/* React Boostrap */
import React, { Component } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";

/* Components */
import Basket from "../components/aside/Basket";
import Topbar from "../components/main/Topbar";
import Header from "../components/main/Header";
import Footer from "../components/main/Footer";
import BreadcrumbMobile from "../components/main/BreadcrumbMobile";

/* Forms controls & validation */
import * as FinalForm from "react-final-form";

/* Utils */
import { loc, userConfigurationService } from "../core/utils";
import { deepGet } from "../core/utils.common";
import _ from "lodash";
import CheckboxField from "../form/CheckboxField";
import {
  REPOSITORY_SEPARATOR,
  FRANCE_COUNTRY_CODE,
  getCivilStatuses,
  initPdiComponent,
  formatBirthDate
} from "../core/service/BusinessLogicService";
import { retrieveRepositoryLabelFromCode } from "../core/service/BusinessLogicService";
import ApiService from "../core/service/ApiService";

class SummaryPage extends Component {

  constructor(props){
    super(props);
    this.state = {
      submitButtonLocked: false
    }
  }

  formatedCountryCode(){
    let config = userConfigurationService.getUserConfiguration();
    if (config) {
      return config.user.locale.substr(config.user.locale.indexOf('-') + 1);
    } else {
      return "FR";
    } 
  }

  async componentDidMount() {
    initPdiComponent("SummaryPage");
    let { tunnelData } = this.props;

    const headers = {
        Authorization: `Bearer ${tunnelData.access_token}`,
        Accept: "*/*",
        "Content-Type": "application/json"
    }
    ApiService.getForEvollisApi(tunnelData.apiUrl + "middle-business-service/api/v2/repository/cities/by-id/" + tunnelData.data.person.birthCity, headers)
        .then(cityLabel => { this.setState({cityLabel: cityLabel}) })
        .catch(error => '');
    ApiService.getForEvollisApi(tunnelData.apiUrl + "middle-business-service/api/v2/repository/department/" + tunnelData.data.person.birthDepartmentCode, headers)
        .then(departmentLabel => this.setState({departmentLabel: departmentLabel }))
        .catch(error => '')

    let civilStatusesAsMap = await getCivilStatuses(this.props?.tunnelData, false);
    let personTitleCode = this.props?.tunnelData?.data?.person.title;
    let retrievedPersonTitleName = await retrieveRepositoryLabelFromCode(civilStatusesAsMap, personTitleCode);
    this.setState({ titleEnumName: retrievedPersonTitleName || "" });
  }

  render() {
    let { tunnelData } = this.props;
    console.log("SummaryPage render's this.state = ", this.state);
    const product = deepGet(tunnelData, ["data", "order", "products", 0])
      || {};
    if (!tunnelData.data.order) {
      tunnelData.data.order = { selectedServicePack: {}, rent: {} }
    }
    let person = this.props.tunnelData.data.person || {};
    let customer = this.props.tunnelData.data.customer || {
      employmentDate: "2020",
      address: { residenceDate: "2020" },
      bankAccountDTO: {}
    };

    return (
      <Container fluid className="white-background">

        <Row noGutters>
          {/* ——————————————————————————————————————————————— */}
          {/* ———————————————————— MAIN ————————————————————— */}
          {/* ——————————————————————————————————————————————— */}
          <Col className="main-block">
            <Row>
              <Topbar tunnelData={this.props?.tunnelData} />
              <Header tunnelData={this.props?.tunnelData} />


              <Col sm={12}>
                {/* CONTENT */}

                <Container>
                  <Row>
                    <Col lg={6}>
                      <header className="header-info">
                        <h2>{loc("My identity")}</h2>
                      </header>
                      <div className="hard-shadow-white-block">
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`Title`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{this.state.titleEnumName || ""}</p></Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`Last name`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{person.name}</p></Col>
                        </Row>
                        {person.maidenName &&
                          <Row className="justify-content-between">
                            <Col className="align-self-center"><p className="text-dark">{loc`Maiden name`}</p></Col>
                            <Col className="align-self-center text-right col-auto"><p>{person.maidenName}</p></Col>
                          </Row>
                        }
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`First name`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{person.firstName}</p></Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`Citizenship`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{retrieveRepositoryLabelFromCode(
                            tunnelData.countries,
                            person.citizenshipCountryCode)}</p></Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`Birthdate`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{formatBirthDate(person.birthDate)}</p></Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`Country of birth`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{retrieveRepositoryLabelFromCode(
                            tunnelData.countries,
                            person.birthCountryCode)}</p></Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`Department of birth`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{this.state.departmentLabel}</p></Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`City of birth`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{this.state.cityLabel}</p></Col>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <header className="header-info">
                        <h2>{loc("My coordinates")}</h2>
                      </header>
                      <div className="hard-shadow-white-block">
                        <Row className="justify-content-between">
                          <Col className="align-self-center text-dark"><p className="text-dark">{loc`Address`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{customer.address.address}</p></Col>
                        </Row>
                        {customer.address.additionalAddress &&
                          <Row className="justify-content-between">
                            <Col className="align-self-center"><p className="text-dark">{loc`Address 2`}</p></Col>
                            <Col className="align-self-center text-right col-auto"><p>{customer.address.additionalAddress}</p></Col>
                          </Row>
                        }
                        {customer.address.locality &&
                          <Row className="justify-content-between">
                            <Col className="align-self-center"><p className="text-dark">{loc`Locality`}</p></Col>
                            <Col className="align-self-center text-right col-auto"><p>{customer.address.locality}</p></Col>
                          </Row>
                        }
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`City`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{customer.address.city}</p></Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`Zip`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{customer.address.postalCode}</p></Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`Email`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{customer.address.email}</p></Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`Mobile`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{customer.address.mobilePhone}</p></Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`IBAN`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{customer.bankAccountDTO.iban}</p></Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`BIC or SWIFT`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{customer.bankAccountDTO.bic}</p></Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`Bank Seniority`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{customer.yearBankSeniority.substr(
                            0, 4)}</p></Col>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <header className="header-info">
                        <h2>{loc("My situation")}</h2>
                      </header>
                      <div className="hard-shadow-white-block">
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`Occupation`}</p></Col>
                          <Col className="align-self-center text-right col-auto">
                            <p>
                              {retrieveRepositoryLabelFromCode(tunnelData.socioProfessionalcategories, customer.occupationCode)}
                              {customer.employmentDate && <span>{loc` since `} {customer.employmentDate.substr(0, 4)}</span>}
                            </p>
                          </Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`Housing`}</p></Col>
                          <Col className="align-self-center text-right col-auto">
                            <p>
                              {retrieveRepositoryLabelFromCode(tunnelData.housingTypes, customer.address.housingType)}
                              {loc` since `}
                              {customer.address.residenceDate.substr(0, 4)}
                            </p></Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`Family situation`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{retrieveRepositoryLabelFromCode(tunnelData.familySituations, customer.maritalStatus)}</p></Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`Number of dependent children`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{customer.dependentChildrenNumber}</p></Col>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <header className="header-info">
                        <h2>{loc("My budget")}</h2>
                      </header>
                      <div className="hard-shadow-white-block">
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`Your net monthly income`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{customer.netMonthlyIncome}€ {loc`within`} {customer.numberMonthsIncome} {loc`months`}</p></Col>
                        </Row>

                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`Other monthly income`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{customer.otherIncome}€</p></Col>
                        </Row>

                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`Your share of monthly charges`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{customer.rent}€</p></Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="align-self-center"><p className="text-dark">{loc`Other monthly charges`}</p></Col>
                          <Col className="align-self-center text-right col-auto"><p>{customer.otherCharges}€</p></Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <FinalForm.Form
                    initialValues={tunnelData.data.optinAnswers}
                    onSubmit={this.onSubmit}
                    render={(
                      {
                        handleSubmit,
                        values,
                        submitting,
                        validating,
                        valid,
                      }
                    ) => (
                        <Form onSubmit={handleSubmit}>
                          <Row>
                            <Col className="align-self-center">
                              {
                                tunnelData.summaryPageOptIn.map((optIn) => 
                                  <CheckboxField
                                    name= {optIn.name}
                                    label= {loc(optIn.label)}
                                  />
                                )
                              }
                              {
                                tunnelData.summaryPageOptOut.map((optOut) => 
                                  <CheckboxField
                                    name= {optOut.name}
                                    label= {loc(optOut.label)}
                                  />
                                )
                              }
                            </Col>
                          </Row>
                          <Row className="justify-content-between margin-top-56">
                            <Col lg={{ span: 4, order: 1 }} md={{ span: 5, order: 1 }} xs={{ span: 12, order: 2 }}>
                              <Button variant="outline-primary" onClick={this.goBackToClientPage} className="btn-outline-primary btn ico-edit-small-before">
                                {loc`Edit my data`}
                              </Button>
                            </Col>
                            <Col lg={{ span: 3, order: 1 }} md={{ span: 5, order: 1 }} xs={{ span: 12, order: 1 }}>
                              <Button variant="success" type="submit" className="btn-solid btn" disabled={this.state?.submitButtonLocked === true}>
                                {loc`Validate`}
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      )} />
                </Container>

                {/* END CONTENT */}
              </Col>

              <Col sm={12}>
                <Container>
                  <BreadcrumbMobile tunnelData={this.props?.tunnelData} />
                  <Footer tunnelData={this.props?.tunnelData} infosAndClientPageDisplay={true} />
                </Container>
              </Col>
            </Row>
          </Col>

          {/* ————————————————————————————————————————————————— */}
          {/* ————————————————————— ASIDE ————————————————————— */}
          {/* ————————————————————————————————————————————————— */}

          <Col className="aside-block d-none d-lg-block d-xl-block" lg={4}>
            <Basket tunnelData={this.props?.tunnelData || null} />
          </Col>
        </Row>

      </Container>
    );
  }

  onSubmit = async (values) => {
    await this.lockSubmitButton();
    this.props.tunnelData.comeBackToClientStep = false;
    this.props.onSetStep(values);
  };

  lockSubmitButton = async () => {
    await this.setState({ submitButtonLocked: this.state.submitButtonLocked !== true });
  };

  goBackToClientPage = () => {
    this.props.tunnelData.comeBackToClientStep = true;
    this.props.onSetStep(this.props.tunnelData.data);
  }
}

export default SummaryPage;
