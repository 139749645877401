/* React Bootstrap */
import React, {Component} from "react";
import {Button, Col, Container, Row, Form} from "react-bootstrap";

/* Components */
import Basket from "../components/aside/Basket";
import Topbar from "../components/main/Topbar";
import Header from "../components/main/Header";
import Footer from "../components/main/Footer";
import BreadcrumbMobile from "../components/main/BreadcrumbMobile";

/* Forms controls & validation */
import * as FinalForm from "react-final-form";

/* Utils */
import ApiService from "../core/service/ApiService";
import {loc} from "../core/utils";
import CheckboxField from "../form/CheckboxField";
import {deepGet} from "../core/utils.common";
import {
  extractAllServicePossibilities,
  extractServicePackDocumentLinkFromServicePackDescription,
  getServicePackPossibilities,
  initPdiComponent, replaceMailAndLinkWithProperHtmlTemplate,
  replaceMailAndLinkWithProperHtmlTemplateForOptins
} from "../core/service/BusinessLogicService";
import Modal from "react-bootstrap/Modal";

class ServicesPage extends Component {
  state: {
    servicesPossibilities: [],
    servicePackPossibilities: [],
    selectedServicePackIndex: null,
    displayConfirmNoAssurancePack : false,
  };

  async componentDidMount() {
    initPdiComponent("ServicePage");
    let servicePackPossibilities = await getServicePackPossibilities(
        this.props?.tunnelData.apiUrl, this.props?.tunnelData.access_token,
        this.props?.tunnelData.data.order.offerId,
        this.props?.tunnelData.data.order.products[0].sku);
    if (servicePackPossibilities) {
      this.setState({
        servicePackPossibilities: servicePackPossibilities,
        servicesPossibilities: extractAllServicePossibilities(
            servicePackPossibilities)
      });
    }
  }

  selectServicePack(index) {
    let stateObject = {};
    if (index === 0) {
      this.props.tunnelData.cheaperServicePackOptins.forEach((optin, index) => {
        stateObject[index] = false;
      });
    }
    else if (index > 0) {
      this.props.tunnelData.mostExpensiveServicePackOptins.forEach((optin, index) => {
        stateObject[index] = false;
      })
    }
    stateObject.selectedServicePackIndex = index;
    this.setState(stateObject);
  }

  render() {
    let {tunnelData} = this.props;
    let serviceAlreadyDisplayed = [];
    let actualServicePackServicesId;
    let alreadyDisplayedServicesCheckbow;
    let optinChoices = null;
    if (!isNaN(this.state?.selectedServicePackIndex)) {
      optinChoices = this.state?.selectedServicePackIndex === 0 ? tunnelData?.cheaperServicePackOptins : tunnelData.mostExpensiveServicePackOptins;
    }
    let isAtLeastOneCheckboxeNotChecked = this.confirmAllCheckboxAreChecked() === false;

    return (
        <Container fluid className="white-background">

          <Row noGutters>
            {/* ——————————————————————————————————————————————— */}
            {/* ———————————————————— MAIN ————————————————————— */}
            {/* ——————————————————————————————————————————————— */}
            <Col className="main-block">
              <Row>
                <Topbar tunnelData={this.props?.tunnelData}/>
                <Header tunnelData={this.props?.tunnelData}/>


                <Col sm={12}>
                  {/* CONTENT */}
                  <Container>
                    <Row>
                      <Col md={12} sm={12} lg={true}
                           className="service-warranties-container">
                        <p>{loc`Gain peace of mind by protecting your product throughout the rental period.`}</p>
                        <ul className="list-unstyled d-none d-lg-block">
                          {this.state?.servicesPossibilities.map(service => {
                            if (serviceAlreadyDisplayed.indexOf(
                                service.serviceId) < 0) {
                              serviceAlreadyDisplayed.push(service.serviceId);
                              return (
                                  <li>{service.label}</li>
                              );
                            }
                            return null;
                          })}
                        </ul>
                      </Col>
                      {
                        this.state?.servicePackPossibilities.map(
                            (servicePackPossibility, index) => {
                              actualServicePackServicesId = [];
                              alreadyDisplayedServicesCheckbow = [];
                              servicePackPossibility.servicePackDTO.services.map(
                                  service => {
                                    actualServicePackServicesId.push(
                                        service.serviceId);
                                  });
                              return (
                                  <Col md={true} sm={12} lg={true}
                                       className="service-container text-center hard-shadow-white-block">
                                    {/*TODO CLEMENT internationalisation via API à gérer ici. */}
                                    <h4>{servicePackPossibility.servicePackDTO.name}</h4>
                                    {/*<p className="service-price">{servicePackPossibility.rentDTO.monthlyFinancedServicesAmount} €<small>{loc`/month`}</small>*/}
                                    {/*TODO CLEMENT enlever le || 6 + index sur la ligne dessous qui ne sert qu'a mocker le temps que le bug de la simulation sur le montant du pack soit réglé*/}
                                    <p className="service-price">{servicePackPossibility.rentDTO.monthlyFinancedServicesAmount.toFixed(2)} €<small>{loc`/month`}</small>
                                    </p>
                                    <small
                                        className="light">{loc`or a rent of `}{servicePackPossibility.rentDTO.monthlyTotal} € {loc`/month`}</small>
                                    <div class="btn-group-toggle"
                                         data-toggle="buttons">
                                      <label
                                          class={this.state?.selectedServicePackIndex
                                          === index
                                              ? "btn btn-outline-primary btn-outline-primary-small ico-check-small-before active"
                                              : "btn btn-outline-primary btn-outline-primary-small"}
                                          onClick={() => this.selectServicePack(
                                              index)}>
                                        <input type="radio" name="options"
                                               key={index}
                                               checked={index
                                               === this.state?.selectedServicePackIndex}
                                        />
                                        {this.state?.selectedServicePackIndex !== index && <div>{loc("Choose")}</div>}
                                      </label>
                                      <a href={extractServicePackDocumentLinkFromServicePackDescription(servicePackPossibility.servicePackDTO.description)} target="_blank">{loc`Read the insurance notice`}</a>
                                    </div>
                                    {this.state?.servicesPossibilities.map(
                                        (servicePossibility, index) => {
                                          let result = [];
                                          if (alreadyDisplayedServicesCheckbow.indexOf(
                                              servicePossibility.serviceId)
                                              < 0) {
                                            result = (
                                                <Row className="warranty-table" noGutters>
                                                  <Col className="align-self-center text-left d-lg-none">{servicePossibility.label}</Col>
                                                  <Col lg={12} className={actualServicePackServicesId.indexOf(servicePossibility.serviceId) >= 0 ? "warranty-included col-auto" : "warranty-not-included col-auto"}/>
                                                </Row>
                                            );
                                          }
                                          alreadyDisplayedServicesCheckbow.push(
                                              servicePossibility.serviceId);
                                          return result;
                                        })}
                                  </Col>
                              );
                            }
                        )
                      }
                    </Row>
                    <Row>
                      <Col lg={{offset: 4}}>
                        <p className="small-lineheight">
                          <small><strong>{loc`Cap`}</strong> : {loc`2 claims maximum per insurance year. Ceiling 1500 € per insurance year.`}
                          </small></p>
                        <p className="small-lineheight">
                          <small><strong>{loc`Main exclusions`}</strong> : {loc`The accessories, and connectors of the device. Normal wear and tear of the device. Negligence, forgetting the device. Theft from the primary and secondary residence.`}
                          </small></p>
                      </Col>
                    </Row>
                  <Row>
                      <Col className="align-self-center margin-top-40">

                        {!isNaN(this.state?.selectedServicePackIndex) && optinChoices?.map((optin, index) => {
                          let stateObject = {};
                          stateObject[index] = !this.state?.[index];
                          return <div className="form-group">
                            <div class="optin row no-gutters">
                              <input id={"option-id-" + index} key={"option" + index} checked={this.state?.[index] === true} type={"checkbox"} onClick={() => this.setState(stateObject)} />
                              {/*<div />*/}
                              <label className="form-label" for={"option-id-" + index} dangerouslySetInnerHTML={{__html : replaceMailAndLinkWithProperHtmlTemplate(loc(optin))}}/>
                            </div>
                          </div>;
                        })}
                      </Col>
                    </Row>
                    <Row className="margin-top-56">
                      <Col lg={{span: 3, order: 1}} md={{span: 4, order: 1}}
                           xs={{span: 12, order: 2}} className="mr-auto">
                        <Button href={this.props?.tunnelData?.shopUrl}
                                variant="outline-primary"
                                className="ico-back-small-before">
                          {loc`Back to shop`}
                        </Button>
                      </Col>
                      <Col lg={{span: 3, order: 2}} md={{span: 4, order: 2}}
                           xs={{span: 12, order: 1}} className="align-item-end">
                        <Button variant="success"
                                onClick={this.onValidateScreen}
                                className=""
                                disabled={(isNaN(this.state?.selectedServicePackIndex) || isAtLeastOneCheckboxeNotChecked)}>
                          {loc`Validate`}
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                  {/* END CONTENT */}
                </Col>
                <Col sm={12}>
                  <Container>
                    <BreadcrumbMobile tunnelData={this.props?.tunnelData}/>
                    <Footer tunnelData={this.props?.tunnelData} serviceDisplay={true}/>
                  </Container>
                </Col>
              </Row>
            </Col>

            {/* ————————————————————————————————————————————————— */}
            {/* ————————————————————— ASIDE ————————————————————— */}
            {/* ————————————————————————————————————————————————— */}

            <Col className="aside-block d-none d-lg-block d-xl-block" lg={4}>
              <Basket tunnelData={this.props?.tunnelData || null}/>
            </Col>
          </Row>

          {/*NO INSURANCE CONFIRM MODAL*/}
          <Modal
              show={this.state?.displayConfirmNoAssurancePack}
              onHide={() => this.setState({displayConfirmNoAssurancePack : false})}
              backdrop="static"
              keyboard={false}
              className="modal-danger"
          >
            <Modal.Body>
            <i class="fas fa-exclamation-triangle"></i>
            <h3>Vous ne souhaitez pas adhérer à l’assurance ?</h3>
              <p>
                Sachez qu’à défaut, en cas de casse ou de vol, vous êtes redevable du paiement des réparations ou du produit. 
                La garantie constructeur ne couvre pas la casse ou le vol, alors pensez à protéger votre appareil pour gagner en sérénité !
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Row className="justify-content-md-center">
                <Col md={6} lg={6}>
                  <Button variant="outline-primary" onClick={() => this.setState({ displayConfirmNoAssurancePack: false })}>Annuler</Button>
                </Col>
              <Col md={6} lg={6}>
                  <Button variant="danger" onClick={() => this.props?.onSetStep({servicePageSelectedServicePackId : this.state?.servicePackPossibilities?.[this.state?.selectedServicePackIndex].servicePackDTO.id})}>Confirmer</Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Modal>
        </Container>
    );
  }

  onValidateScreen = () => {
    if (this.state?.selectedServicePackIndex === 0) {
      this.setState({displayConfirmNoAssurancePack : true})
    }
    else {
      this.props.onSetStep({servicePageSelectedServicePackId : this.state?.servicePackPossibilities?.[this.state?.selectedServicePackIndex].servicePackDTO.id});
    }
  }

  confirmAllCheckboxAreChecked() {
    let result = true;
    if (!this.state || isNaN(this.state?.selectedServicePackIndex)) {
      return false;
    }
    if (this.state?.selectedServicePackIndex === 0) {
      this.props.tunnelData.cheaperServicePackOptins.forEach((optin, index) => {
        if (!this.state?.[index] || this.state?.[index] === false) {
          result = false;
        }
      });
    }
    else if (this.state?.selectedServicePackIndex >= 0) {
      this.props.tunnelData.mostExpensiveServicePackOptins.forEach((optin, index) => {
        if (!this.state?.[index] || this.state?.[index] === false) {
          result = false;
        }
      });
    }
    return result;
  }
}

export default ServicesPage;
