const axios = require("axios");
class ApiService {
  registered = false;
  incrementLoading = undefined;
  history = undefined;
  decrementLoading = undefined;

  register = ({ history, incrementLoading, decrementLoading }) => {
    this.registered = true;
    this.incrementLoading = incrementLoading;
    this.decrementLoading = decrementLoading;
    this.history = history;
  };

  get = async url => {
    this.checkRegister();
  };

  post = async (url, data, headers) => {
    this.checkRegister();
    this.incrementLoading();
    try {
      const res = await axios.post(url, data, headers);
      return res.data;
    } catch (error) {
      console.error("Voyager error : ", error);
      return error;
    } finally {
      this.decrementLoading();
    }
  };

  postForEvollisApi = async (url, data, headers) => {
    try {
      const res = await axios.post(url, data, headers);
      return res.data;
    } catch (error) {
      console.error("Voyager error", error);
    }
  };

  getForEvollisApi = async (url, headers) => {
    try {
      let response = await (axios.get(url, {headers : headers}));
      return response.data;
    } catch (error) {
      console.error("Voyager error", error);
    }
  };

  checkRegister = () => {
    if (!this.registered) {
      console.error("ApiService not registered");
    }
  };
}

export default new ApiService();
