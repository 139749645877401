import { loc } from "./utils";

export var nl_BE = {
  /* ========================================== */
  /* COMMON =================================== */
  /* ========================================== */
  "Back to shop": "Terug om te winkelen",
  "Validate": "Bevestigen",
  "Select...": "Selecteren",
  "Choose": "Kiezen",
  "Start": "Beginnen",
  "Je déclare par la présente avoir lu les conditions générales et la politique de confidentialité et être d'accord avec leur contenu": "Ik verklaar hierbij dat ik de Algemene voorwaarden en de Privacy Policy heb gelezen en dat ik instem met de inhoud ervan.",
  "Par la présente, je donne mon consentement explicite au traitement de mes données personnelles aux fins spécifiées dans la politique de confidentialité": "Hierbij geef ik mijn uitdrukkelijke toestemming voor de verwerking van mijn persoonsgegevens voor de doeleinden als gespecificeerd in de Privacy Policy.",
  /* ========================================== */
  /* BREADCRUMB =============================== */
  /* ========================================== */
  "Services": "Diensten",
  "Informations": "Informatie",
  "My informations": "Mijn informatie",
  "Summary": "Overzicht",
  "My summary": "Mijn samenvatting",
  "Contracts": "Contracten",
  "Back to basket": "Terug naar de korf",
  /* ========================================== */
  /* BASKET =================================== */
  /* ========================================== */
  "Basket": "Bestelling",
  "Promo code": "Promotiecode",
  "on": "voor",
  "months": "maanden",
  "Offered": "Aangeboden",
  "Shipping fees": "Transportkosten",
  "Upfront": "Eenmalige betaling",
  "My upfront": "Mijn eerste betaling",
  "Rent": "Huur",
  "/month": "/maand",
  "YYYY": "JJJJ",
  "YYYY/MM/DD": "DD/MM/JJJJ",
  "including services pack at": "Inclusief Dienstenpakket",
  "Supporting documents requested": "Gevraagde ondersteunende documenten",
  "A bank card in your name": "Een bankkaart op uw naam",
  "Your mobile phone": "Je mobiele telefoon",
  "A bank identity statement in your name": "Een bankafschrift op uw naam",
  "My RIB": "mijn bankafschrift",
  "A valid ID in your name": "Geldig legitimatiebewijs op uw naam",
  "My ID": "Mijn ID",
  "My valid ID": "Mijn geldige ID",
  "Proof of address of less than 3 months": "",
  "Proof of address of less than 6 months": "",
  "My proof of address": "",
  /* ========================================== */
  /* ONBOARDING PAGE ========================== */
  /* ========================================== */
  "My identification": "Mijn identificatie",
  "Only if your bancary document's address is different than the one you will enter": "",
  "Only if my RIB's address is different from my current address": "",
  "Enter the informations that will allow us to identify you.": "Voer de informatie in waarmee we u kunnen identificeren.",
  "Start your location online then meet us in our sellpoint.": "",
  "by entering my personnal informations": "door mijn persoonlijke gegevens in te voeren",
  "Want to rent my product ? No problem ! It only takes a few minutes to subscribe online": "",
  "Then i finalize my order in shop under 5 days, bringing with me those 3 proofs": "",
  "Here are the steps to follow to make your rental request": "Dit zijn de volgende stappen die u moet volgen om uw huuraanvraag in te dienen",
  "The online subscription procedure is fully dematerialized.": "De online inschrijvingsprocedure gebeurt volledig papierloos.",
  "The signing of your contract and the downloading of your supporting documents will be done by electronic way.": "Het ondertekenen van uw contract en het downloaden van uw ondersteunende documenten gebeurt langs elektronische weg.",
  "Select your services and customize the options of your rental contract.": "Selecteer uw diensten en pas de opties van uw huurcontract aan",
  "Pay by credit card the 1st installment of your rental contract.": "Betaal de eenmalige afsluitingskost van uw huurcontract met uw kredietkaart.",
  "through bank card to reserve my product": "met een creditcard om mijn product te reserveren.",
  "Check and complete your personal information which will appear on your contract.": "Controleer en vul uw persoonlijke gegevens in die op uw contract zullen verschijnen.",
  "which will appear on my location's contract": "die op mijn huurovereenkomst zal verschijnen.",
  "Validate your personal information and your rental request.": "Valideer uw persoonlijke gegevens en het gepersonaliseerd huurverzoek.",
  "which allow me to validate my informations and my rent request": "waarmee ik mijn gegevens en mijn huurverzoek kan valideren.",
  "Download your supporting documents and sign your dematerialized contract.": "Download uw ondersteunende documenten en onderteken uw huurcontract.",
  "Obtain acceptance in principle then finalize your order in the store of your choice.": "",
  "I get a principle acceptation to rent my product": "",
  "To complete your subscription request, have the following information ready:": "Hou volgende informatie bij de hand om uw abonnementsaanvraag te voltooien!",
  "Only if the address of your RIB differs from your current address": "",
  /* ========================================== */
  /* SERVICES PAGE ============================ */
  /* ========================================== */
  "Gain peace of mind by protecting your product throughout the rental period.": "",
  "Read the insurance notice": "",
  "Full warranty": "Volledige garantie",
  "Upgrade": "Upgraden (na 6 maanden)",
  "Dedicated customer service": "",
  "Accidental breakage": "Protection Plan",
  "Accidental oxidation": "",
  "Accidental action": "",
  "Global coverage": "",
  "No deductible applied": "",
  "14 days withdrawal period": "14 dagen bedenktijd",
  "Telephone assistance 6 days a week": "",
  "Pickpocketing": "",
  "Theft by assault or break-in": "",
  "Fraudulent use": "",
  "None": "Nee",
  "Breakage": "",
  "Breakage and theft": "",
  "or a rent of ": "Of een huur van",
  "Cap": "",
  "2 claims maximum per insurance year. Ceiling 1500 € per insurance year.": "",
  "Main exclusions": "",
  "The accessories, and connectors of the device. Normal wear and tear of the device. Negligence, forgetting the device. Theft from the primary and secondary residence.": "",
  "I acknowledge having read and accept the general conditions of the long-term rental contract offer and the service contract.": "Hierbij verklaar ik dat ik de algemene voorwaarden en het privacybeleid heb gelezen en dat ik de inhoud ervan accepteer. Ik geef ook de machtiging om de eerste betaling van het huurcontract via Payline van mijn creditcard af te schrijven.",
  "I request the start of the execution of my long-term rental contract with the lessor and my service contract before the expiration of the 14-day withdrawal period.": "Je demande le commencement de l’exécution de mon contrat de location longue durée avec le loueur et de mon contrat de services avant l’expiration du délai de rétractation de 14 jours.",
  "I confirm that I do not choose optional insurance and assume the risk of breakage or theft during my long-term rental contract.": "",
  "I confirm my subscription to breakage insurance and acknowledge having read the insurance information notice.": "",
  "By checking this box, I authorize Cardif Assurances Risques Divers to use my email address for the execution of my contract. However, I recognize that certain documents must be sent to you in the original and that you will inform me if the case arises. I note that this data (email address) will not be used for commercial solicitation purposes, unless I have expressly consented otherwise. I have the right to oppose exchanges by e-mail at any time by sending my request to data.protection@cardif.com or to CARDIF Assurances Risques Divers - Claims quality department - Prévoyance SH 123- 8 rue du Port - 92 728 Nanterre Cedex.": "",
  "Up2You's breakage and thief protection is a collective damage insurance (number 674) contract subscribed by Samsung Electronics France, society with a capital of 27 000 000 euros – 334 367 497 RCS Bobigny – Siège social 1, rue fructidor 93484 Saint-Ouen auprès de CARDIF - Assurances Risques Divers - S.A. (society with a capital of 16 875 840 euros) 308 896 547 RCS Paris - Siège social : 1, Boulevard Haussmann 75009 Paris - Entreprise ruled by the insurance code, here called \"CARDIF\"": "",
  "Up2YOu Location's breakage and thief insurance is distributed as an accessory relatively to it main commercial activity by Samsung Electronics France by the way of Garantie-privée SAS, insurance society with a capital of 60 000 € - 521 279 737 RCS Bordeaux, - Siège social : 7-9 allée Haussmann 33300 Bordeaux, registered to l’ORIAS ( www.orias.fr ) under the reference 100 557 38.": "«Protection casse et vol SAMSUNG Up2you location longue durée» est distribué à titre accessoire à son activité commerciale principale par Samsung Electronics France par l’intermédiaire de Garantie-Privée SAS, Société de Courtage en assurances au capital de 60 000 € - 521 279 737 RCS Bordeaux, - Siège social : 7-9 allée Haussmann 33300 Bordeaux, immatriculée à l’ORIAS ( www.orias.fr ) sous le numéro 100 557 38 en sa qualité de Courtier.",
  "Up2You location breakage and thief contract' sinister handling is the responsability of Grantie-Privée, insurance broker": "",
  /* ========================================== */
  /* UPFRONT PAGE ============================= */
  /* ========================================== */
  "first installment of your rental contract": "Eerste termijn van uw huurcontract",
  "Cards accepted": "Geaccepteerde betaalkaarten",
  "Cards not accepted": "Niet geaccepteerde betaalkaarten",
  "Systematic authorization cards (Electron, Maestro, pre-paid or rechargeable), American Express and e-CB.": "Systematische autorisatiekaarten (Electron, Maestro, prepaid of oplaadbaar), American Express en e-CB.",
  "This step only constitutes a request for authorization of payment.":"Deze stap is slechts een betalingsautorisatieverzoek.",
  " The 1st payment of ":" De 1e betaling van ",
  "by credit card will only be effective after signing your rental contract and downloading your supporting documents.If your rental request is subsequently refused by the Lessor following its study, then this amount will be immediately refunded.":" per creditcard is pas effectief na het downloaden van uw ondersteunende documenten en na ondertekening van uw huurovereenkomst. Wordt uw huuraanvraag na onderzoek door de verhuurder geweigerd, dan krijgt u dit bedrag direct terug.",
  "Cards not accepted": "Niet geaccepteerde betaalkaarten",
  "Systematically authorized cards (such as Electron, Maestro, prepaid, rechargeable), e-CB and American Express.": "Systematische autorisatie betaalkaarten (Electron, Maestro, prepaid-kaarten, herlaadbare kaarten), American Express, e-CB.",
  "Upfront amount of your leasing contract": "Bedrag van de eerste termijn van uw huurcontract",
  "Card number": "Kaartnummer",
  "MM": "",
  "CB": "",
  "Visa": "Visa",
  "Mastercard": "Mastercard",
  "Your credit card is expired": "uw kaart is verlopen",
  "Expiration date": "Vervaldatum",
  "CVV": "CVV (Bancontact: voer 000 in)",
  "I, undersigned $ certify that I am the holder of the bank card used for the request for payment of my 1st payment.": ["Ik ", " ondergetekende, verklaar dat ik de houder ben van de betaalkaarte die is gebruikt voor de betaling van de eenmalige afsluitingskost."],
  "Secured payment": "Beveiligde transactie",
  "validate the payment authorization": "Valideer de betalingsautorisatie",
  "Your card has been declined. Please check the accuracy of the information entered. You can also contact your bank to verify that it is not blocking payment.": "Je kaart is geweigerd. Controleer de juistheid van de ingevoerde informatie. U kunt ook contact opnemen met uw bank om te controleren of ze de betaling niet blokkeren.",
  /* ========================================== */
  /* CLIENT DATA PAGE ========================= */
  /* ========================================== */
  "Bank Seniority": "Sinds",
  "My identity": "Mijn identiteit",
  "My coordinates": "Mijn contactgegevens",
  "My situation": "Mijn situatie",
  "My budget": "Mijn Bestedingsruimte",
  "The information below is necessary for our renter for the constitution of your rental file.": "Onderstaande gegevens zijn nodig voor ons verhuurbedrijf om uw verhuurdossier samen te stellen.",
  "To meet our eligibility conditions, you must have regular activity income and not have any current payment incidents.": "Om aan onze deelnamevereisten te voldoen, moet u een regelmatig inkomen hebben en geen openstaande betalingsincidenten.",
  "Identity": "Identiteit",
  "Title": "Aanhef",
  "Mr": "Mr",
  "Ms": "Mevr",
  "Last name": "Naam",
  "Maiden name": "Meisjesnaam",
  "If you are not married, enter your name again.": "Als u niet getrouwd bent, voert u uw naam opnieuw in.",
  "First name": "Voornaam",
  "Address": "Adres",
  "Enter the address that will appear on your contract.": "Voer het adres in dat op uw contract zal verschijnen.",
  "Address 2": "Toevoeging adres",
  "Locality": "Plaats",
  "Zip": "Postcode",
  "City": "Stad",
  "Birth": "Geboorte",
  "Birthdate": "Geboortedatum",
  "Department of birth": "Geboorteprovincie",
  "City of birth": "Geboorteplaats",
  "Country of birth": "Geboorteland",
  "Citizenship": "Nationaliteit",
  "Contact": "Contact",
  "Mobile": "mobiel nummer",
  "Email": "E-mail",
  "Occupation": "Beroep",
  "Since...": "Sinds...",
  " since ": " sinds",
  "Finances": "Bestedingsruimte",
  "Your net monthly income": "Uw netto maandelijks inkomen",
  "over 12 months": "meer dan 12 maanden",
  "over 13 months": "meer dan 13 maanden",
  "over 14 months": "meer dan 14 maanden",
  "over 15 months": "meer dan 15 maanden",
  "over 16 months": "meer dan 16 maanden",
  "Other monthly income": "Overig maandelijks inkomen",
  "Property income or alimony paid": "Inkomen uit vermogen of ontvangen alimentatie",
  "Your share of monthly charges": "Uw aandeel in maandelijkse kosten",
  "Rent or mortgage": "Huur of hypotheek",
  "Other monthly charges": "Overige maandelijkse kosten",
  "Credit (excluding mortgage) and / or alimony paid": "Krediet (exclusief hypotheek) en / of alimentatie betaald",
  "Housing": "Huisvesting",
  "Family situation": "Familiale situatie",
  "Number of dependent children": "Aantal kinderen",
  "Bank account": "bankpas",
  "IBAN": "IBAN",
  "BIC or SWIFT": "BIC of SWIFT",
  /* ========================================== */
  /* SUMMARY ================================== */
  /* ========================================== */
  "within": "Zeker",
  "Edit my data": "Pas mijn gegevens aan",
  "I agree to receive electronic commercial offers from Evollis.": "Ik ga ermee akkoord om elektronische commerciële aanbiedingen van Evollis te ontvangen.",
  "I oppose the receipt of commercial offers by mail or telephone from Evollis.": "Ik verzet me tegen de ontvangst van commerciële aanbiedingen per post of telefoon van Evollis.",
  /* ========================================== */
  /* NETHEOS ================================== */
  /* ========================================== */
  "Your product will be delivered to you after the final acceptance of the long-term rental contract and upon receipt of supporting documents by the rental company.": "Uw product wordt aan u geleverd na de definitieve aanvaarding van het lange termijn huurcontract en na ontvangst van de ondersteunende documenten door de verhuurder.",
  /* ========================================== */
  /* CONFIRMATION ============================= */
  /* ========================================== */
  "Request sent": "Verzoek verzonden",
  "Your file number is": "Uw dossiernummer is",
  "Now, we need to review it and determine if you are eligible for our offer. A response will be provided to you within 48 hours.": "Er wordt nu gekeken en bepaalt of u in aanmerking komt voor Samsung Private Rent. U ontvangt binnen 48 uur een antwoord.",
  "Thank you for your trust and see you soon !": "Bedankt voor uw vertrouwen en tot snel !",
  /* ========================================== */
  /* LEGALS =================================== */
  /* ========================================== */
  "Long-term rental contract, subject to acceptance of the file by the Lessor, Evollis, a Simplified Joint Stock Company under French law, with a capital of € 1,217,008, having its head office at 22 quai de Bacalan, 33300 Bordeaux, France, registered in the French trade register under number 530612571; VAT number: FR 19530612571.":"Huurcontract op lange termijn, onder voorbehoud van aanvaarding van het dossier door de verhuurder, Evollis, een vereenvoudigde naamloze vennootschap naar Frans recht, met een kapitaal van € 1,217,008, met maatschappelijke zetel te 22 quai de Bacalan, 33000 Bordeaux, Frankrijk, ingeschreven in het Franse handelsregister onder nummer 530612571; BTW-nummer: FR 19530612571.",
  "This is a long-term rental contract, which the consumer concludes with Evollis S.A.S. The consumer will first be asked to make a first payment and provide information. It is only after Evollis has evaluated this information and has definitively accepted the request, that the contract will be concluded. Evollis S.A.S. is a limited liability company under French law with its registered office at 22 quai de Bacalan, 33300 Bordeaux, France, registered in the French trade register under number 530612571; VAT number: FR 19530612571.":"Dit is een lange termijn huurovereenkomst die de consument sluit met Evollis. De consument zal eerst gevraagd worden een eerste betaling te doen en (aanvullende) informatie te verstrekken. Pas nadat Evollis deze informatie heeft beoordeeld en de aanvraag definitief heeft aanvaard, komt de huurovereenkomst tot stand. Evollis S.A.S. is een besloten kapitaalvennootschap naar Frans recht met haar statutaire vestigingsplaats aan de 22 quai de Bacalan, 33300 Bordeaux, Frankrijk, ingeschreven in het Franse handelsregister onder nummer 530612571; btw-nummer: FR 19530612571.",
  "The purpose of the information requested from you is to manage and execute your lease. Unless otherwise agreed, the provision of the requested information is mandatory. If the information provided is not complete, your rental request may be rejected. If you choose not to provide information, we will not be able to process your request. Your information is only intended for Evollis as manager and executor of the lease. By providing your information, you may receive commercial offers from our partners. If you want more information about the processing of your personal data by Evollis and your rights in this regard (access, rectification, deletion, limitation of processing, data transfer, get it here. You can also contact us at our postal address: Evollis, Customer Service, 22 quai de Bacalan, 33300 Bordeaux, France or by e-mail: protectiondonnees@evollis.com":"Het doel van de van u verlangde informatie is om uw huurovereenkomst te beheren en uit te voeren. Tenzij anders overeengekomen is het verstrekken van de gevraagde informatie verplicht. Wanneer de verstrekte informatie niet volledig is dan kan uw huuraanvraag worden afgewezen. Als u ervoor kiest om geen informatie te vertrekken, dan zijn wij niet in staat om uw aanvraag in behandeling te nemen. Uw informatie is louter bedoeld voor Evollis als beheerder en uitvoerder van de huurovereenkomst. Met het verstrekken van uw informatie kunt u commerciële aanbiedingen van onze partners ontvangen. Als u meer informatie wenst over de verwerking van uw persoonlijke gegevens door Evollis en over uw rechten daaromtrent (toegang, rectificatie, verwijdering, beperking in verwerking, overdracht van gegevens, bezwaarmogelijkheden en beheer van uw gegevens na overlijden) kunt die hier verkrijgen. Ook kunt u contact met ons opnemen via ons postadres: Evollis, afdeling Klantenservice, 22 quai de Bacalan, 33300 Bordeaux, Frankrijk of per e-mail: protectiondonnees@evollis.com",
  /* ========================================== */
  /* ERROR PAGES ============================== */
  /* ========================================== */
  "Internal error": "Interne fout",
  "The server was unable to process your request.": "De server kan uw verzoek niet verwerken.",
  "The server has encountered an error, and couldn't treat your request.": "De server heeft een fout aangetroffen en kan uw verzoek niet verwerken.",
  "Page not found": "Pagina niet gevonden",
  "The page you're asking for doesn't exist or had been deleted": "De pagina die u zoekt, bestaat niet of is verwijderd.",
  "Service unavailable": "Service indisponible",
  "Service is unavailable, please come back later.": "Le service est temporairement indisponible. Merci de revenir plus tard.",
  "Pending request": "Aanvraag in behandeling",
  "We will send you an email allowing you to finalize your file later.": "We sturen u een e-mail waarmee u uw zaak later kunt afronden.",
  "Without your supporting documents, your file will be canceled within 3 days.": "Zonder uw ondersteunende documenten wordt uw dossier binnen 3 dagen geannuleerd.",
  /* ========================================== */
  /* VALIDATION =============================== */
  /* ========================================== */
  "Not a valid IBAN": "Ongeldige IBAN",
  "Not a valid BIC": "BIC ongeldig",
  "Required": "Verplicht veld",
  "Date is not valid": "Ongeldige datum",
  "maximum characters": "maximum aantal tekens",
  "Unauthorized characters": "Ongeautoriseerde tekens",
  "Phone number must start with 06 or 07 and contain 10 numbers": "",
  "Invalid postal code": "Ongeldige postcode",
  "Minimum age must be > 18 years old": "De minimumleeftijd moet> 18 jaar zijn",
  "Maximum age must be < 110 years old": "De maximumleeftijd moet <110 jaar zijn",
  "Must be positive": "Het nummer moet positief zijn",
  "minimum characters": "minimum karakters",
  "number of children must be contains between 0 and 10": "Het aantal kinderen moet tussen de 0 en 10 zijn",
  "birthDate is not valid": "Ongeldige geboortedatum",
  "employmentDate cannot be before birthDate": "De huurdatum mag niet voor de geboortedatum liggen",
  "Year seniority bank cannot be before birth date": "De datum van bancaire anciënniteit mag niet vóór de geboortedatum liggen",
  "difference between employment date and birth date must be at least 16 years": "Het verschil tussen de datum van aanstelling en de geboortedatum moet minimaal 16 jaar zijn",
  "difference between bank seniority date and birth date must be at least 16 years": "",
  "employmentDate cannot be in future": "De huurdatum mag niet in de toekomst liggen",
  "Must be a whole number": "Moet een heel getal zijn",
  "Must be a number": "Moet een nummer zijn",
  "Email invalid": "Ongeldig e-mailadres",
  "Please assert you're the real card owner": "Controleer of u de eigenaar van deze betaalkaart bent",
  "Not a valid MM value": "Maand in MM-indeling vereist",
  "Not a valid YYYY date": "Jaar in JJJJ-indeling vereist",
  "Not a valid date": "Ongeldige datum",
  "Can't be before current year": "Mag niet vóór het huidige jaar zijn",
  "Must be composed of 3 digits": "Moet uit drie cijfers bestaan",
  "Must be composed of 12-19 digits": "Moet 12-19 cijfers zijn",
  "Not a valid YYYY-MM-DD date": "Voer een datum in met de notatie DD / MM / JJJJ",
  "Residence date cannot be before birth date": "De verblijfsdatum mag niet vóór de geboortedatum liggen",
  "This date cannot be in future": "Deze datum mag niet in de toekomst liggen",
  "Employment date cannot be before birth date": "De huurdatum mag niet voor de geboortedatum liggen",
  "Employment date cannot be in future": "De huurdatum mag niet in de toekomst liggen",
  "Please pick a correct value": "Kies een juiste waarde",
  /* ========================================== */
  /* OK KO BACK =============================== */
  /* ========================================== */
  "Your request is already finalized": "Uw verzoek is al afgerond",
  "You will receive a final response within 24 hours.": "Je krijgt binnen 24 uur een definitief antwoord.",
  "Thank you for your request.": "Dankjewel voor je aanvraag.",
  "Your request has already been validated": "Uw verzoek is al gevalideerd",
  "The information you have entered can no longer be changed.": "De informatie die u heeft ingevoerd, kan niet meer worden gewijzigd.",
  "Please click on the button below to finalize your order.": "Klik op de onderstaande knop om uw bestelling af te ronden.",
  "Finalize your order": "Rond uw bestelling af",
  "Operation failed": "Uw verzoek is mislukt",
  "Your order is canceled. The amount of the upfront will not be deducted from you.": "Uw bestelling is geannuleerd en het bedrag van uw eerste betaling wordt niet afgeschreven.",
  "Request denied": "Verzoek geweigerd",
  "We regret to inform you that we cannot give a favorable response to your request.": "Helaas moeten we u meedelen dat uw aanvraag is geweigerd.",
  "Maintenance": "Onderhoud",
  "The app is temporarily unavailable.": "De app is tijdelijk niet beschikbaar.",
  "Please come back later.": "Bedankt dat je later terug bent gekomen.",

};
