import React, { Component } from "react";
import { Col, Row, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { loc } from "../../core/utils";
import {
  computeServicePackPriceFromRent,
  getNotFineancableInsurances,
  isPromoAssociatedToOrder,
  getPromotionType,
  getPromotionValueType,
  getPromotionValue, getPromotionCode,
  getPromotionDescription, DISCOUNT_PROVISION_REDUCTION_AMOUNT, DISCOUNT_PROVISION_FIXED_AMOUNT, FREE_RENT,
  PROMOTION_VALUE_TYPE, PROMOTION_PERCENT_TYPE
} from "../../core/service/BusinessLogicService";
import CustomizedTooltip from "../CustomizedTooltip";

class Basket extends Component {

  componentWillUnmount() {
    document.body.style.position = ''
  }

  render() {
    const { tunnelData } = this.props;
    const product = this.props?.tunnelData?.data?.order?.products?.[0] || {};
    let notFinanceableInsurances = getNotFineancableInsurances(tunnelData?.data?.order);
    let isPromotionActiveOnOrder = isPromoAssociatedToOrder(tunnelData?.data?.order);
    let servicePackPriceFromRent = computeServicePackPriceFromRent(tunnelData);

    return (
      <Row>
        <Col>
          <aside className="basket">

            <Row>
              <Col>
                <h1>{loc`Basket`}</h1>
              </Col>
            </Row>

            {/* ———————————————————————————————————————————————————————————— */}
            {/* ————————————————————————— PRODUCT —————————————————————————— */}
            {/* ———————————————————————————————————————————————————————————— */}
            <Row className="product d-flex align-items-center">
              <Col xs={2}>
                <div className="product-image">
                  <img src={product?.picture || ""} alt="" />
                </div>
              </Col>
              <Col>
                <ul className="list-unstyled align-content-center">
                  <li><small>{product?.categoryEvollis?.labelCategoryParent || ""}</small></li>
                  <li><h3>{product?.designation || ""}</h3></li>
                </ul>
              </Col>
            </Row>

            {/* ———————————————————————————————————————————————————————————— */}
            {/* —————————————————————— ORDER SUMMARY ——————————————————————— */}
            {/* ———————————————————————————————————————————————————————————— */}
            <Row>
              <Col>
                <ul className="order-summary list-unstyled">
                  <li>
                    <Row>
                      <Col><p>{loc`Shipping fees`}</p></Col>
                      <Col><p className="text-right">{loc`Offered`}</p></Col>
                    </Row>
                  </li>
                  {isPromotionActiveOnOrder &&
                    <li>
                      <Row>
                        <Col><p>{loc`Promo code`}</p></Col>
                        <Col><p className="text-right">{getPromotionCode(tunnelData?.data?.order?.promotionDTOs[0]) || ""}</p></Col>
                      </Row>
                    </li>}
                  {tunnelData?.data?.order?.rent?.firstCustomerPayment &&
                    <li>
                      <Row>
                        <Col>
                          <p>{loc`Upfront`}</p>
                          {isPromotionActiveOnOrder
                            && getPromotionType(tunnelData?.data?.order?.promotionDTOs[0]) == DISCOUNT_PROVISION_REDUCTION_AMOUNT
                            && getPromotionValueType(tunnelData?.data?.order?.promotionDTOs[0]) == PROMOTION_PERCENT_TYPE &&
                            <span class="badge badge-pill badge-primary"> - {getPromotionValue(tunnelData?.data?.order?.promotionDTOs[0]) || 0} %</span>
                          }
                          {isPromotionActiveOnOrder
                            && getPromotionType(tunnelData?.data?.order?.promotionDTOs[0]) == DISCOUNT_PROVISION_REDUCTION_AMOUNT
                            && getPromotionValueType(tunnelData?.data?.order?.promotionDTOs[0]) == PROMOTION_VALUE_TYPE &&
                            <span class="badge badge-pill badge-primary"> - {getPromotionValue(tunnelData?.data?.order?.promotionDTOs[0]) || 0} €</span>
                          }
                        </Col>
                        <Col><p className="text-right">{tunnelData?.data?.order?.rent?.firstCustomerPayment || 0} €
                      {isPromotionActiveOnOrder && (getPromotionType(tunnelData?.data?.order?.promotionDTOs[0]) == DISCOUNT_PROVISION_REDUCTION_AMOUNT || getPromotionType(tunnelData?.data?.order?.promotionDTOs[0]) == DISCOUNT_PROVISION_FIXED_AMOUNT) &&
                            <s className="strike">{tunnelData?.data?.order?.rent?.rawRentDTO.provision || 0} €</s>}
                        </p></Col>
                      </Row>
                    </li>}
                  {tunnelData?.data?.order?.rent?.monthlyTotal &&
                    <li>
                      <Row>
                        <Col><p>{loc`Rent`} {loc`on`} {tunnelData?.data?.order?.rent?.duration || 0} {loc`months`}</p></Col>
                        <Col><p className="text-right">{tunnelData?.data?.order?.rent?.monthlyTotal || 0} € <small>{loc`/month`}</small></p></Col>
                      </Row>
                      <Row>
                        {isPromotionActiveOnOrder && getPromotionType(tunnelData?.data?.order?.promotionDTOs[0]) == FREE_RENT
                          && <Col><p><small>{tunnelData?.data?.order?.promotionDTOs[0]?.code || ""}</small></p></Col>}
                        {isPromotionActiveOnOrder && getPromotionType(tunnelData?.data?.order?.promotionDTOs[0]) == FREE_RENT
                          && <Col><p className="text-right"><small><span class="badge badge-pill badge-primary">{getPromotionDescription(tunnelData?.data?.order?.promotionDTOs[0]) || ""}</span></small></p></Col>}

                      </Row>
                      {servicePackPriceFromRent > 0 &&
                        <Row>
                          <Col><p><small>{loc`including services pack at`}</small></p></Col>
                          <Col><p className="text-right"><small>{servicePackPriceFromRent} € {loc`/month`}</small></p></Col>
                        </Row>}
                    </li>}
                </ul>
              </Col>
            </Row>

            {/* ———————————————————————————————————————————————————————————— */}
            {/* ———————————————————— INCLUDED SERVICES ————————————————————— */}
            {/* ———————————————————————————————————————————————————————————— */}
            {tunnelData?.data?.order?.selectedServicePack?.name &&
              <Row>
                <Col>
                  <div className="soft-shadow-white-block included-services">
                    <p>{tunnelData?.data?.order?.selectedServicePack?.name}</p>
                    <div dangerouslySetInnerHTML={{ __html: tunnelData?.data?.order?.selectedServicePack?.description }} />
                  </div>
                </Col>
              </Row>}

            {/* ———————————————————————————————————————————————————————————— */}
            {/* ———————————————————————— INSURANCE ————————————————————————— */}
            {/* ———————————————————————————————————————————————————————————— */}
            {notFinanceableInsurances && notFinanceableInsurances.length > 0 &&
              <Row>
                <Col>
                  <div className="soft-shadow-white-block">
                    {notFinanceableInsurances.map((notFinanceableInsurance) => {
                      return (<Row>
                        <Col><p>{notFinanceableInsurance.label}</p></Col>
                        <Col><p className="text-right">{(notFinanceableInsurance.servicePrice / notFinanceableInsurance.duration).toFixed(2)} € <small>{loc`/month`}</small></p></Col>
                      </Row>);
                    })}
                  </div>
                </Col>
              </Row>}

            {/* ———————————————————————————————————————————————————————————— */}
            {/* ———————————————————— REQUIRED DOCUMENTS ———————————————————— */}
            {/* ———————————————————————————————————————————————————————————— */}
            {tunnelData.dipslayProofDocuments &&
              <Row>
                <Col>
                  <div className="required-documents margin-top-56">
                    <Row>
                      <Col>
                        <h3>{loc`Supporting documents requested`}</h3>
                        <ul className="list-unstyled">
                          {this.props?.tunnelData.onBoardingDocuments.map((document, index) => {
                            return (
                              <li>
                                <CustomizedTooltip document={document} />
                              </li>
                            );
                          })}
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>}

          </aside>
        </Col>
      </Row>
    )
  }
}

export default Basket;