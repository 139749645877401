import React, { Component } from "react";
import { Field as FinalFormField } from "react-final-form";
import { Alert, Form, FormGroup, Col, Row} from "react-bootstrap";
import { AccessKeysContext } from "../context/AccessKeysContext";
import { REQUIRED_MESSAGE } from "./defaultMessages";

export default class CheckboxField extends Component {
  validate = (value, accessKeys) => {
    const { name } = this.props;
    const ak = accessKeys[name] || {};
    const { required, requiredMessage = REQUIRED_MESSAGE } = { ...this.props, ...ak };
    if (required && value === undefined) {
      return requiredMessage;
    }
  };

  render() {
    return (
      <AccessKeysContext.Consumer>
        {(accessKeys = {}) => {
          const { name } = this.props;
          const ak = accessKeys[name] || {};
          const {
            hidden,
            placeholder,
            label,
            className,
            xs,
            lg,
            xl,
            md,
            sm,
            xsHidden,
            smHidden,
            mdHidden,
            lgHidden,
            xlHidden,
            disabled,
            hasServerErrors,
            noFormGroup,
            type = "checkbox"
          } = { ...this.props, ...ak };

          if (hidden) {
            return false;
          }

          return (
            <FinalFormField
              name={name}
              component="input"
              type={type}
              placeholder={placeholder}
              className="form-control"
              validate={value => {
                return this.validate(value, accessKeys);
              }}
            >
              {({ input, meta }) => {
                const metaError = meta.error && (meta.touched || hasServerErrors);
                const hasError = metaError || meta.submitError;
                const cl = {
                  "d-none": xsHidden || smHidden || mdHidden || lgHidden || xlHidden,
                  "d-sm-block": !smHidden,
                  "d-sm-none": smHidden,
                  "d-md-block": !mdHidden,
                  "d-md-none": mdHidden,
                  "d-lg-block": !lgHidden,
                  "d-lg-none": lgHidden,
                  "d-xl-block": !xlHidden,
                  "d-xl-none": xlHidden,
                  "col-no-padding": true
                };
                if (className) {
                  cl[className] = true;
                }
                const hasColParam =
                  xs || lg || xl || md || sm || xsHidden || smHidden || mdHidden || lgHidden || xlHidden;
                const colParams = { className: cl, xs, lg, xl, md, sm };

                let body = [
                  <Row className="optin" noGutters noFormGroup>
                    <FinalFormField
                        disabled={disabled}
                        name={name}
                        component="input"
                        type="checkbox"
                    />
                    <React.Fragment key="label">{label && <Form.Label>{label}</Form.Label>}
                    <React.Fragment key="alert">
                      {hasError && <p className="required-message">{meta.error || meta.submitError}</p>}
                    </React.Fragment></React.Fragment>
                  </Row>
                ];

                if (!noFormGroup) {
                  body = <FormGroup>{body}</FormGroup>;
                }

                if (hasColParam) {
                  body = <Col {...colParams}>{body}</Col>;
                }

                return body;
              }}
            </FinalFormField>
          );
        }}
      </AccessKeysContext.Consumer>
    );
  }
}
