export function utcDate(localDate) {
  return new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate()));
}

export function formatCurrency(number, locale, options) {
  if (!number) return number;
  // this is a hack to make it simpler when we want no digits
  if (options === 0) options = { minimumFractionDigits: 0, maximumFractionDigits: 0 };
  else if (!options) options = {};

  let right = locale ? locale.split("-")[1] : "FR";
  options.currency = right === "GB" ? "GBP" : right === "US" ? "USD" : "EUR";
  options.style = "currency";
  return new Intl.NumberFormat(locale, options).format(number);
}

export function formatDecimal(number, locale) {
  return new Intl.NumberFormat(locale).format(number);
}

export function formatPercentage(number, locale) {
  if (!number) return number;
  number = (100 * number).toFixed(2);
  return number + "%";
}

export function formatDate(date, locale, option) {
  if (!date) return date;
  return date.toLocaleDateString(locale, option);
}

export function formatDateTime(date, locale, option) {
  return date ? date.toLocaleString(locale, option) : date;
}

export function roundCurrency(number, locale) {
  let round = locale === "jp-JP" ? 1000 : 100;
  return number ? Math.round((number + 0.00001) * round) / round : number;
}

export const deepSet = (obj, path, value) => {
  if (Object(obj) !== obj) return obj; // When obj is not an object
  // If not yet an array, get the keys from the string-path
  if (!Array.isArray(path)) path = path.toString().match(/[^.[\]]+/g) || [];
  path.slice(0, -1).reduce(
    (
      a,
      c,
      i // Iterate all of them except the last one
    ) =>
      Object(a[c]) === a[c] // Does the key exist and is its value an object?
        ? // Yes: then follow that path
          a[c]
        : // No: create the key. Is the next key a potential array-index?
          (a[c] =
            Math.abs(path[i + 1]) >> 0 === +path[i + 1]
              ? [] // Yes: assign a new array object
              : {}), // No: assign a new plain object
    obj
  )[path[path.length - 1]] = value; // Finally assign the value to the last key
  return obj; // Return the top-level object to allow chaining
};

export const deepGet = (object, keys, defaultVal) => {
  keys = Array.isArray(keys) ? keys : keys.split(".");
  object = object[keys[0]];
  if (object && keys.length > 1) {
    return deepGet(object, keys.slice(1));
  }
  return object === undefined ? defaultVal : object;
};

export function momentDateFormat(locale, withDay) {
  // could not find a standard function to do this so hardcoding for now :-(
  if (!locale) return "DD-MM-YYYY";
  let right = locale.split("-")[1];
  let format = withDay ? "ddd " : "";
  format += right === "US" ? "MM-DD-YYYY" : "FR" ? "DD/MM/YYYY" : "DD-MM-YYYY";
  return format;
}

// used for JSON patch on incoming (server and client)
var regexIso8601 = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?(Z)?$/;
export function convertDateStringsToDates(input) {
  // Ignore things that aren't objects.
  if (typeof input !== "object") return input;

  for (var key in input) {
    if (!Object.prototype.hasOwnProperty.call(input, key)) continue;

    var value = input[key];
    var match;
    // Check for string properties which look like dates.
    if (typeof value === "string" && (match = value.match(regexIso8601))) {
      var milliseconds = Date.parse(match[0]);
      if (!isNaN(milliseconds)) {
        input[key] = new Date(milliseconds);
      }
    } else if (typeof value === "object") {
      // Recurse into object
      convertDateStringsToDates(value);
    }
  }
}

export function dateDiff(ed, sd) {
  let diff = (ed.getTime() - sd.getTime()) / (1000 * 60 * 60 * 24);
  return diff;
}

export function dateAddDays(date, nbDays) {
  // this code changes the time/timezone
  // let dt = new Date(date);
  // dt.setDate(date.getDate() + nbDays);
  let time = date.getTime() + 1000 * 60 * 60 * 24 * nbDays;
  let dt = new Date(time);
  // let diff = dateDiff(dt, date);
  // if (diff !== Math.floor(diff)) throw Error("!!!!!!Here!!!!!!" + dt + "---" + date + "===" + nbDays + "===" + diff);
  return dt;
}

export function dateFirstDayOfNextMonth(date, month) {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth() + month, 1));
}

export function formatAddress(address) {
  if (!address) return address;
  return [address.address1, address.address2, address.zipcode, address.city].filter(it => it).join(" ");
}

export default {
  formatCurrency,
  formatDecimal,
  formatDate,
  roundCurrency
};
