/* React Boostrap */
import React, { Component } from "react";
import {
  Button, Col, Container, Row, Carousel, OverlayTrigger} from "react-bootstrap";

/* Components */
import Topbar from "../components/main/Topbar";
import Footer from "../components/main/Footer";
import CustomizedTooltip from "../components/CustomizedTooltip"

/* Utils */
import { loc } from "../core/utils";
import {initPdiComponent} from "../core/service/BusinessLogicService";

class OnboardingPage extends Component {
  state: {};

  componentDidMount() {
    initPdiComponent("OnBoardingPage");
  }

  render() {
    return (
      <Container fluid>

        <Row noGutters>
          <Col className="misc-block">
            <Row>
              <Topbar tunnelData={this.props?.tunnelData} displayToggleButton={false}/>

              <Col sm={12}>
                {/* CONTENT */}
                <Container className="onboarding-container container-wider">
                  <Row className="justify-content-center">
                    <Col lg={9} className="text-center">
                      {this.props.tunnelData.onBoardingFirstSubtitle.map((subtitlePart, index) => {
                        if (index === 0) {
                          return <h1>{loc(subtitlePart)}</h1>;
                        }
                        else {
                          return <p>{loc(subtitlePart)}</p>;
                        }
                      })}
                    </Col>
                  </Row>
                  <Row className="block-steps">
                    {this.props?.tunnelData.breadcrumbs.map((step, index) => {
                          if (step.title && step.description) {
                            return <Col key={index} sm={12} md={true} className="block-step hard-shadow-white-block text-center">
                              <h3>{loc(step.title)}</h3>
                              <p>{loc(step.description)}</p>
                            </Col>;
                          }
                          else {
                            return null;
                          }
                    })}
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <h3>{loc(this.props.tunnelData.onBoardingSecondSubtitle)}</h3>
                    </Col>
                  </Row>
                  <Row className="justify-content-center text-center onboarding-documents">
                    {this.props?.tunnelData.onBoardingDocuments.map( (document, index) => 
                      <Col xs={6} md={2} key={index}>
                          <CustomizedTooltip document={document} isParagraphed={true}/>
                      </Col>
                    )}
                  </Row>
                  <Row className="margin-top-56">
                    <Col lg={{ span: 3, order: 1 }} md={{ span: 4, order: 1 }} xs={{ span: 12, order: 2 }} className="mr-auto">
                      <Button href={this.props?.tunnelData?.shopUrl} variant="outline-primary" className="ico-back-small-before">
                        {loc`Back to shop`}
                      </Button>
                    </Col>
                    <Col lg={{ span: 3, order: 2 }} md={{ span: 4, order: 2 }} xs={{ span: 12, order: 1 }} className="align-item-end">
                      <Button variant="success" className="btn-solid btn" disabled={this.state?.paymentButtonLocked === true} onClick={() => this.onValidate()}>
                        {loc`Start`}
                      </Button>
                    </Col>
                  </Row>
                </Container>
                {/* END CONTENT */}
              </Col>

              <Col sm={12}>
                <Container className="container-wider">
                  <Footer tunnelData={this.props?.tunnelData}/>
                </Container>
              </Col>
            </Row>
          </Col>
        </Row>

      </Container>
    );
  }

  onValidate(){
    this.props.onSetStep();
  }
}

export default OnboardingPage;