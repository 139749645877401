import { makeValidate } from "../../form/makeValidate";
import { loc } from "../../core/utils";
import * as yup from "yup";
import {missEnumCode} from "../../core/service/BusinessLogicService";

// error messages
export const requiredMessage = () => loc`Required`;
const maximumCharactersMessage = (maximumValue) => maximumValue + " " + loc`maximum characters`;
const regexMessage = () => loc`Unauthorized characters`;
const phoneMessage = () =>
  loc`Phone number must start with 06 or 07 and contain 10 numbers`;
const minimumCharactersMessage = (minimalValue) => minimalValue + " " +loc`minimum characters`;
const emailNotValidMessage = () => loc`Email invalid`;


// regex
const defaultRegex = /^[a-zA-ZêëéèçÊËÉÈÇàâäÀÂ' ÄùûüÙÛÜìïîÌÏÎòôöÒÔÖ]+(([ -][a-zA-Z êëéèçÊËÉÈÇàâäÀÂÄùûüÙÛÜìïîÌÏÎòôöÒÔÖ])?[a-zA-ZêëéèçÊËÉÈÇàâäÀÂÄùûüÙÛÜìïîÌÏÎòôöÒÔÖ]*)*$/;
const phoneRegex = /^33(6|7){1}[0-9]{8}$/;
const emailRegex = /^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9_\-\.]+\.[a-zA-Z0-9_\-\.]+$/;
// A choice has been made here : code reusability or performance : if we compose the regex dynamically perfs are affected.
// If perf problems are detected for validation, change this implementation
const minimumCharacterRegex = (minValue) => "^.{"+ minValue +",}$";
const maximumCaracterRegex = (maxValue) => "^.{0,"+ maxValue + "}$";

// todo : refactor in helpers redundant test

let schema = yup.object().shape({
    person: yup.object().shape({
      title: yup.string().nullable().test({
        name : "titleTest",
        message : "",
        test(value){
          if (!value) {
            return this.createError({
              message: requiredMessage,
              path: "person.title",
            });
          }
        }
      }),
      name : yup.string().nullable().test({
        name : "nameTest",
        message : "",
        test(value){
          if (!value) {
            return this.createError({
              message: requiredMessage,
              path: "person.name",
            });
          }
          if (!value.match(defaultRegex)) {
            return this.createError({
              message: regexMessage,
              path: "person.name",
            });
          }
          if (!value.match(minimumCharacterRegex(2))) {
            return this.createError({
              message: minimumCharactersMessage(2),
              path: "person.name",
            });
          }
          if (!value.match(maximumCaracterRegex(32))) {
            return this.createError({
              message: maximumCharactersMessage(32),
              path: "person.name",
            });
          }
        }
      }),
      maidenName : yup
      .string().nullable()
      .when("title", {
        is: missEnumCode,
        then: yup.string().nullable().test({
          name : "maidenNameTest",
          message : "",
          test(value){
            if (!value && this.parent["title"] === missEnumCode) {
              return this.createError({
                message: requiredMessage,
                path: "person.maidenName",
              });
            }
            if (!value.match(defaultRegex)) {
              return this.createError({
                message: regexMessage,
                path: "person.maidenName",
              });
            }
            if (!value.match(minimumCharacterRegex(2))) {
              return this.createError({
                message: minimumCharactersMessage(2),
                path: "person.maidenName",
              });
            }
            if (!value.match(maximumCaracterRegex(32))) {
              return this.createError({
                message: maximumCharactersMessage(32),
                path: "person.maidenName",
              });
            }
          }
        }),
        otherwise : yup.string().nullable(),
      }),
      firstName : yup.string().nullable().test({
        name : "firstNameTest",
        message : "",
        test(value){
          if (!value) {
            return this.createError({
              message: requiredMessage,
              path: "person.firstName",
            });
          }
          if (!value.match(defaultRegex)) {
            return this.createError({
              message: regexMessage,
              path: "person.firstName",
            });
          }
          if (!value.match(minimumCharacterRegex(2))) {
            return this.createError({
              message: minimumCharactersMessage(2),
              path: "person.firstName",
            });
          }
          if (!value.match(maximumCaracterRegex(25))) {
            return this.createError({
              message: maximumCharactersMessage(25),
              path: "person.firstName",
            });
          }
        }
      }),
    }),
    customer: yup.object().shape({
      address: yup.object().shape({
        email: yup
        .string().nullable()
        .test({
          name: "emailTest",
          message: "",
          test(value) {
            if (!value) {
              return this.createError({
                message: requiredMessage,
                path: "customer.address.email",
              });
            }

            if (!value.match(emailRegex)) {
              return this.createError({
                message: emailNotValidMessage,
                path: "customer.address.email",
              });
            }
          },
        }),
        mobilePhone: yup
        .string()
        .nullable()
        .test({
          name: "mobilePhoneTest",
          message: "",
          test(value) {
            if (!value) {
              return this.createError({
                message: requiredMessage,
                path: "customer.address.mobilePhone",
              });
            }
            if (!value.match(phoneRegex)) {
              return this.createError({
                message: phoneMessage,
                path: "customer.address.mobilePhone",
              });
            }
          },
        }),
      }),
    }),
  });
const validate = makeValidate(schema);
export default validate;
