import React, {Component} from "react";
import {Switch} from "react-router-dom";

import OnboardingPage from "./OnboardingPage";
import IdentificationPage from "./IdentificationPage/IdentificationPage";
import ServicesPage from "./ServicesPage";
import CbPage from "./CbPage/CbPage";
import ClientPage from "./ClientPage/ClientPage";
import SummaryPage from "./SummaryPage";
import FailPage from "./FailPage";
import MaintenancePage from "./MaintenancePage";
import FinalPage from "./FinalPage";
import AcceptancePage from "./AcceptancePage";
import NetheosPage from "./NetheosPage";
import ComeBackLaterPage from "./ComeBackLaterPage";
import PaymentKOPage from "./PaymentKOPage";
import ScoreKOPage from "./ScoreKOPage";
import RouteWithAccessKeys from "./RouteWithAccessKeys";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return props.children;
}

class EvollisPagesLayout extends Component {
  render() {
    return (
        <>
          {/*Global styles taken from tunnelData. Add on this stylesheet only cross component styles.
        Component scoped styles must be include in this way, but in targetted component.*/}
          <style dangerouslySetInnerHTML={{
            __html: [
              'a {',
              this.props?.tunnelData?.links,
              '}',
              'a:hover {',
              this.props?.tunnelData?.linksHover,
              '}',
              '.title-step {',
              this.props?.tunnelData?.titleStep,
              '}',
              '.inline-steps > li.is-active, .list-steps > li.is-active {',
              this.props?.tunnelData?.breadcrumbIsActive,
              '}',
              '.inline-steps > li.is-active::before, .list-steps > li.is-active::before {',
              this.props?.tunnelData?.breadcrumbIsActiveIco,
              '}',
              '.btn-primary {',
              this.props?.tunnelData?.buttonPrimary,
              '}',
              '.btn-primary:hover, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active {',
              this.props?.tunnelData?.buttonPrimaryHoverActive,
              '}',
              '.btn-primary:focus {',
              this.props?.tunnelData?.buttonPrimaryFocus,
              '}',
              '.btn-success {',
              this.props?.tunnelData?.buttonSuccess,
              '}',
              '.btn-success:hover, .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active {',
              this.props?.tunnelData?.buttonSuccessHoverActive,
              '}',
              '.btn-success:focus {',
              this.props?.tunnelData?.buttonSuccessFocus,
              '}',
              '.btn-outline-primary {',
              this.props?.tunnelData?.buttonOutlinePrimary,
              '}',
              '.btn-outline-primary:hover, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active {',
              this.props?.tunnelData?.buttonOutlinePrimaryHoverActive,
              '}',
              '.btn-outline-primary:focus {',
              this.props?.tunnelData?.buttonOutlinePrimaryFocus,
              '}',
              '.required-documents-ico {',
              this.props?.tunnelData?.requiredDocumentsIco,
              '}',
              '.block-steps .hard-shadow-white-block {',
              this.props?.tunnelData?.topBorderOnboarding,
              '}',
              '.block-steps > .block-step:before {',
              this.props?.tunnelData?.blockStepOnboarding,
              '}',
              '.solid-brand-block {',
              this.props?.tunnelData?.solidBrandBlock,
              '}',
              '.btn-tooltip .fas {',
              this.props?.tunnelData?.tooltip,
              '}',
              'input[type="radio"]:checked, input[type="checkbox"]:checked {',
              this.props?.tunnelData?.inputChecked,
              '}',
            ].join('\n')
          }}>
          </style>
          <Switch>
            <ScrollToTop>
            <RouteWithAccessKeys
                path="/digital-journey/onboarding"
                render={routeProps =>
                    <OnboardingPage {...routeProps} {...this.props} />}
            />
            <RouteWithAccessKeys
                path="/digital-journey/services"
                render={routeProps =>
                    <ServicesPage {...routeProps} {...this.props} />}
            />
            <RouteWithAccessKeys
                path="/digital-journey/identification"
                render={routeProps =>
                    <IdentificationPage {...routeProps} {...this.props} />}
            />
            <RouteWithAccessKeys
                path="/digital-journey/acceptance"
                render={routeProps =>
                    <AcceptancePage {...routeProps} {...this.props} />}
            />
            <RouteWithAccessKeys
                path="/digital-journey/cb"
                render={routeProps =>
                    <CbPage {...routeProps} {...this.props} />}
            />
            <RouteWithAccessKeys
                path="/digital-journey/paymentko"
                render={routeProps => (
                    <PaymentKOPage {...routeProps} {...this.props} />
                )}
            />
            <RouteWithAccessKeys
                path="/digital-journey/fail"
                render={routeProps =>
                    <FailPage {...routeProps} {...this.props} />}
            />
            <RouteWithAccessKeys
                path="/digital-journey/maintenance"
                render={routeProps =>
                    <MaintenancePage {...routeProps} {...this.props} />}
            />
            <RouteWithAccessKeys
                path="/digital-journey/client"
                render={routeProps => (
                    <ClientPage {...routeProps} {...this.props} />
                )}
            />
            <RouteWithAccessKeys
                path="/digital-journey/summary"
                render={routeProps => (
                    <SummaryPage {...routeProps} {...this.props} />
                )}
            />
            <RouteWithAccessKeys
                path="/digital-journey/score-ko"
                render={routeProps => (
                    <ScoreKOPage {...routeProps} {...this.props} />
                )}
            />
            <RouteWithAccessKeys
                path="/digital-journey/netheos"
                render={routeProps => (
                    <NetheosPage {...routeProps} {...this.props} />
                )}
            />
            <RouteWithAccessKeys
                path="/digital-journey/signature-paused"
                render={routeProps => (
                    <ComeBackLaterPage {...routeProps} {...this.props} />
                )}
            />
            <RouteWithAccessKeys
                path="/digital-journey/final"
                render={routeProps =>
                    <FinalPage {...routeProps} {...this.props} />}
            />
            </ScrollToTop>
          </Switch>
        </>
    );
  }
}

export default EvollisPagesLayout;
