import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { loc } from "../../core/utils";
import {replaceMailAndLinkWithProperHtmlTemplate} from "../../core/service/BusinessLogicService";

class Footer extends Component {
  componentDidMount() {
    let legalsToDisplay = this.props.tunnelData.legalMentionBaseBloc;
    if (this.props.serviceDisplay) {
      legalsToDisplay = legalsToDisplay.concat(this.props.tunnelData.legalMentionServiceBloc)
    }
    else if(this.props.infosAndClientPageDisplay) {
      legalsToDisplay = legalsToDisplay.concat(this.props.tunnelData.summaryPageLegalTexts)
    }
    this.setState({legalsToDisplay : legalsToDisplay})
  }

  render() {
    return (
      <>
        <footer>
          <Row>
            <Col>
              {this.state?.legalsToDisplay.map((legalText, index) => {
                return <div dangerouslySetInnerHTML={{__html : '<p><small>' + replaceMailAndLinkWithProperHtmlTemplate(loc(legalText)) + '</small></p>'}} />
              })}
            </Col>
          </Row>
        </footer>
      </>
    )
  }
}

export default Footer;