import React, {Component} from "react";
import FailPage from "../digital-journey/FailPage";
import {sendToLogServer} from "./service/BusinessLogicService";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  async componentDidCatch(error, info) {
    this.setState({hasError: true});
    sendToLogServer("ERROR", "" + error.message + " " + info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      return <FailPage/>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;