import React, { Component } from "react";
import { Row, Col, Container, ProgressBar, Navbar, Nav} from "react-bootstrap";
import { loc } from "../../core/utils";
import Basket from "../../components/aside/Basket";
import './styles.css'



class Topbar extends Component {
  
  onDisableBodyScroll = () => {
    
    if(document.body.style.position === ''){
      document.body.style.position = 'fixed'
    } else {
      document.body.style.position = ''
    }
  }

  render() {
    const { tunnelData } = this.props;
    
    return (
      <Col sm={12} className="topbar-container">
        <Navbar className="d-block d-lg-none d-xl-none" collapseOnSelect expand="lg" fixed="top">
          <Navbar.Brand href="#home"><img src={this.props?.tunnelData?.brandLogo} alt="brand logo image" /></Navbar.Brand>

          {this.props?.displayToggleButton !== false && <Navbar.Toggle onClick={this.onDisableBodyScroll} aria-controls="responsive-navbar-nav"/>}

          <Navbar.Collapse id="responsive-navbar-nav" className="basket-collapse">
            <Nav className="mr-auto">
              <Basket tunnelData={this.props?.tunnelData || null} />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Container className="topbar d-none d-lg-block d-xl-block">
          <Row>
            <Col><img src={this.props?.tunnelData?.brandLogo} alt="brand logo image" /></Col>
          </Row>
        </Container>
      </Col>
    )
  }
}

export default Topbar;