/* React Boostrap */
import React, {Component} from "react";
import { Button, Col, Container, Row, Tooltip, OverlayTrigger} from "react-bootstrap";
/* Components */
import Topbar from "../components/main/Topbar";
import Footer from "../components/main/Footer";
/* Utils */
import {initPdiComponent} from "../core/service/BusinessLogicService";
import ApiService from "../core/service/ApiService";
import {loc} from "../core/utils";
import CustomizedTooltip from "../components/CustomizedTooltip";

class AcceptancePage extends Component {
  state = {
    offerDroppingDelay: 0
  };

  async componentDidMount() {
    initPdiComponent("AcceptancePage");
    let {tunnelData} = this.props;
    const getOfferDroppingDelay = this.props?.tunnelData.apiUrl
        + "api/v2/offers/" + tunnelData.opl + "/dropping-delay";
    const token = this.props.tunnelData.access_token;
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "*/*",
    };
    let result = await ApiService.getForEvollisApi(getOfferDroppingDelay, headers);
    let shopUrl = this.props?.tunnelData?.shopUrl;
    if (tunnelData.data.order.orderLanguageTag === "fr-BE" || tunnelData.data.order.orderLanguageTag === "nl-BE") {
      shopUrl.replace("OPL", tunnelData.opl);
    }
    this.setState({offerDroppingDelay : result});
    this.setState({shopUrl :  shopUrl})
  }

  render() {
    let { tunnelData } = this.props;
    let offerDroppingDelay = this.state?.offerDroppingDelay;
    let shopUrl = this.state?.shopUrl;
    return (
      <Container fluid>

        <Row noGutters>
          {/* ——————————————————————————————————————————————— */}
          {/* ———————————————————— MAIN ————————————————————— */}
          {/* ——————————————————————————————————————————————— */}
          <Col className="misc-block">
            <Row>
              <Topbar tunnelData={this.props?.tunnelData} displayToggleButton={false}/>

              <Col sm={12}>
                {/* CONTENT */}
                <Container>
                  <Row className="justify-content-center">
                    <Col xs={11} sm={10} md={10} lg={6} className="hard-shadow-white-block text-center misc-mono-block">
                      <span className="ico-success-background">
                        <i className="far fa-check"/>
                      </span>
                      <h1>{loc`Acceptance in principle`}</h1>
                      <p>{loc`A confirmation email of your order`} {tunnelData.opl} {loc`has just been sent.`}</p>
                      <p>{loc("Let's go ! I have ")} {offerDroppingDelay} {loc("days to go personally to a Micromania store to finalize my request with the following documents:")}</p>
                      <div className="required-documents">
                        <ul className="list-unstyled text-left">
                          {this.props?.tunnelData.clickAndCollectDocuments.map((document, index) => {
                            return (
                                <li key={index}>
                                  <CustomizedTooltip document={document}/>
                                </li>
                            );
                          })}
                        </ul>
                      </div>
                      <Button href={shopUrl} variant="primary" className="ico-back-small-before">
                        {loc`Back to shop`}
                      </Button>

                      
                    </Col>
                  </Row>
                </Container>
                {/* END CONTENT */}
              </Col>
              <Col sm={12}>
                <Container>
                  <Footer tunnelData={this.props?.tunnelData} />
                </Container>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default AcceptancePage;
