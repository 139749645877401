/* React Boostrap */
import React, {Component} from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  OverlayTrigger,
  Row,
  Tooltip
} from "react-bootstrap";
/* Components */
import Basket from "../../components/aside/Basket";
import Topbar from "../../components/main/Topbar";
import Header from "../../components/main/Header";
import Footer from "../../components/main/Footer";
import BreadcrumbMobile from "../../components/main/BreadcrumbMobile";
import { getCountryFromLanguage } from "../../core/service/BusinessLogicService";
/* Forms controls & validation */
import * as FinalForm from "react-final-form";
import TextField from "../../form/TextField";
import NumberField from "../../form/NumberField";
import RadiosField from "../../form/RadiosField";
import DateField from "../../form/DateField";
import SelectField from "../../form/SelectField";
import NumberFieldPhone from "../../form/NumberFieldPhone";
/* Utils */
import _ from "lodash";
import {loc, userConfigurationService} from "../../core/utils";
import validate from "./validate";
import {
  getCivilStatuses, initPdiComponent,
  isASocioProfessionalCategoryWithoutContract,
  missEnumCode,
  REPOSITORY_SEPARATOR,
} from "../../core/service/BusinessLogicService";
import {mapValidateReturnObjectToFitFinalFormRequirements} from "../../form/makeValidate";

const emptyTunnelData = {data: {}};
const DATE_FORMAT = "DD/MM/YYYY"; // todo modularité
const YEAR_FORMAT = "YYYY";  // todo modularité
const formPartBaseClassName = " header-info ";
const formPartFinishedClassName = formPartBaseClassName + " is-success ";
const mariedStatusValue = "1";

class ClientPage extends Component {
  state = {
    submitButtonLocked: false,
    violationsConfirmed: []
  };

  async componentDidMount() {
    initPdiComponent("ClientPage");
    this.setState({
      "civilStatuses": await getCivilStatuses(this.props?.tunnelData, true)
    });
  }

  lockSubmitButton = async () => {
    await this.setState(
        {submitButtonLocked: this.state.submitButtonLocked !== true});
  };

  onSubmit = async values => {
    await this.lockSubmitButton();
    if (!values.person.title
        && this.props.tunnelData?.data?.order?.customer?.person?.title) {
      values.person.title = this.props.tunnelData.data.order.customer.person.title;
    }
    if (values.person.title !== missEnumCode && values.person.maidenName) {
      values.person.maidenName = undefined;
    }
    if (values.customer.employmentDate
        && isASocioProfessionalCategoryWithoutContract(
            this.props.tunnelData.socioProfessionalcategoriesWithoutContract,
            values.customer.occupationCode)) {
      values.customer.employmentDate = undefined;
    }
    const submitErrors = await this.props.onSetStep(values);
    console.log(submitErrors);
    return submitErrors;
  };

  getSocioProfessionalCategories() {
    let tunnelData = this.props.tunnelData;
    let socioProfessionalCategories =
        tunnelData.socioProfessionalcategories || [];
    let result = [];
    for (const [key, value] of Object.entries(socioProfessionalCategories)) {
      result.push({value: key, label: value});
    }
    result.sort(function (scp, otherScp) {
      if (scp.label < otherScp.label) {
        return -1;
      }
      if (scp.label > otherScp.label) {
        return 1;
      }
      return 0;
    });
    return result;
  }

  getbirthDepartments() {
    let tunnelData = this.props.tunnelData;
    let birhtDepartments = tunnelData.birhtDepartments || [];
    let result = [];
    for (const [key, value] of Object.entries(birhtDepartments)) {
      result.push({value: key, label: key + REPOSITORY_SEPARATOR + value});
    }
    result.sort(function (birhtDepartment, otherbirhtDepartments) {
      if (birhtDepartment.label < otherbirhtDepartments.label) {
        return -1;
      }
      if (birhtDepartment.label > otherbirhtDepartments.label) {
        return 1;
      }
      return 0;
    });
    return result;
  }

  getUserCountryCode(){
    let config = userConfigurationService.getUserConfiguration();
    if (config) {
      return config.user.locale.substr(config.user.locale.indexOf('-') + 1);
    } else {
      return null;
    } 
  }

  getHousingTypes() {
    let tunnelData = this.props.tunnelData;
    let housingTypes = tunnelData.housingTypes || [];
    let result = [];
    for (const [key, value] of Object.entries(housingTypes)) {
      result.push({value: key, label: value});
    }
    result.sort(function (reference, otherReference) {
      if (reference.label < otherReference.label) {
        return -1;
      }
      if (reference.label > otherReference.label) {
        return 1;
      }
      return 0;
    });
    return result;
  }

  getFamilySituations() {
    let tunnelData = this.props.tunnelData;
    let familySituations = tunnelData.familySituations || [];
    let result = [];
    for (const [key, value] of Object.entries(familySituations)) {
      result.push({value: key, label: value});
    }
    result.sort(function (reference, otherReference) {
      if (reference.label < otherReference.label) {
        return -1;
      }
      if (reference.label > otherReference.label) {
        return 1;
      }
      return 0;
    });
    return result;
  }

  // todo clement formatter le bic et l'iban auto + ameliorer visuel saisie
  render() {
    const { tunnelData = emptyTunnelData} = this.props;
    const hasServerErrors = !!tunnelData.errors;
    let customer = this.loadCustomerDataRelyingOnComponentContext();
    let person = this.loadPersonDataRelyingOnComponentContext();

    return (
        <Container fluid className="white-background">

          <Row noGutters>
            {/* ——————————————————————————————————————————————— */}
            {/* ———————————————————— MAIN ————————————————————— */}
            {/* ——————————————————————————————————————————————— */}
            <Col className="main-block">
              <Row>
                <Topbar tunnelData={this.props?.tunnelData}/>
                <Header tunnelData={this.props?.tunnelData}/>


                <Col sm={12}>
                  {/* CONTENT */}
                  <FinalForm.Form
                      initialValues={tunnelData.data}
                      validate={async values => {
                        if (!values.person.title) {
                          values.person.title = this.props.tunnelData?.data?.order?.customer?.person?.title || person.title;
                        }
                        if (!values.person.birthCountryCode) {
                          values.person.birthDepartmentCode=null;
                        }
                        if (!values.person.birthDepartmentCode) {
                          values.person.birthCity=null;
                        }
                        if (!values.person.title) {
                          values.person.title = this.props.tunnelData?.data?.order?.customer?.person?.title || person.title;
                        }
                        
                        if (values.customer
                            && values.customer.needEmploymentDate === "YES"
                            && this.props.tunnelData
                            && this.props.tunnelData.socioProfessionalcategoriesWithoutContract
                            && values.customer.occupationCode
                            && await isASocioProfessionalCategoryWithoutContract(
                                this.props.tunnelData.socioProfessionalcategoriesWithoutContract,
                                values.customer.occupationCode)) {
                          values.customer.needEmploymentDate = undefined;
                        }
                        // client side errors
                        const res = await validate({...values, journeyCountryCode: this.getUserCountryCode()});
                        let newValidationResult;
                        if (!_.isEmpty(res)) {
                          this.flagInputKeysInError(res, true);
                          newValidationResult = await mapValidateReturnObjectToFitFinalFormRequirements(res);
                          return newValidationResult;
                        }
                      }}
                      onSubmit={this.onSubmit}
                      render={({
                        handleSubmit,
                        values,
                      }) => {
                        let socioProfessionalCategoryNeedsContractDate = (!values
                            || !values.customer
                            || !values.customer.occupationCode)
                            || (values.customer.occupationCode
                                && !isASocioProfessionalCategoryWithoutContract(
                                    tunnelData.socioProfessionalcategoriesWithoutContract,
                                    values.customer.occupationCode));
                        let maidenNameNeedDisplay = (values?.person?.title && values?.customer?.maritalStatus === mariedStatusValue);
                        let identityCounter = this.refreshIdentityCounterFromFormValues(
                            values, maidenNameNeedDisplay);
                        const identityMaxInputs = maidenNameNeedDisplay === true
                            ? 9 : 8;
                        let situationCounter = this.refreshSituationCounterFromFormValues(
                            values, socioProfessionalCategoryNeedsContractDate);
                        const situationMaxInputs = socioProfessionalCategoryNeedsContractDate
                        === true ? 6 : 5;
                        let financesCounter = this.refreshFinancesCounterFromFormValues(
                            values);
                        const financesMaxInputs = 5;
                        let coordinatesCounter = this.refreshCoordinatesCounterFromFormValues(
                            values);
                        const coordinatesMaxInputs = 10;

                        let identityPartClassName = this.chooseFormPartClassNameFromState(
                            identityMaxInputs, identityCounter);
                        let situationPartClassName = this.chooseFormPartClassNameFromState(
                            situationMaxInputs, situationCounter);
                        let coordinatesPartClassName = this.chooseFormPartClassNameFromState(
                            coordinatesMaxInputs, coordinatesCounter);
                        let financesPartClassName = this.chooseFormPartClassNameFromState(
                            financesMaxInputs, financesCounter);
                        let isSimplifiedJourneyEnableForOrder = tunnelData.orderHasSimplifiedJourney;

                        return (
                            <Form onSubmit={handleSubmit}>
                              <Container>
                                <Row>
                                  <Col lg={6}>
                                    <header className={identityPartClassName}>
                                      <Row className="justify-content-between">
                                        <Col className="align-self-center">
                                          <h2>{loc("My identity")}</h2>
                                        </Col>
                                        <Col className="align-self-center">
                                          <p className="counter">{identityCounter} / {identityMaxInputs}</p>
                                        </Col>
                                      </Row>
                                    </header>
                                    <div className="hard-shadow-white-block">
                                      <RadiosField
                                          name="person.title"
                                          items={this.state.civilStatuses || []}
                                          label={loc`Title`}
                                          hasServerErrors={hasServerErrors}
                                          initialCustomValue={person.title}
                                          disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["person.title"].isNotEditable}
                                      />
                                      <TextField
                                          name="person.name"
                                          label={loc`Last name`}
                                          hasServerErrors={hasServerErrors}
                                          initialValue={person.name}
                                          trimOnBlur={true}
                                          disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["person.name"].isNotEditable}
                                      />
                                      <TextField
                                          name="person.firstName"
                                          label={loc`First name`}
                                          hasServerErrors={hasServerErrors}
                                          initialValue={person.firstName}
                                          trimOnBlur={true}
                                          disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["person.firstName"].isNotEditable}
                                      />
                                      <Row>
                                        <Col lg={8}>
                                          <SelectField
                                              name="person.citizenshipCountryCode"
                                              label={loc`Citizenship`}
                                              placeholder={loc`Select...`}
                                              restApiConfig={
                                              {
                                                url: this.props.tunnelData.apiUrl
                                                    + "middle-business-service/api/v2/repository/countries/codes/"
                                                    + this.getUserCountryCode(),
                                                headers: {
                                                  Authorization: `Bearer ${this.props.tunnelData.access_token}`,
                                                  Accept: "*/*"
                                                }
                                              }
                                              }
                                              hasServerErrors={hasServerErrors}
                                              initialValue={person.citizenshipCountryCode}
                                              disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["person.citizenshipCountryCode"].isNotEditable}
                                          />
                                        </Col>
                                        <Col lg={4}>
                                          <DateField
                                              name="person.birthDate"
                                              label={loc`Birthdate`}
                                              placeholder={loc`YYYY/MM/DD`}
                                              dateFormat={DATE_FORMAT}
                                              dateFormatServer={"YYYY-MM-DD"}
                                              hasServerErrors={hasServerErrors}
                                              initialValue={customer.birthDate}
                                              addSeparator={true}
                                              separator="/"
                                              disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["person.birthDate"].isNotEditable}
                                          />
                                        </Col>
                                      </Row>
                                      <SelectField
                                          name="person.birthCountryCode"
                                          label={loc`Country of birth`}
                                          placeholder={loc`Select...`}
                                          restApiConfig={
                                              {
                                                url: this.props.tunnelData.apiUrl
                                                    + "middle-business-service/api/v2/repository/countries/codes/"
                                                    + this.getUserCountryCode(),
                                                headers: {
                                                  Authorization: `Bearer ${this.props.tunnelData.access_token}`,
                                                  Accept: "*/*"
                                                }
                                              }
                                          }
                                          hasServerErrors={hasServerErrors}
                                          initialValue={person.birthCountryCode}
                                          disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["person.birthCountryCode"].isNotEditable}
                                      />
                                      <SelectField
                                          name="person.birthDepartmentCode"
                                          placeholder={loc`Select...`}
                                          label={loc`Department of birth`}
                                          restApiConfig={
                                            {
                                              url: this.props.tunnelData.apiUrl
                                              + "middle-business-service/api/v2/repository/departments/codes?"
                                              + "journeyCountryCode=" + getCountryFromLanguage(tunnelData.data.order.orderLanguageTag)
                                              + "&countryCode=" + values.person.birthCountryCode,
                                              headers: {
                                                Authorization: `Bearer ${this.props.tunnelData.access_token}`,
                                                Accept: "*/*"
                                              }
                                            }
                                          }
                                          hasServerErrors={hasServerErrors}
                                          initialValue={person.birthDepartmentCode}
                                          otherComponentValueToBind={values.person.birthCountryCode}
                                          disabled={
                                            !values.person.birthCountryCode ||
                                            (isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["person.birthDepartmentCode"].isNotEditable)}
                                      />
                                      <SelectField
                                          name="person.birthCity"
                                          label={loc`City of birth`}
                                          initialValue={person.birthCity}
                                          placeholder={loc`Select...`}
                                          restApiConfig={
                                            {
                                              url: this.props.tunnelData.apiUrl
                                                  + "middle-business-service/api/v2/repository/cities/?"
                                                  + "departmentId="+ values.person.birthDepartmentCode,
                                              headers: {
                                                Authorization: `Bearer ${this.props.tunnelData.access_token}`,
                                                Accept: "*/*"
                                              }
                                            }
                                          }
                                          hasServerErrors={hasServerErrors}
                                          otherComponentValueToBind={values.person.birthDepartmentCode}
                                          disabled={
                                            !values.person.birthDepartmentCode ||
                                            (isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["person.birthCity"].isNotEditable)}
                                      />

                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <header
                                        className={coordinatesPartClassName}>
                                      <Row className="justify-content-between">
                                        <Col className="align-self-center">
                                          <h2>{loc("My coordinates")}</h2>
                                        </Col>
                                        <Col className="align-self-center">
                                          <p className="counter">{coordinatesCounter} / {coordinatesMaxInputs}</p>
                                        </Col>
                                      </Row>
                                    </header>
                                    <div className="hard-shadow-white-block">

                                      <FormGroup>
                                        <Form.Label>{loc`Address`}</Form.Label>
                                        <Row noGutters>
                                          <Col>
                                            <TextField
                                              noFormGroup
                                              name="customer.address.address"
                                              hasServerErrors={hasServerErrors}
                                              initialValue={customer.address.address}
                                              trimOnBlur={true}
                                              disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["customer.address.address"].isNotEditable}
                                            />
                                          </Col>
                                          <Col className="col-auto">
                                            <OverlayTrigger
                                              key="left"
                                              placement="left"
                                              overlay={
                                                <Tooltip>{loc`Enter the address that will appear on your contract.`}</Tooltip>
                                              }
                                            >
                                              <Button className="btn-tooltip">
                                                <i className="fas fa-info-circle"></i>
                                              </Button>
                                            </OverlayTrigger>
                                          </Col>
                                        </Row>
                                      </FormGroup>
                                      <Row>
                                        <Col lg={6}>
                                          <TextField
                                              name="customer.address.additionalAddress"
                                              label={loc`Address 2`}
                                              hasServerErrors={hasServerErrors}
                                              initialValue={
                                                customer.address.additionalAddress
                                              }
                                              trimOnBlur={true}
                                              disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["customer.address.additionalAddress"].isNotEditable}
                                          />
                                        </Col>
                                        <Col lg={6}>
                                          <TextField
                                              name="customer.address.locality"
                                              label={loc`Locality`}
                                              hasServerErrors={hasServerErrors}
                                              initialValue={customer.address.locality}
                                              trimOnBlur={true}
                                              disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["customer.address.locality"].isNotEditable}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={8}>
                                          <TextField
                                              name="customer.address.city"
                                              label={loc`City`}
                                              hasServerErrors={hasServerErrors}
                                              initialValue={customer.address.city}
                                              disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["customer.address.city"].isNotEditable}
                                          />
                                        </Col>
                                        <Col lg={4}>
                                          <NumberField
                                              name="customer.address.postalCode"
                                              label={loc`Zip`}
                                              hasServerErrors={hasServerErrors}
                                              initialValue={customer.address.postalCode}
                                              trimOnBlur={true}
                                              disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["customer.address.postalCode"].isNotEditable}
                                          />
                                        </Col>
                                      </Row>
                                      <NumberFieldPhone
                                          countryCode={getCountryFromLanguage(tunnelData.data.order.orderLanguageTag)}
                                          hasServerErrors={hasServerErrors}
                                          initialValue={customer.address.mobilePhone || customer.address.phone}
                                          name="customer.address.mobilePhone"
                                          label={loc`Mobile`}
                                          disabled={isSimplifiedJourneyEnableForOrder && tunnelData?.simplifiedJourneyInputConfiguration?.["customer.address.mobilePhone"].isNotEditable}
                                      />
                                      <TextField
                                          name="customer.address.email"
                                          label={loc`Email`}
                                          hasServerErrors={hasServerErrors}
                                          initialValue={customer.email || customer.address.email}
                                          trimOnBlur={true}
                                          disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["customer.address.email"].isNotEditable}
                                      />
                                      <Row>
                                        <Col lg={8}>
                                          <TextField
                                              name="customer.bankAccountDTO.iban"
                                              label={loc`IBAN`}
                                              hasServerErrors={hasServerErrors}
                                              trimOnBlur={true}
                                              addIbanSeparator={true}
                                              initialValue={customer?.bankAccountDTO?.iban || null}
                                              disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["customer.bankAccountDTO.iban"].isNotEditable}
                                          />
                                        </Col>
                                        <Col lg={4}>
                                          <TextField
                                              name="customer.bankAccountDTO.bic"
                                              label={loc`BIC or SWIFT`}
                                              hasServerErrors={hasServerErrors}
                                              trimOnBlur={true}
                                              initialValue={customer?.bankAccountDTO?.bic || null}
                                              disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["customer.bankAccountDTO.bic"].isNotEditable}
                                          />
                                        </Col>
                                        <Col lg={8}>
                                          <DateField
                                              name="customer.yearBankSeniority"
                                              label={loc`Bank Seniority`}
                                              placeholder={loc`YYYY`}
                                              dateFormat={YEAR_FORMAT}
                                              dateFormatServer={"YYYY-MM-DD"}
                                              hasServerErrors={hasServerErrors}
                                              initialValue={customer.yearBankSeniority}
                                              disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["customer.yearBankSeniority"].isNotEditable}
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <header className={situationPartClassName}>
                                      <Row className="justify-content-between">
                                        <Col className="align-self-center">
                                          <h2>{loc("My situation")}</h2>
                                        </Col>
                                        <Col className="align-self-center">
                                          <p className="counter">{situationCounter} / {situationMaxInputs}</p>
                                        </Col>
                                      </Row>
                                    </header>
                                    <div className="hard-shadow-white-block">
                                      <Row>
                                        <Col lg={8}>
                                          <SelectField
                                              name="customer.occupationCode"
                                              label={loc`Occupation`}
                                              placeholder={loc`Select...`}
                                              options={this.getSocioProfessionalCategories()}
                                              hasServerErrors={hasServerErrors}
                                              initialValue={customer.occupationCode}
                                              disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["customer.occupationCode"].isNotEditable}
                                          />
                                        </Col>
                                        <Col lg={4}>
                                          {socioProfessionalCategoryNeedsContractDate
                                          && <div>
                                            <DateField
                                                name="customer.employmentDate"
                                                label={loc`Since...`}
                                                placeholder={loc`YYYY`}
                                                dateFormat={YEAR_FORMAT}
                                                dateFormatServer={"YYYY-MM-DD"}
                                                hasServerErrors={hasServerErrors}
                                                initialValue={customer.employmentDate}
                                                disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["customer.employmentDate"].isNotEditable}
                                            />
                                            <div style={{display: 'none'}}>
                                              <TextField
                                                  name="customer.needEmploymentDate"
                                                  initialValue={"YES"}
                                                  label={"technical"}
                                                  hasServerErrors={hasServerErrors}
                                              />
                                            </div>
                                          </div>}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col lg={8}>
                                          <SelectField
                                              name="customer.address.housingType"
                                              label={loc`Housing`}
                                              options={this.getHousingTypes()}
                                              hasServerErrors={hasServerErrors}
                                              initialValue={customer.address.housingType}
                                              disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["customer.address.housingType"].isNotEditable}
                                          />
                                        </Col>
                                        <Col lg={4}>
                                          <DateField
                                              name="customer.address.residenceDate"
                                              label={loc`Since...`}
                                              placeholder={loc`YYYY`}
                                              dateFormat={YEAR_FORMAT}
                                              dateFormatServer={loc`YYYY-MM-DD`}
                                              hasServerErrors={hasServerErrors}
                                              initialValue={customer.address.residenceDate}
                                              disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["customer.address.residenceDate"].isNotEditable}
                                          />
                                        </Col>
                                      </Row>
                                      <SelectField
                                          name="customer.maritalStatus"
                                          label={loc`Family situation`}
                                          options={this.getFamilySituations()}
                                          hasServerErrors={hasServerErrors}
                                          initialValue={customer.maritalStatus}
                                          disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["customer.maritalStatus"].isNotEditable}
                                      />
                                      {maidenNameNeedDisplay &&
                                      <FormGroup>
                                        <Form.Label>{loc`Maiden name`}</Form.Label>
                                        <Row noGutters>
                                          <Col>
                                            <TextField
                                                noFormGroup
                                                name="person.maidenName"
                                                hasServerErrors={hasServerErrors}
                                                initialValue={person.maidenName}
                                                trimOnBlur={true}
                                                disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["person.maidenName"].isNotEditable}
                                            />
                                          </Col>
                                          <Col className="col-auto">
                                            <OverlayTrigger
                                                key="left"
                                                placement="left"
                                                overlay={
                                                  <Tooltip>{loc`If you are not married, enter your name again.`}</Tooltip>
                                                }
                                            >
                                              <Button className="btn-tooltip">
                                                <i className="fas fa-info-circle"></i>
                                              </Button>
                                            </OverlayTrigger>
                                          </Col>
                                        </Row>
                                      </FormGroup>}
                                      <Row>
                                        <Col lg={6}>
                                          <TextField
                                              name="customer.dependentChildrenNumber"
                                              label={loc`Number of dependent children`}
                                              hasServerErrors={hasServerErrors}
                                              trimOnBlur={true}
                                              initialValue={customer.dependentChildrenNumber}
                                              disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["customer.dependentChildrenNumber"].isNotEditable}
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <header className={financesPartClassName}>
                                      <Row className="justify-content-between">
                                        <Col className="align-self-center">
                                          <h2>{loc("My budget")}</h2>
                                        </Col>
                                        <Col className="align-self-center">
                                          <p className="counter">{financesCounter} / {financesMaxInputs}</p>
                                        </Col>
                                      </Row>
                                    </header>
                                    <div className="hard-shadow-white-block">
                                      <FormGroup>
                                        <Form.Label>{loc`Your net monthly income`}</Form.Label>
                                        <Row>
                                          <Col xs={6}>
                                            <NumberField
                                                noFormGroup
                                                name="customer.netMonthlyIncome"
                                                placeholder="€"
                                                hasServerErrors={hasServerErrors}
                                                initialValue={customer.netMonthlyIncome}
                                                disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["customer.netMonthlyIncome"].isNotEditable}
                                            />
                                          </Col>
                                          <Col xs={6}>
                                            <SelectField
                                                noFormGroup
                                                name="customer.numberMonthsIncome"
                                                initialValue={customer.numberMonthsIncome
                                                || 12}
                                                disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["customer.numberMonthsIncome"].isNotEditable}
                                                options={[
                                                  {
                                                    value: 12,
                                                    label: loc`over 12 months`,
                                                  },
                                                  {
                                                    value: 13,
                                                    label: loc`over 13 months`,
                                                  },
                                                  {
                                                    value: 14,
                                                    label: loc`over 14 months`,
                                                  },
                                                  {
                                                    value: 15,
                                                    label: loc`over 15 months`,
                                                  },
                                                  {
                                                    value: 16,
                                                    label: loc`over 16 months`,
                                                  },
                                                ]}
                                                hasServerErrors={hasServerErrors}
                                            />
                                          </Col>
                                        </Row>
                                      </FormGroup>
                                      <FormGroup>
                                        <Form.Label>{loc`Other monthly income`}</Form.Label>
                                        <Row noGutters>
                                          <Col>
                                            <NumberField
                                                noFormGroup
                                                name="customer.otherIncome"
                                                placeholder="€"
                                                hasServerErrors={hasServerErrors}
                                                initialValue={customer.otherIncome}
                                                disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["customer.otherIncome"].isNotEditable}
                                            />
                                          </Col>
                                          <Col className="col-auto">
                                            <OverlayTrigger
                                                key="left"
                                                placement="left"
                                                overlay={
                                                  <Tooltip>{loc`Property income or alimony paid`}</Tooltip>
                                                }
                                            >
                                              <Button className="btn-tooltip">
                                                <i className="fas fa-info-circle"></i>
                                              </Button>
                                            </OverlayTrigger>
                                          </Col>
                                        </Row>
                                      </FormGroup>
                                      <FormGroup>
                                        <Form.Label>{loc`Your share of monthly charges`}</Form.Label>
                                        <Row noGutters>
                                          <Col>
                                            <NumberField
                                                noFormGroup
                                                name="customer.rent"
                                                placeholder="€"
                                                hasServerErrors={hasServerErrors}
                                                initialValue={customer.rent}
                                                disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["customer.rent"].isNotEditable}
                                            />
                                          </Col>
                                          <Col className="col-auto">
                                            <OverlayTrigger
                                                key="left"
                                                placement="left"
                                                overlay={
                                                  <Tooltip>{loc`Rent or mortgage`}</Tooltip>
                                                }
                                            >
                                              <Button className="btn-tooltip">
                                                <i className="fas fa-info-circle"></i>
                                              </Button>
                                            </OverlayTrigger>
                                          </Col>
                                        </Row>
                                      </FormGroup>
                                      <FormGroup>
                                        <Form.Label>{loc`Other monthly charges`}</Form.Label>
                                        <Row noGutters>
                                          <Col>
                                            <NumberField
                                                noFormGroup
                                                name="customer.otherCharges"
                                                placeholder="€"
                                                hasServerErrors={hasServerErrors}
                                                initialValue={customer.otherCharges}
                                                disabled={isSimplifiedJourneyEnableForOrder && this.props.tunnelData?.simplifiedJourneyInputConfiguration?.["customer.otherCharges"].isNotEditable}
                                            />
                                          </Col>
                                          <Col className="col-auto">
                                            <OverlayTrigger
                                                key="left"
                                                placement="left"
                                                overlay={
                                                  <Tooltip>{loc`Credit (excluding mortgage) and / or alimony paid`}</Tooltip>
                                                }
                                            >
                                              <Button className="btn-tooltip">
                                                <i className="fas fa-info-circle"></i>
                                              </Button>
                                            </OverlayTrigger>
                                          </Col>
                                        </Row>
                                      </FormGroup>
                                    </div>
                                  </Col>
                                </Row>
                                <Row
                                    className="justify-content-center margin-top-40">
                                  <Col lg={3} md={3} sm={12}>
                                    <Button
                                        variant="success"
                                        type="submit"
                                        className="btn-solid btn"
                                        disabled={this.state?.submitButtonLocked
                                        === true}
                                    >
                                      {loc`Validate`}
                                    </Button>
                                  </Col>
                                </Row>
                              </Container>
                            </Form>
                        )
                      }}
                  />

                </Col>
                {/* END CONTENT */}

                <Col sm={12}>
                  <Container>
                    <BreadcrumbMobile tunnelData={this.props?.tunnelData}/>
                    <Footer tunnelData={this.props?.tunnelData} infosAndClientPageDisplay={true}/>
                  </Container>
                </Col>
              </Row>
            </Col>

            {/* ————————————————————————————————————————————————— */}
            {/* ————————————————————— ASIDE ————————————————————— */}
            {/* ————————————————————————————————————————————————— */}

            <Col className="aside-block d-none d-lg-block d-xl-block" lg={4}>
              <Basket tunnelData={this.props?.tunnelData || null}/>
            </Col>
          </Row>

        </Container>
    );
  }

  chooseFormPartClassNameFromState(targetNumberInput, actualValuedInputCount) {
    if (targetNumberInput - actualValuedInputCount > 0) {
      return formPartBaseClassName;
    }
    return formPartFinishedClassName;
  };

  refreshIdentityCounterFromFormValues(values, isMaidenNameNeeded) {
    let counter = 0;
    counter = values?.person?.title && this.state.violationsConfirmed.indexOf(
        "title") < 0 ? counter + 1 : counter;
    counter = values?.person?.name && this.state.violationsConfirmed.indexOf(
        "name") < 0 ? counter + 1 : counter;
    if (isMaidenNameNeeded === true) {
      counter = values?.person?.maidenName
      && this.state.violationsConfirmed.indexOf("maidenName") < 0 ? counter + 1
          : counter;
    }
    counter = values?.person?.firstName
    && this.state.violationsConfirmed.indexOf("firstName") < 0 ? counter + 1
        : counter;
    counter = values?.person?.citizenshipCountryCode
    && this.state.violationsConfirmed.indexOf("citizenshipCountryCode") < 0
        ? counter + 1 : counter;
    counter = values?.person?.birthDate
    && this.state.violationsConfirmed.indexOf("birthDate") < 0 ? counter + 1
        : counter;
    counter = values?.person?.birthCountryCode
    && this.state.violationsConfirmed.indexOf("birthCountryCode") < 0 ? counter
        + 1 : counter;
    if (values?.person?.birthCountryCode) {
      counter = values?.person?.birthDepartmentCode
      && this.state.violationsConfirmed.indexOf("birthDepartmentCode") < 0
          ? counter + 1 : counter;
    }
    counter = values?.person?.birthCity
    && this.state.violationsConfirmed.indexOf("birthCity") < 0 ? counter + 1
        : counter;
    return counter;
  };

  refreshSituationCounterFromFormValues(values, isEmploymentDateNeeded) {
    let counter = 0;
    counter = values?.customer?.occupationCode
    && this.state.violationsConfirmed.indexOf("occupationCode") < 0 ? counter
        + 1 : counter;
    if (isEmploymentDateNeeded === true) {
      counter = values?.customer?.employmentDate
      && this.state.violationsConfirmed.indexOf("employmentDate") < 0 ? counter
          + 1 : counter;
    }
    counter = values?.customer?.address?.housingType
    && this.state.violationsConfirmed.indexOf("housingType") < 0 ? counter + 1
        : counter;
    counter = values?.customer?.address?.residenceDate
    && this.state.violationsConfirmed.indexOf("residenceDate") < 0 ? counter + 1
        : counter;
    counter = values?.customer?.maritalStatus
    && this.state.violationsConfirmed.indexOf("maritalStatus") < 0 ? counter + 1
        : counter;
    counter = values?.customer?.dependentChildrenNumber
    && this.state.violationsConfirmed.indexOf("dependentChildrenNumber") < 0
        ? counter + 1 : counter;

    return counter;
  };

  refreshFinancesCounterFromFormValues(values) {
    let counter = 0;
    // when 0 is a possible value can't use if(myVar)
    counter = (values?.customer?.netMonthlyIncome !== null && values?.customer?.netMonthlyIncome !== undefined) && this.state.violationsConfirmed.indexOf("netMonthlyIncome") < 0 ? counter + 1 : counter;
    counter = values?.customer?.numberMonthsIncome && this.state.violationsConfirmed.indexOf("numberMonthsIncome") < 0 ? counter + 1 : counter;
    counter = (values?.customer?.otherIncome !== null && values?.customer?.otherIncome !== undefined) && this.state.violationsConfirmed.indexOf("otherIncome") < 0 ? counter + 1 : counter;
    counter = (values?.customer?.rent !== null && values?.customer?.rent !== undefined) && this.state.violationsConfirmed.indexOf("rent") < 0 ? counter + 1 : counter;
    counter = (values?.customer?.otherCharges !== null && values?.customer?.otherCharges !== undefined) && this.state.violationsConfirmed.indexOf("otherCharges") < 0 ? counter + 1 : counter;
    return counter;
  };

  refreshCoordinatesCounterFromFormValues(values) {
    let counter = 0;
    counter = this.state.violationsConfirmed.indexOf("locality") < 0 ? counter
        + 1 : counter;
    counter = this.state.violationsConfirmed.indexOf("additionalAddress") < 0
        ? counter + 1 : counter;
    counter = values?.customer?.address?.address
    && this.state.violationsConfirmed.indexOf("address") < 0 ? counter + 1
        : counter;
    counter = values?.customer?.address?.city
    && this.state.violationsConfirmed.indexOf("city") < 0 ? counter + 1
        : counter;
    counter = values?.customer?.address?.postalCode
    && this.state.violationsConfirmed.indexOf("postalCode") < 0 ? counter + 1
        : counter;
    counter = values?.customer?.address?.mobilePhone
    && this.state.violationsConfirmed.indexOf("mobilePhone") < 0 ? counter + 1
        : counter;
    counter = values?.customer?.address?.email
    && this.state.violationsConfirmed.indexOf("email") < 0 ? counter + 1
        : counter;
    counter = values?.customer?.bankAccountDTO?.iban
    && this.state.violationsConfirmed.indexOf("iban") < 0 ? counter + 1
        : counter;
    counter = values?.customer?.bankAccountDTO?.bic
    && this.state.violationsConfirmed.indexOf("bic") < 0 ? counter + 1
        : counter;
    counter = values?.customer?.yearBankSeniority
    && this.state.violationsConfirmed.indexOf("yearBankSeniority") < 0 ? counter
        + 1 : counter;
    return counter;
  };

  flagInputKeysInError(validationErrors, isFirstCall) {
    let violationsConfirmed = [];
    for (const [key, value] of Object.entries(validationErrors)) {
      if (value && value.hasOwnProperty("message")) {
        if (value.message) {
          violationsConfirmed.push(key);
        }
      } else if (typeof value === "object") {
        violationsConfirmed = violationsConfirmed.concat(
            this.flagInputKeysInError(value, false));
      }
    }
    if (isFirstCall === true) {
      if (JSON.stringify(this.state?.violationsConfirmed) !== JSON.stringify(
          violationsConfirmed)) {
        this.setState({violationsConfirmed: violationsConfirmed});
      }
    }
    return violationsConfirmed;
  }

  loadCustomerDataRelyingOnComponentContext() {
    if (this.props.tunnelData != null
        && this.props.tunnelData.comeBackToClientStep === true) {
      return this.props.tunnelData.data.customer;
    }
    return this.props.tunnelData.data.order.customer;
  }

  loadPersonDataRelyingOnComponentContext() {
    if (this.props.tunnelData != null
        && this.props.tunnelData.comeBackToClientStep === true) {
      return this.props.tunnelData.data.person;
    }
    return this.props.tunnelData.data.order.customer.person;
  }

}

export default ClientPage;
