/* React Boostrap */
import React, { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

/* Components */
import Topbar from "../components/main/Topbar";
import Footer from "../components/main/Footer";

/* Utils */
import { loc } from "../core/utils";
import {initPdiComponent} from "../core/service/BusinessLogicService";

class ScoreKOPage extends Component {
  state: {};

  componentDidMount() {
    initPdiComponent("ScoreKoPage");
  }

  render() {
    return (
      <Container fluid>

        <Row noGutters>
          <Col className="misc-block">
            <Row>
              <Topbar tunnelData={this.props?.tunnelData} displayToggleButton={false}/>

              <Col sm={12}>
                {/* CONTENT */}
                <Container>
                  <Row className="justify-content-center">
                    <Col xs={11} sm={10} md={10} lg={6} className="text-center misc-mono-block">
                      <span className="ico-danger-background">
                        <i class="far fa-times"></i>
                      </span>
                      <h1>{loc`Request denied`}</h1>
                      <p>{loc`We regret to inform you that we cannot give a favorable response to your request.`}</p>
                      <p>{loc`Your order is canceled. The amount of the upfront will not be deducted from you.`}</p>
                      <Button href={this.props?.tunnelData?.shopUrl} variant="outline-primary" className="ico-back-small-before">
                        {loc`Back to shop`}
                      </Button>
                    </Col>
                  </Row>
                </Container>
                {/* END CONTENT */}
              </Col>
              <Col sm={12}>
                <Container>
                  <Footer tunnelData={this.props?.tunnelData}/>
                </Container>
              </Col>
            </Row>
          </Col>
        </Row>

      </Container>
    );
  }
}

export default ScoreKOPage;
