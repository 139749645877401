import React, { Component } from "react";
import { Field as FinalFormField } from "react-final-form";
import { Alert, Form, FormGroup, Col } from "react-bootstrap";
import { REQUIRED_MESSAGE } from "./defaultMessages";
import Select from "react-select";
import ApiService from "../core/service/ApiService";
import { createRepositoryListForSelectFromEvollisApi } from "../core/service/BusinessLogicService";
import { loc } from "../core/utils";
import TextField from "./TextField"

export default class SelectField extends Component {
  state = {};

  async componentDidMount() {
    await this.updateStateOptionsFromApiIfNeeded(this.props);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps?.otherComponentValueToBind !== this.props.otherComponentValueToBind) {
      await this.updateStateOptionsFromApiIfNeeded(this.props);
    }
  }

  async updateStateOptionsFromApiIfNeeded(props) {
    let restApiConfig = props?.restApiConfig;
    if (restApiConfig) {
      let result = await ApiService.getForEvollisApi(restApiConfig.url, restApiConfig.headers);
      result = await createRepositoryListForSelectFromEvollisApi(result, false);
      this.setState({ optionsFromExternalApi: result });
    }
  }

  render() {
    const { name } = this.props;
    let options = this.props.options;
    const {
      hidden,
      placeholder,
      label,
      className,
      xs,
      lg,
      xl,
      md,
      sm,
      xsHidden,
      smHidden,
      mdHidden,
      lgHidden,
      xlHidden,
      disabled,
      hasServerErrors,
      noFormGroup,
      initialValue,
    } = this.props;

    if (hidden) {
      return false;
    }
    return options?.length > 0 || this.state.optionsFromExternalApi?.length > 0 ? (
      <FinalFormField
        name={name}
        type="text"
        placeholder={placeholder}
        className="form-control"
        initialValue={initialValue}
      >
        {({ input, meta }) => {
          const metaError = meta.error && (meta.touched || hasServerErrors);
          const hasError = metaError || meta.submitError;
          const cl = {
            "d-none": xsHidden || smHidden || mdHidden || lgHidden || xlHidden,
            "d-sm-block": !smHidden,
            "d-sm-none": smHidden,
            "d-md-block": !mdHidden,
            "d-md-none": mdHidden,
            "d-lg-block": !lgHidden,
            "d-lg-none": lgHidden,
            "d-xl-block": !xlHidden,
            "d-xl-none": xlHidden,
            "col-no-padding": true
          };
          if (className) {
            cl[className] = true;
          }
          let selectedOptions = null;
          let selectedOption = null;
          if (this.props.restApiConfig && this.state.optionsFromExternalApi) {
            options = this.state.optionsFromExternalApi;
          }
          if (options instanceof Array) {
            selectedOptions = options.filter(key => key.value === input.value && input.value!=='');
          }
          if (selectedOptions.length !== 0) {
            selectedOption = selectedOptions[0];
          } else {
            input.onChange(null)
          }

          const hasColParam =
            xs || lg || xl || md || sm || xsHidden || smHidden || mdHidden || lgHidden || xlHidden;
          const colParams = { className: cl, xs, lg, xl, md, sm };

          let body = [
            <React.Fragment key="label">{label && <Form.Label>{label}</Form.Label>}</React.Fragment>,
            <Select
              {...input}
              key="input"
              classNamePrefix="react-select"
              className={hasError && "selectfield-error"}
              value={selectedOption}
              isDisabled={disabled}
              placeholder={loc`Select...`}
              onChange={option => {
                option ? input.onChange(option.value) : input.onChange(undefined);
              }}
              options={options}
              isClearable
            />,
            <React.Fragment key="error">
              {hasError && <p className="required-message">{meta.error || meta.submitError}</p>}
            </React.Fragment>
          ];

          if (!noFormGroup) {
            body = <FormGroup>{body}</FormGroup>;
          }

          if (hasColParam) {
            body = <Col {...colParams}>{body}</Col>;
          }

          return body;
        }}
      </FinalFormField>
    ) :
      <TextField
        name={name}
        label={label}
        hasServerErrors={hasServerErrors}
        initialValue={initialValue}
        trimOnBlur={true}
        disabled={disabled}
      />;
  }
}
