import React, {Component} from "react";
import {Route, Switch} from "react-router-dom";
import EvollisPagesLayout from "../digital-journey/EvollisPagesLayout";
import {authenticationService} from "./utils";
import {convertDateStringsToDates} from "./utils.common";
import ApiService from "./service/ApiService";
import {AccessKeysContext} from "../context/AccessKeysContext";
import {userConfigurationService} from "./utils.js";
import {
  didUserRefreshedPage,
  flagTunnelDataForPaylineNavigationCases, getClientIPAddress,
  LOCAL_STORAGE_TUNNEL_DATA_KEY,
  PAYLINE_RETURN_CODES
} from "./service/BusinessLogicService"

const axios = require("axios");
const errorCodeDetectionRegex = /[0-9]{3}/g;
const emptyString = "";
const paylineCodeUrlParamName = "?code=";
const netheosRestartUrlDetectionRegex = /\/uuid\/(.+)\/(.*)/;
const paylineRestartUrlDetectionRegex = /\/payline\/(.+)\//;


class PagesLayout extends Component {
  state = {
    tunnelData: null
  };

  isPaylineInitDetected(urlParam) {
    for (const [key, value] of Object.entries(PAYLINE_RETURN_CODES)) {
      if (urlParam.includes(value.code)) {
        return true;
      }
    }
    return false;
  }

  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    let selectedServicePack = query.get("pack");
    let selectedSku = query.get("sku");
    let tenant = query.get("tenant");
    let opl = query.get("opl");
    let paylineToken = query.get("token");
    if(!paylineToken) {
      paylineToken = query.get("paylinetoken")
    }
    let transactionId = query.get("transactionId");
    let clientIpAddress;

    let tunnelData = {};
    tunnelData.opl = opl;
    tunnelData.tenant = tenant;
    let pathName = window.location.pathname.toString();
    // PDI Journey will always be initialized by an url param (case netheos restart, click and collect START or classic START)
    // In all those case, we clean local storage to allow user to restart from shop if needed
    if (pathName.includes("/uuid") || (selectedSku && selectedServicePack && tenant) || (opl && !pathName.includes("payline"))) {
      window.localStorage.clear();
      tunnelData.clientIpAddress = await getClientIPAddress();
    }

    let hasUserRefreshPage = didUserRefreshedPage();

    const { history, incrementLoading, decrementLoading } = this.props;
    ApiService.register({ history, incrementLoading, decrementLoading });

    window.addEventListener("popstate", () => {
      console.log("PopState event triggered in PagesLayout.jsx");
      this.props.history.go(1);
    });

    tunnelData = this.handleNetheosRestartURLForNavigationCases(tunnelData);
    tunnelData = await this.handleUrlParamFromPaylineAndFlagTunnelDataForNavigationCases(tunnelData, transactionId, opl, paylineToken, tenant);

    authenticationService.userHasLoggedIn(); // previous call came back with a valid token => we are now logged in

    // Case Click and Collect simplified journey handling
    if (selectedSku && selectedServicePack && tenant ) {
      tunnelData.clickAndCollectJourney = true;
      tunnelData.selectedSku = selectedSku;
      tunnelData.selectedServicePack = selectedServicePack;
    }

    let result;
    if (!hasUserRefreshPage) {
      const tunnelName = tunnelData.tenant + "/first-init";
      const scriptUrl = "/api/script/runs/" + tunnelName;
      console.log("PagesLayout-ComponentDidMount : BEFORE calling script server tunnelData =", tunnelData);
      result = await ApiService.post(scriptUrl, { tunnelData });
      if (result && result.tunnelData) {
        tunnelData = result.tunnelData;
        console.log("PagesLayout-ComponentDidMount : AFTER calling script server tunnelData =", tunnelData);
        window.localStorage.setItem(LOCAL_STORAGE_TUNNEL_DATA_KEY, JSON.stringify(tunnelData));
      }
      else {
        this.navigateToCustomFailPage(result, tunnelData);
      }
    }
    else {
      tunnelData = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_TUNNEL_DATA_KEY));
      console.log("USER REFRESH DETECTED RETRIEVING TUNNEL DATA FROM LOCAL STORAGE ", tunnelData);
    }
    let user = this.setApplicationLangageFromCustomerIfNeeded(tunnelData);
    this.setState({ tunnelData, user });
    const newStep = this.findCurrentStepFromTunnelData(tunnelData);
    this.props.history.push(newStep.route);

    document.title = tunnelData.title || ''; 
    document.getElementById("icon").href = process.env.PUBLIC_URL+"/images/" + tunnelData.pathIcon || process.env.PUBLIC_URL+"/faicon.ico";
  
  }

  navigateToCustomFailPage(requestResult, tunnelData) {
    this.setApplicationLangageFromCustomerIfNeeded(tunnelData);
    let errorCode = parseInt(requestResult.toString().match(errorCodeDetectionRegex),10);
    let title = tunnelData?.failPageMessages?.[errorCode]?.title;
    let message = tunnelData?.failPageMessages?.[errorCode]?.message;
    this.props.history.push("/digital-journey/fail", {"title" : title, "message" : message});
  }

  setApplicationLangageFromCustomerIfNeeded(tunnelData){
    if (userConfigurationService.getUserConfiguration() === null) {
      // set locale for user (both shop and server can define it just before)
      let user = (tunnelData && { locale: tunnelData.data.order.orderLanguageTag }) || { locale: "fr-FR" };
      // this is neededby the "loc" API
      userConfigurationService.register({ user });
      return user;
    }
    return userConfigurationService.getUserConfiguration();
  }

  handleNetheosRestartURLForNavigationCases(tunnelData) {
    tunnelData.netheosRestart = false;
    let pathName = window.location.pathname.toString();
    if (pathName.includes("/uuid")) {
      console.log("netheos restart detected in PagesLayout");
      let tenantDetection = pathName.match(netheosRestartUrlDetectionRegex);
      tunnelData.netheosRestart = true;
      tunnelData.tenant = tenantDetection[1];
      tunnelData.urlUUIDToken = tenantDetection[2];
    }
    return tunnelData;
  }

  async handleUrlParamFromPaylineAndFlagTunnelDataForNavigationCases(tunnelData, transactionId, opl, token, tenant) {
    if (window.location.pathname.includes("payline")) {
      tunnelData = await flagTunnelDataForPaylineNavigationCases(tunnelData, transactionId, token);
      tunnelData.tenant = tenant;
      tunnelData.opl = opl;
    }
    return tunnelData;
  }

  correctOplUrlParam(oplUrlParam) {
    for (const [key, value] of Object.entries(PAYLINE_RETURN_CODES)) {
      if (oplUrlParam.includes(value.code)) {
        oplUrlParam = oplUrlParam.replace(paylineCodeUrlParamName + value.code, emptyString);
      }
    }
    return oplUrlParam;
  }

  removeTunnelDataError = path => {
    const { tunnelData } = this.state;
    this.setState({
      tunnelData: {
        ...tunnelData,
        errors: tunnelData.errors.filter(error => error.path !== path)
      }
    });
  };

  handleSetStep = async (data = {}) => {
    const { history } = this.props;
    const { tunnelData } = this.state;
    try {
      let newTunnelData = {
        ...tunnelData,
        data: { ...tunnelData.data, ...data }
      };

      const currentStep = this.findCurrentStepFromTunnelData(tunnelData);
      console.log("PagesLayout-HandleSetStep : RUN script " + currentStep.runUrl);
      console.log("PagesLayout-HandleSetStep : BEFORE calling script server tunnelData =", tunnelData);
      const result = await ApiService.post(currentStep.runUrl, {
        tunnelData: newTunnelData
      });
      let nextTunnelData;
      if (result && result.tunnelData) {
        console.log("PagesLayout-HandleSetStep : AFTER calling script server tunnelData =", result.tunnelData);
        nextTunnelData = result.tunnelData;
        window.localStorage.setItem(LOCAL_STORAGE_TUNNEL_DATA_KEY, JSON.stringify(nextTunnelData));
        const newStep = this.findCurrentStepFromTunnelData(nextTunnelData);
        this.setState({ tunnelData: nextTunnelData });
        history.push(newStep.route);
      }
      else {
        this.navigateToCustomFailPage(result, tunnelData)
      }
    } catch (error) {
      console.error(error);
    }
  };

  findCurrentStepFromTunnelData(tunnelData) {
    const stepId = tunnelData.step;
    const stepIndex = tunnelData.breadcrumbs.findIndex(step => step.id === stepId);
    const currentStep = tunnelData.breadcrumbs[stepIndex];
    if (currentStep) {
      return currentStep;
    }
    throw "Error : no step with id " + stepId + " has been found in tunnelData";
  }

  render() {
    const { tunnelData } = this.state;

    let componentProps = {
      ...this.props,
      ...this.state,
      onSetStep: this.handleSetStep,
      tunnelData: tunnelData,
      removeTunnelDataError: this.removeTunnelDataError
    };
    let i = 1;
    if (!tunnelData) {
      return <div></div>;
    }
    else {
      return (
          <AccessKeysContext.Provider value={tunnelData ? tunnelData.accessKeys : {}}>
            <Switch>
              <Route
                  path="/digital-journey"
                  render={routeProps => <EvollisPagesLayout {...routeProps} {...componentProps} />}
                  key={i++}
              />
            </Switch>
          </AccessKeysContext.Provider>
      );
    }
  }
}

export default PagesLayout;
