import ApiService from "./ApiService";
import moment from "moment";
import PublicIp from 'public-ip'
import Axios from "axios";

const insuranceServiceTypes = ["ASSURANCE_CASSE", "ASSURANCE_CASSE_ET_VOL"];

/** ============================ GLOBALS COMPONENT FUNCTIONNALITIES ============================*/

/** Common Component initialization pre-required method. Actually this method allows to :
 * 1- Store last component url path visited (so we can detect user refresh event and not call script server in this case)
 * 2- Log that user Navigated to a new page for exploitation purposes.
 * WARNING : call this method in EVERY componentDidMount method when you create a new "main" Component
 * NOTE : a "main" component is a component that you can found in EvollisPageLayout.jsx and where user can be routed to.
 * For example : CbPage.jsx, IdentificationPage.jsx and OnBoardingPage.jsx are "main" components but Basket.js is not (he's only a secondary component
 * because there is not route to it, he's a slave component made for being displayed into another).*/
export function initPdiComponent(componentName) {
  if (!didUserRefreshedPage()) {
    logUserNavigateToNewPageEvent(componentName);
  }
  updateLocalStorageLastPathNameVisited();
}

export function didUserRefreshedPage(){
  return window.location.pathname === window.localStorage.getItem(LOCAL_STORAGE_LAST_PATHNAME_VISITED_KEY);
}

/** ============================ LOCAL STORAGE PART ============================*/
export const LOCAL_STORAGE_TUNNEL_DATA_KEY = "tunnelData";
export const LOCAL_STORAGE_LAST_PATHNAME_VISITED_KEY = "lastPathName";
export const LOCAL_STORAGE_CLICK_AND_COLLECT_TEMPORARY_CUSTOMER_KEY = "clickAndCollectCustomer";
export const LOCAL_STORAGE_CLICK_AND_COLLECT_TEMPORARY_PERSON_KEY = "clickAndCollectPerson";

/** Store the current pathname to localstorage. Useful to handle user refresh. */
export function updateLocalStorageLastPathNameVisited() {
  window.localStorage.setItem(LOCAL_STORAGE_LAST_PATHNAME_VISITED_KEY, window.location.pathname);
}

/** TRACKING (IP, click, navigation)*/
/** Call the cloudflare API to compute the client ip address. */
export async function getClientIPAddress(){
  try {
    return PublicIp.v4();
  }
  catch (exception) {
    console.log("Exception happened while fetching IP ADDRESS", exception);
    return null;
  }
}

/** ============================LOG UTILS ============================*/
export const LOG_SERVICE_URL_PREFIX = "https://elasticsearch.evollis.com/logstash-docker-";
export const LOG_SERVICE_URL_SUFFIX = "/_doc";
export const basicAuthEncoded = "ZWxhc3RpYzpqWEozek15UTVIN1hzWG9UVHhvcA==";

const preproductionEnvironments = [{name : "sit", searchString : "sit"}, {name : "uat", searchString : "uat"}];
const productionEnvironnement = {name : "production"};

/** Method used to log client side navigation events.*/
export function logUserNavigateToNewPageEvent(componentName) {
  let opl = getOplFromLocalStorage();
  if (opl) {
    let message = "OPL " + opl + " landed in page " + componentName;
    let logLevel = "INFO";
    sendToLogServer(logLevel, message)
  }
}

function getOplFromLocalStorage(){
  let tunnelData = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_TUNNEL_DATA_KEY));
  if (tunnelData && tunnelData.opl) return tunnelData.opl;
}

export function sendToLogServer(logLevel, message) {
  let logTags = computeLogTagFromHref();
  if (logTags) {
    let data = JSON.stringify({
      "log_level": logLevel,
      "message": message,
      "@timestamp": new Date().toISOString(),
      "tag":logTags
    });
    let xhr = new XMLHttpRequest();
    xhr.addEventListener("readystatechange", function() {
      if(this.readyState === 4 && this.status === 201) {
        console.log("Successfully loggued "+ message + " to server with log level " + logLevel + "and tags " + logTags);
      }
    });
    xhr.open("POST", computeLogServiceUrl());
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Basic " + basicAuthEncoded);
    xhr.setRequestHeader("Accept", "*/*");
    xhr.send(data);
  }
}

/** Formats date from "yyyy-mm-dd" to "dd/mm/yyyy" */
export function formatBirthDate(birthDate) {
  return birthDate.substring(8,10) + "/" + birthDate.substring(5,7) + "/" + birthDate.substring(0,4);
}

export function changeToCapitalLetter(string) {
  return string[0].toUpperCase() + string.substring(1);
}

function computeLogServiceUrl() {
  const date = new Date(Date.now());
  const dateMonth = (date.getMonth()+1).toString().length === 1 ? "0" + (parseInt(date.getMonth())+1) : parseInt(date.getMonth())+1;
  const YyyyMmDdTodaysDate = date.getFullYear() + "." + dateMonth + "." + date.getDate();
  return LOG_SERVICE_URL_PREFIX + YyyyMmDdTodaysDate + LOG_SERVICE_URL_SUFFIX;
}

/** Compute log tags for kibana tool. */
function computeLogTagFromHref() {
  let currentLocation = window.location.href;
  if (currentLocation.includes("localhost") === true) {
    return undefined;
  }

  let environmentName;
  preproductionEnvironments.forEach((environment) => {
    if (currentLocation.includes(environment.searchString) === true) {
      environmentName = environment.name;
    }
  });

  if (!environmentName) {
    environmentName = productionEnvironnement.name;
  }
  return "voyager-bis,pfloc,"+ environmentName;
}

/** ============================ PAYLINE UTILITY PARTS ============================ */
export const PAYLINE_RETURN_CODES = {
  CODE_APPROVED: {
    code: "CODE_APPROVED",
    paylineError: false,
    eliminatoryPaylineError: false,
  },

  CODE_UNKNOWN: {
    code: "CODE_UNKNOWN",
    paylineError: true,
    eliminatoryPaylineError: false,
  },

  REFUSED_CODE_AUTHENTIFICATION_FAIL: {
    code: "REFUSED_CODE_AUTHENTIFICATION_FAIL",
    paylineError: true,
    eliminatoryPaylineError: true,
  },

  REFUSED_CODE_EXPIRED_CREDIT_CARD: {
    code: "REFUSED_CODE_EXPIRED_CREDIT_CARD",
    paylineError: true,
    eliminatoryPaylineError: false,
  },

  REFUSED_CODE_INVALID_CREDIT_CARD_NUMBER: {
    code: "REFUSED_CODE_INVALID_CREDIT_CARD_NUMBER",
    paylineError: true,
    eliminatoryPaylineError: false,
  },

  REFUSED_CODE_CONNECTION_TIME_OUT: {
    code: "REFUSED_CODE_CONNECTION_TIME_OUT",
    paylineError: true,
    eliminatoryPaylineError: false,
  },

  REFUSED_CODE_CARD_LOST: {
    code: "REFUSED_CODE_CARD_LOST",
    paylineError: true,
    eliminatoryPaylineError: false,
  },

  REFUSED_CODE_EXPIRED_CREDIT_CARD_BIS: {
    code: "REFUSED_CODE_EXPIRED_CREDIT_CARD_BIS",
    paylineError: true,
    eliminatoryPaylineError: false,
  },

  REFUSED_CODE_INSUFFICIENT_CASH: {
    code: "REFUSED_CODE_INSUFFICIENT_CASH",
    paylineError: true,
    eliminatoryPaylineError: false,
  },

  REFUSED_CODE_MAXIMUM_DEBIT_ACHIEVED: {
    code: "REFUSED_CODE_MAXIMUM_DEBIT_ACHIEVED",
    paylineError: true,
    eliminatoryPaylineError: false,
  },

  REFUSED_CODE_MAXIMUM_DEBIT_ACHIEVED: {
    code: "REFUSED_CODE_MAXIMUM_DEBIT_ACHIEVED",
    paylineError: true,
    eliminatoryPaylineError: false,
  },

  REFUSED_CODE_MAXIMUM_DEBIT_ACHIEVED: {
    code: "REFUSED_CODE_MAXIMUM_DEBIT_ACHIEVED",
    paylineError: true,
    eliminatoryPaylineError: false,
  },
};

export async function flagTunnelDataForPaylineNavigationCases(tunnelData, transactionId, paylineToken) {
  let apiUrl = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_TUNNEL_DATA_KEY)).apiUrl;
  let access_token = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_TUNNEL_DATA_KEY)).access_token;
  let payload = {
    "transactionId": transactionId,
    "paymentTarget": "FIRST_CUSTOMER_PAYMENT",
    "cardOperationType": "AUTHORIZATION",
    "pspContextEnum": "PROVISION",
    "paylineToken": paylineToken
  }
  const headers = {
    Authorization: `Bearer ${access_token}`,
    Accept: "*/*",
  };

  let paylinePaymentInfo = await Axios.post(apiUrl + "middle-bank-service/api/v1/transactions/sourceId/" + transactionId + "/payment/authorization/FIRST_CUSTOMER_PAYMENT", payload, { headers: headers })

  tunnelData.paylineError = PAYLINE_RETURN_CODES[paylinePaymentInfo.data.code].paylineError;
  tunnelData.eliminatoryPaylineError = PAYLINE_RETURN_CODES[paylinePaymentInfo.data.code].eliminatoryPaylineError;

  return tunnelData;
}

/** ============================ PROMOTION MANAGEMENT ============================ */

export const DISCOUNT_PROVISION_REDUCTION_AMOUNT = "DISCOUNT_PROVISION_REDUCTION_AMOUNT";
export const DISCOUNT_PROVISION_FIXED_AMOUNT = "DISCOUNT_PROVISION_FIXED_AMOUNT";
export const FREE_RENT = "FREE_RENT";
export const PROMOTION_VALUE_TYPE = "VALUE";
export const PROMOTION_PERCENT_TYPE = "PERCENT";

export function isPromoAssociatedToOrder(order) {
  return order?.promotionDTOs.length > 0;
}

export function getPromotionType(promotion) {
  return promotion.promotionRuleDTOs[0]?.type;
}

export function getPromotionCode(promotion) {
  return promotion.code;
}

export function getPromotionValue(promotion) {
  return promotion.promotionRuleDTOs[0]?.value;
}

export function getPromotionValueType(promotion) {
  return promotion.promotionRuleDTOs[0]?.valueType;
}

export function getPromotionDescription(promotion) {
  return promotion.description;
}

/** RENT AND SERVICE PACK PART */

export function extractServicePackDocumentLinkFromServicePackDescription(description){
  return (description.match(/.*(https.*.pdf).*/)[1]);
}

export function computeServicePackPriceFromRent(tunnelData) {
  let country = getCountryFromLanguage(tunnelData.data.order.orderLanguageTag);
  if (['FR','IT'].includes(country)) {
    return tunnelData?.data?.order?.rent.monthlyFinancedServicesAmount;
  } else {
    return tunnelData?.data?.order?.rent?.monthlyFinancedServicesAmount + tunnelData?.data?.order?.rent?.monthlyUnfinancedServicesAmount;
  }
}

export function getNotFineancableInsurances(order) {
  let result = [];
  if (order && order.selectedServicePack) {
    order.selectedServicePack.services.map((service) => {
      if (insuranceServiceTypes.indexOf(service.serviceType) > 0
          && service.financeable === false) {
        result.push(service);
      }
    });
  }
  return result;
}

export async function getServicePackPossibilities(apiUrl, accesToken, offerId,
    sku) {
  const postForServicePackPossibilitesURL = apiUrl
      + "api/v2/leases/lease-rent-calculation";
  const requestBody = {
    "offerId": offerId,
    "simulationDate": moment().format("YYYY-MM-DD"),
    "productSku": sku
  };
  const headers = {
    Authorization: `Bearer ${accesToken}`,
    Accept: "*/*",
  };
  let servicePackAndRentPossibilitiesByDuration = await ApiService.postForEvollisApi(
      postForServicePackPossibilitesURL, requestBody, {headers});
  let servicePackAndRentPossibilities = [];
  for (const [duration, servicePackPossibilityArray] of
      Object.entries(servicePackAndRentPossibilitiesByDuration)) {
    servicePackPossibilityArray.map(servicePackAndRent => {
      servicePackAndRentPossibilities.push(servicePackAndRent);
    });
  }
  // Order the service packs by monthlyPrice
  servicePackAndRentPossibilities.sort(
      (firstServicePackAndRent, secondServicePackAndRent) => {
        let isServicePackCheaper = firstServicePackAndRent.rentDTO?.monthlyFinancedServicesAmount
            < secondServicePackAndRent.rentDTO?.monthlyFinancedServicesAmount;
        if (isServicePackCheaper === true) {
          return -1;
        } else if (isServicePackCheaper === false) {
          return 1;
        } else {
          return 0;
        }
      })
  return servicePackAndRentPossibilities;
}

export function extractAllServicePossibilities(servicePackPossibilities) {
  let servicesReturned = [];
  if (servicePackPossibilities) {
    servicePackPossibilities.map(servicePackPossibility => {
      servicePackPossibility.servicePackDTO.services.map(service => {
        if (servicesReturned.indexOf(service) < 0) {
          servicesReturned.push(service);
        }
      });
    });
  }
  return servicesReturned;
}

/** ============================ REPOSITORY PART ============================*/

export function retrieveRepositoryLabelFromCode(repositoryList, code) {
  console.log("retrieveRepositoryLabelFromCode with params", code,
      repositoryList);
  if (code && repositoryList) {
    for (const [key, value] of Object.entries(repositoryList)) {
      if (key === code) {
        return value;
      }
    }
  }
}

export function createRepositoryListForRadiosFromEvollisApi(apiRequestResult) {
  let result = [];
  if (apiRequestResult) {
    for (const [key, value] of Object.entries(apiRequestResult)) {
      result.push({"name": key, "label": value});
    }
  }
  return result;
}

export function createRepositoryListForSelectFromEvollisApi(apiRequestResult,
    concatKeyAndValueInLabel) {
  let result = [];
  let labelAttributValue;
  if (apiRequestResult) {
    for (const [key, value] of Object.entries(apiRequestResult)) {
      if (concatKeyAndValueInLabel === true) {
        labelAttributValue = key + REPOSITORY_SEPARATOR + value;
      } else {
        labelAttributValue = value;
      }
      result.push({"value": key, "label": labelAttributValue});
      result.sort(function (reference, otherReference) {
        if (reference.label < otherReference.label) {
          return -1;
        }
        if (reference.label > otherReference.label) {
          return 1;
        }
        return 0;
      });
    }
  }
  return result;
}

export function retrieveRepositoryObjectFromCode(repositoryList, code) {
  if (code && repositoryList) {
    for (const [key, value] of Object.entries(repositoryList)) {
      if (key === code) {
        return {"value": key, "label": value};
      }
    }
  }
}

/** Socioprofessional category handling */
export function isASocioProfessionalCategoryWithoutContract(socioProfessionalRepositoryList,
    socioProfessionalCategoryCode) {
  if (socioProfessionalCategoryCode) {
    if (socioProfessionalCategoryCode && socioProfessionalRepositoryList) {
      for (const [key, value] of
          Object.entries(socioProfessionalRepositoryList)) {
        if (socioProfessionalCategoryCode === key) {
          return true;
        }
      }
    }
  }
  return false;
}

/** Country repository handling */
export const FRANCE_COUNTRY_CODE = "F";
// used to concat repositories values for client side displaying or for ids and zipcodes concat in cities lists keys
export const REPOSITORY_SEPARATOR = " - ";

/** Person title handling */

export const misterEnumName = "MR";
export const misterEnumCode = "1";

export const mademoiselleEnumName = "MS";
export const mademoiselleEnumCode = "3";

export const missEnumName = "MRS";
export const missEnumCode = "2";

export const getCountryFromLanguage = (language) => {
  return language.split('-')[1];
}

export async function getCivilStatuses(tunnelData, returnAsArray) {
  let countryCode = getCountryFromLanguage(tunnelData.data.order.orderLanguageTag);
  const getCivilStatusesURL = tunnelData?.apiUrl
      + "middle-business-service/api/v2/repository/civil-statuses?countryCode="+countryCode;
  const token = tunnelData?.access_token;
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "*/*",
  };
  let civilStatusesFromEvollisApi = await ApiService.getForEvollisApi(
      getCivilStatusesURL, headers);
  // Remove Miss from civility enum cuz we can't delete it from DB (other parcours use it at the moment);
  delete civilStatusesFromEvollisApi[mademoiselleEnumCode];
  if (returnAsArray === true) {
    return createRepositoryListForRadiosFromEvollisApi(
        civilStatusesFromEvollisApi);
  } else {
    return civilStatusesFromEvollisApi;
  }
}

export function convertPersonTitleCodeToEnumName(valueToConvert) {
  if (valueToConvert === misterEnumCode) {
    return misterEnumName;
  }
  if (valueToConvert === mademoiselleEnumCode) {
    return mademoiselleEnumName;
  }
  if (valueToConvert === missEnumCode) {
    return missEnumName;
  }
  return null;
}

export function convertTitleEnumNameToCode(valueToConvert) {
  if (valueToConvert === misterEnumName) {
    return misterEnumCode;
  }
  if (valueToConvert === mademoiselleEnumName) {
    return mademoiselleEnumCode;
  }
  if (valueToConvert === missEnumName) {
    return missEnumCode;
  }
  return null;
}

/** OPTIN AND LEGALS HANDLING */

const mailDetectionRegex = /\S+@\S+\.\S+/g;
const linkDetectionRegex = /www\.\S+\.\S+/g;

/** This method is currently used to extract links and mails from parametrized values of TunnelData (legal texts and optins). */
export function replaceMailAndLinkWithProperHtmlTemplate(stringToTemplate) {
  if (stringToTemplate) {
    stringToTemplate = replaceMailWithProperHtmlTemplate(stringToTemplate);
    return replaceLinkWithProperHtmlTemplate(stringToTemplate);
  }
  return stringToTemplate;
}

/** This method detect a mail in a string and inject an html tag around. Made for inject into a div "innerHtml" property.
 * We require a string like "[anyValue]@[anyvalue].[anyValue] */
function replaceMailWithProperHtmlTemplate(stringToTemplate) {
  let matchDetection = stringToTemplate.match(mailDetectionRegex);
  if (matchDetection) {
    for (let mailDetected of matchDetection) {
      stringToTemplate = stringToTemplate.replace(mailDetected, "<a href=\"mailto:" + mailDetected + "\">" + mailDetected + "</a>");
    }
  }
  return stringToTemplate;
}

/** This method detect a link in a string and inject an html tag around. Made for inject into a div "innerHtml" property.
 * We require a string like "www.[anyValue].[anyvalue]" (don't include "https://") */
function replaceLinkWithProperHtmlTemplate(stringToTemplate) {
  let matchDetection = stringToTemplate.match(linkDetectionRegex);
  if (matchDetection) {
    for (let linkDetected of matchDetection) {
      stringToTemplate = stringToTemplate.replace(linkDetected,
          "<a target=\"_blank\" href=\"https://" + linkDetected + "\">" + linkDetected
          + "</a>");
    }
  }
  return stringToTemplate;
}