import React, {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {loc} from "../../core/utils";
import Breadcrumb from "../../components/main/Breadcrumb";

class Header extends Component {
  render() {
    const {tunnelData} = this.props;
    let title;
    let description;
    if (tunnelData) {
      tunnelData.breadcrumbs.forEach(step => {
        if (step.id === tunnelData?.step) {
          description = step.title;
        }
      });
      tunnelData.breadcrumbs.forEach(step => {
        if (step.id === tunnelData?.step) {
          title = "" + tunnelData.breadcrumbs.indexOf(step);
        }
      });
    }

    return (
        <Col sm={12} className="header-container">
          <Container>
            <header className="header-main">
              <Row className="align-content-center">
                <Col>
                  <h1>
                    <span className="title-step">{loc(title)}</span>
                    <span className="title-name">{loc(description)}</span>
                  </h1>
                </Col>
                <Breadcrumb tunnelData={this.props?.tunnelData}/>
              </Row>
            </header>
          </Container>
        </Col>
    )
  }
}

export default Header;