import {loc} from "./utils";

export var fr_FR = {
  /* ========================================== */
  /* COMMON =================================== */
  /* ========================================== */
  "Back to shop": "Retour au shop",
  "Validate": "Valider",
  "Select...": "Sélectionner...",
  "Choose":"Choisir",
  "Start":"Commencer",
  /* ========================================== */
  /* BREADCRUMB =============================== */
  /* ========================================== */
  "Services":"Services",
  "Informations":"Informations",
  "My informations":"Mes informations",
  "Summary":"Récapitulatif",
  "My summary":"Mon récapitulatif",
  "Contracts":"Contrats",
  "Back to basket":"Retour au panier",
  /* ========================================== */
  /* BASKET =================================== */
  /* ========================================== */
  "Basket":"Panier",
  "Promo code": "Code promo",
  "on": "sur",
  "months": "mois",
  "Offered": "Offerts",
  "Shipping fees":"Frais de livraison",
  "Upfront":"1er versement",
  "My upfront" : "Mon 1er versement",
  "Rent":"Loyer",
  "/month":"/mois",
  "YYYY":"AAAA",
  "YYYY/MM/DD":"JJ/MM/AAAA",
  "including services pack at":"dont pack de services à",
  "Supporting documents requested":"Eléments demandés",
  "A bank card in your name": "Une carte bancaire à votre nom",
  "Your mobile phone": "Votre téléphone mobile",
  "A bank identity statement in your name":"Un RIB à votre nom",
  "My RIB":"Mon RIB",
  "A valid ID in your name":"Une pièce d’identité valide à votre nom",
  "My ID" : "Ma pièce d’identité",
  "My valid ID" : "Ma pièce d’identité valide",
  "Proof of address of less than 3 months":"Un justificatif de domicile ≤ 3 mois",
  "Proof of address of less than 6 months":"Un justificatif de - de 6 mois",
  "My proof of address":"Mon justificatif de domicile",
  /* ========================================== */
  /* ONBOARDING PAGE ========================== */
  /* ========================================== */
  "My identification" : "Mon identification",
  "Only if your bancary document's address is different than the one you will enter" : "Uniquement si l’adresse sur mon RIB diffère de mon adresse actuelle",
  "Only if my RIB's address is different from my current address" : "Uniquement si l'adresse de votre RIB diffère de votre adresse actuelle",
  "Enter the informations that will allow us to identify you." : "Entrez les informations nous permettant de vous identifier.",
  "Start your location online then meet us in our sellpoint." : "Démarrez votre demande de souscription en ligne puis rendez-vous en magasin.",
  "by entering my personnal informations" : "en saisissant mes informations personnelles",
  "Want to rent my product ? No problem ! It only takes a few minutes to subscribe online" : "Je souhaite louer mon produit ? Rien de plus simple ! Je fais ma demande en ligne en quelques minutes.",
  "Then i finalize my order in shop under 5 days, bringing with me those 3 proofs" : "Puis je finalise en magasin dans les 5 jours en me munissant impérativement des 3 justificatifs suivants :",
  "Here are the steps to follow to make your rental request": "Voici les étapes à suivre pour faire votre demande de location",
  "The online subscription procedure is fully dematerialized.": "La procédure de souscription en ligne est entièrement dématérialisée.",
  "The signing of your contract and the downloading of your supporting documents will be done by electronic way.": "La signature de votre contrat et l'envoi de vos pièces justificatives se feront de manière électronique.",
  "Select your services and customize the options of your rental contract.": "Sélectionnez vos services et personnalisez les options de votre contrat de location.",
  "Pay by credit card the 1st installment of your rental contract.": "Payez par carte bancaire le 1er versement de votre contrat de location.",
  "through bank card to reserve my product" : "par carte bancaire pour réserver mon produit",
  "Check and complete your personal information which will appear on your contract.": "Vérifiez et complétez vos informations personnelles qui figureront sur votre contrat.",
  "which will appear on my location's contract": "qui figureront sur mon contrat de location",
  "Validate your personal information and your rental request.": "Validez vos informations personnelles et votre demande de location.",
  "which allow me to validate my informations and my rent request": "qui me permet de valider mes informations et ma demande de location",
  "Download your supporting documents and sign your dematerialized contract.": "Téléchargez vos justificatifs et signez votre contrat dématérialisé.",
  "Obtain acceptance in principle then finalize your order in the store of your choice.":"Obtenez une acceptation de principe puis finalisez votre commande en magasin.",
  "I get a principle acceptation to rent my product":"J'obtiens une acceptation de principe pour la location de mon produit",
  "To complete your subscription request, have the following information ready:": "Pour réaliser votre demande de souscription, munissez-vous des éléments suivants :",
  "Only if the address of your RIB differs from your current address":"Uniquement si l’adresse de votre RIB diffère de votre adresse actuelle",
  /* ========================================== */
  /* SERVICES PAGE ============================ */
  /* ========================================== */
  "Gain peace of mind by protecting your product throughout the rental period.":"Gagnez en sérénité en protégeant votre produit durant toute la durée de la location.",
  "Read the insurance notice":"Lire la notice d’assurance",
  "Full warranty":"Garantie complète",
  "Upgrade":"Renouvellement",
  "Dedicated customer service":"Service client dédié",
  "Accidental breakage":"Casse accidentelle",
  "Accidental oxidation":"Oxydation accidentelle",
  "Accidental action":"Action accidentelle",
  "Global coverage":"Couverture mondiale",
  "No deductible applied":"Aucune franchise appliquée",
  "14 days withdrawal period":"Délai de rétractation de 14j",
  "Telephone assistance 6 days a week":"Assistance téléphonique 6j/7",
  "Pickpocketing":"Vol à la tire et à la sauvette",
  "Theft by assault or break-in":"Vol par agression ou effraction",
  "Fraudulent use":"Utilisation frauduleuse",
  "None":"Aucun",
  "Breakage": "Casse",
  "Breakage and theft": "Casse et vol",
  "or a rent of ":"soit un loyer de ",
  "Cap":"Plafond",
  "2 claims maximum per insurance year. Ceiling 1500 € per insurance year.":"2 sinistres maximum par année d'assurance. Plafond 1500 € par année d'assurance.",
  "Main exclusions":"Principales exclusions",
  "The accessories, and connectors of the device. Normal wear and tear of the device. Negligence, forgetting the device. Theft from the primary and secondary residence.":"Les accessoires, et connecteurs de l’appareil. L'usure normale de l’appareil. La négligence, oubli de l’appareil. Le vol dans la résidence principale et secondaire.",
  "I acknowledge having read and accept the general conditions of the long-term rental contract offer and the service contract.":"Je reconnais avoir pris connaissance et accepte les conditions générales de l’offre de contrat de location longue durée et du contrat de services.",
  "I request the start of the execution of my long-term rental contract with the lessor and my service contract before the expiration of the 14-day withdrawal period.":"Je demande le commencement de l’exécution de mon contrat de location longue durée avec le loueur et de mon contrat de services avant l’expiration du délai de rétractation de 14 jours.",
  "I confirm that I do not choose optional insurance and assume the risk of breakage or theft during my long-term rental contract.":"Je confirme ne pas choisir l’assurance facultative et assume les risques de casse ou vol au cours de mon contrat de Location longue durée.",
  "I confirm my subscription to breakage insurance and acknowledge having read the insurance information notice.":"Je confirme mon adhésion à l’assurance casse et reconnais avoir pris connaissance de la notice d’information assurance.",
  "By checking this box, I authorize Cardif Assurances Risques Divers to use my email address for the execution of my contract. However, I recognize that certain documents must be sent to you in the original and that you will inform me if the case arises. I note that this data (email address) will not be used for commercial solicitation purposes, unless I have expressly consented otherwise. I have the right to oppose exchanges by e-mail at any time by sending my request to data.protection@cardif.com or to CARDIF Assurances Risques Divers - Claims quality department - Prévoyance SH 123- 8 rue du Port - 92 728 Nanterre Cedex.":"En cochant cette case, j’autorise Cardif Assurances Risques Divers à utiliser mon adresse électronique dans le cadre de l’exécution de mon contrat. Toutefois, je reconnais que certains documents doivent vous être obligatoirement envoyés en original et que vous m’en informerez si le cas se présente. Je prends acte que ces données (adresse électronique) ne seront pas utilisées à des fins de sollicitation commerciale, sauf si j’y ai expressément consenti par ailleurs. Je dispose de la faculté de m’opposer à tout moment aux échanges par courrier électronique en adressant ma demande à data.protection@cardif.com ou à CARDIF Assurances Risques Divers - Service qualité réclamations - Prévoyance SH 123- 8 rue du Port - 92 728 Nanterre Cedex.",
  "Up2You's breakage and thief protection is a collective damage insurance (number 674) contract subscribed by Samsung Electronics France, society with a capital of 27 000 000 euros – 334 367 497 RCS Bobigny – Siège social 1, rue fructidor 93484 Saint-Ouen auprès de CARDIF - Assurances Risques Divers - S.A. (society with a capital of 16 875 840 euros) 308 896 547 RCS Paris - Siège social : 1, Boulevard Haussmann 75009 Paris - Entreprise ruled by the insurance code, here called \"CARDIF\"" : "«Protection casse et vol SAMSUNG Up2you location longue durée» est un contrat d’assurance collective de dommages n°674 souscrit par Samsung Electronics France, SAS au capital 27 000 000 euros – 334 367 497 RCS Bobigny – Siège social 1, rue fructidor 93484 Saint-Ouen auprès de CARDIF - Assurances Risques Divers - S.A. au capital de 16 875 840 € - 308 896 547 RCS Paris - Siège social : 1, Boulevard Haussmann 75009 Paris - Entreprise régie par le Code des assurances, ci-après dénommée « CARDIF ».",
  "Up2YOu Location's breakage and thief insurance is distributed as an accessory relatively to it main commercial activity by Samsung Electronics France by the way of Garantie-privée SAS, insurance society with a capital of 60 000 € - 521 279 737 RCS Bordeaux, - Siège social : 7-9 allée Haussmann 33300 Bordeaux, registered to l’ORIAS ( www.orias.fr ) under the reference 100 557 38." : "«Protection casse et vol SAMSUNG Up2you location longue durée» est distribué à titre accessoire à son activité commerciale principale par Samsung Electronics France par l’intermédiaire de Garantie-Privée SAS, Société de Courtage en assurances au capital de 60 000 € - 521 279 737 RCS Bordeaux, - Siège social : 7-9 allée Haussmann 33300 Bordeaux, immatriculée à l’ORIAS ( www.orias.fr ) sous le numéro 100 557 38 en sa qualité de Courtier.",
  "Up2You location breakage and thief contract' sinister handling is the responsability of Grantie-Privée, insurance broker" : "La gestion des sinistres du contrat «Protection casse et vol SAMSUNG Up2you location longue durée» est déléguée à la société Garantie-Privée, en qualité de courtier gestionnaire.",
  /* ========================================== */
  /* UPFRONT PAGE ============================= */
  /* ========================================== */
  "first installment of your rental contract": "1er versement de votre contrat de location",
  "Cards accepted": "Cartes acceptées",
  "Cards not accepted": "Cartes non-acceptées",
  "Systematic authorization cards (Electron, Maestro, pre-paid or rechargeable), American Express and e-CB.": "Cartes à autorisation systématique (Electron, Maestro, pré-payées ou rechargeables), American Express et e-CB.",
  "This step only constitutes a request for authorization of payment.":"Cette étape ne constitue qu’une demande d’autorisation de paiement.",
  " The 1st payment of ":" Le 1er versement de ",
  "by credit card will only be effective after signing your rental contract and downloading your supporting documents.If your rental request is subsequently refused by the Lessor following its study, then this amount will be immediately refunded.":" par carte bancaire ne sera effectif qu’après le téléchargement de vos justificatifs et qu’après signature de votre Contrat de location. Si votre demande de location est par la suite refusée par le loueur suite à son étude, alors ce montant sera immédiatement remboursé.",
  "Cards not accepted":"Cartes non-acceptées",
  "Systematically authorized cards (such as Electron, Maestro, prepaid, rechargeable), e-CB and American Express." : "Cartes à autorisation systématique (de type Electron, Maestro, prépayées, rechargeables), e-CB et American Express.",
  "Upfront amount of your leasing contract": "Montant du 1er versement de votre contrat de location",
  "Card number": "Numéro de carte",
  "MM":"MM",
  "CB":"CB",
  "Visa":"Visa",
  "Mastercard":"Mastercard",
  "Your credit card is expired":"Votre carte est expirée",
  "Expiration date":"Date d'expiration",
  "CVV":"CVV",
  "I, undersigned $ certify that I am the holder of the bank card used for the request for payment of my 1st payment.": ["Je soussigné(e) ", " certifie être le titulaire de la carte bancaire utilisée pour la demande d’autorisation de mon premier versement."],
  "Secured payment":"Paiement sécurisé",
  "validate the payment authorization":"Valider l'autorisation de paiement",
  "Your card has been declined. Please check the accuracy of the information entered. You can also contact your bank to verify that it is not blocking payment.":"Votre carte a été refusée. Merci de vérifier l'exactitude des informations saisies. Vous pouvez également contacter votre banque pour vérifier qu'elle ne bloque pas le paiement.",
  /* ========================================== */
  /* CLIENT DATA PAGE ========================= */
  /* ========================================== */
  "Bank Seniority" : "Année d'entrée dans votre banque actuelle",
  "My identity" : "Mon identité",
  "My coordinates" : "Mes coordonnées",
  "My situation" : "Ma situation",
  "My budget" : "Mon budget",
  "The information below is necessary for our renter for the constitution of your rental file.":"Les informations ci-dessous sont nécessaires à notre loueur pour la constitution de votre dossier de location.",
  "To meet our eligibility conditions, you must have regular activity income and not have any current payment incidents.":"Pour remplir nos conditions d’éligibilité, vous devez bénéficier d’un revenu d’activité régulier et ne pas avoir d’incidents de paiement en cours.",
  "Identity": "Identité",
  "Title": "Civilité",
  "Mr": "M.",
  "Ms": "Mme",
  "Last name":"Nom",
  "Maiden name":"Nom de naissance",
  "If you are not married, enter your name again.":"Si vous n'êtes pas mariée, saisissez à nouveau votre nom.",
  "First name":"Prénom",
  "Address":"Adresse",
  "Enter the address that will appear on your contract.":"Saisissez l'adresse qui figurera sur votre contrat.",
  "Address 2":"Complément d'adresse (optionnel)",
  "Locality":"Lieu dit (optionnel)",
  "Zip":"Code postal",
  "City":"Ville",
  "Birth":"Naissance",
  "Birthdate":"Date de naissance",
  "Department of birth":"Dpt. de naissance",
  "City of birth":"Ville de naissance",
  "Country of birth":"Pays de naissance",
  "Citizenship":"Nationalité",
  "Contact":"Contact",
  "Mobile":"Portable",
  "Email":"Email",
  "Occupation":"Profession",
  "Since...": "Depuis...",
  " since ": " depuis ",
  "Finances":"Finances",
  "Your net monthly income":"Vos revenus mensuels nets",
  "over 12 months":"sur 12 mois",
  "over 13 months":"sur 13 mois",
  "over 14 months":"sur 14 mois",
  "over 15 months":"sur 15 mois",
  "over 16 months":"sur 16 mois",
  "Other monthly income":"Autres revenus mensuels",
  "Property income or alimony paid":"Revenus fonciers ou pension alimentaire reçue",
  "Your share of monthly charges":"Votre part de charges mensuelles",
  "Rent or mortgage":"Loyer ou crédit immobilier",
  "Other monthly charges":"Autres charges mensuelles",
  "Credit (excluding mortgage) and / or alimony paid":"Crédit (hors prêt immobilier) et/ou pension alimentaire versée",
  "Housing":"Logement",
  "Family situation":"Situation familiale",
  "Number of dependent children":"Nb. d'enfants",
  "Bank account":"Compte bancaire",
  "IBAN":"IBAN",
  "BIC or SWIFT":"BIC ou SWIFT",
  /* ========================================== */
  /* SUMMARY ================================== */
  /* ========================================== */
  "within":"sur",
  "Edit my data":"Modifier mes données",
  "I agree to receive electronically commercial offers from companies in the BNP Paribas Personal Finance group.":"J’accepte de recevoir par voie électronique des offres commerciales de la part des sociétés du groupe BNP Paribas Personal Finance.",
  "I oppose the receipt of commercial offers by post or telephone from BNP Paribas Personal Finance group companies.":"Je m'oppose à la réception d'offres commerciales par voie postale ou téléphonique de la part des sociétés de groupe BNP Paribas Personal Finance.",
  /* ========================================== */
  /* NETHEOS ================================== */
  /* ========================================== */
  "Your product will be delivered to you after the final acceptance of the long-term rental contract and upon receipt of supporting documents by the rental company.":"Votre produit vous sera livré après l’acceptation définitive du contrat de location longue durée et à réception des pièces justificatives par le loueur.",
  /* ========================================== */
  /* CONFIRMATION ============================= */
  /* ========================================== */
  "Request sent":"Demande envoyée",
  "Your file number is":"Votre numéro de dossier est le",
  "Now, we need to review it and determine if you are eligible for our offer. A response will be provided to you within 48 hours.":"Nous devons maintenant l’examiner et déterminer si vous êtes éligible à notre offre. Une réponse vous sera apportée dans un délai de 48h.",
  "Thank you for your trust and see you soon !":"Merci de votre confiance et à bientôt !",
  /* ========================================== */
  /* ACCEPTANCE PAGE ========================== */
  /* ========================================== */
  "Let's go ! I have " : "C’est parti ! J’ai ",
  "days to go personally to a Micromania store to finalize my request with the following documents:" : "jours pour me rendre personnellement dans un magasin Micromania afin de finaliser ma demande avec les documents suivants :",
  "Acceptance":"Acceptation",
  "Acceptance in principle":"Acceptation de principe",
  "Your subscription request": "Votre demande de souscription",
  "has been accepted in principle.": "a reçu une acceptation de principe.",
  "You have ": "Vous avez ",
  " days": " jours",
  "to go to your store in order to finalize your subscription request by bringing the following documents:": "pour vous rendre dans votre magasin afin de finaliser votre demande de souscription en apportant les documents suivants :",
  "A confirmation email of your order":"Un email de confirmation de ma commande",
  "has just been sent.": " va être envoyé.",
  /* ========================================== */
  /* LEGALS =================================== */
  /* ========================================== */
  "Long-term rental contract, subject to acceptance of the file by the Lessor Personal Finance Long-term rental contract, subject to acceptance of the file by the Lessor Personal Finance Location and after expiration of the legal withdrawal period. Personal Finance Location - SASU with a capital of € 1,500,000 - Headquarters: 1 boulevard Haussmann - 75009 Paris - RCS Paris N °433 911 799.": "Contrat de location longue durée, sous réserve d’acceptation du dossier par le Loueur, Personal Finance Location, Société par actions Simplifiée au capital de 1 500 000 €, Siège social : 1 boulevard Haussmann - 75009 Paris RCS Paris N° 433 911 799.",
  "Service's contract handled by Micromania SAS society with a capital of 32 230 000 € with head office at 955, Route des Lucioles, Valbonne 06560 ; Registered to the Registre du Commerce des sociétés de Grasse under the reference SIREN 418 096 392." : "Contrat de services géré par Micromania SAS au capital de 32 230 000 € dont le siège social est situé 955, Route des Lucioles à Valbonne 06560 ; Immatriculée au Registre du Commerce des Sociétés de Grasse sous le numéro SIREN 418 096 392.",
  "The information requested is for the management of your leasing request. Unless otherwise stated, they are mandatory and in the absence of a complete response, your request may be refused. If you choose not to let us know, we will not be able to process your request. They are intended for the Lessor, Personal Finance Location, data controller. Through it, you will be able to receive proposals from partners. For more information on the processing of your personal data by Personal Finance Location and on your rights (right of access, rectification, erasure, limitation of processing, data portability, right of opposition and right to organize the management of your data after your death) you can refer to the information notice on the use of personal data or contact us at the following address at Personal Finance Location - Customer Service - 62061 Cedex 9 - consommateurs.donneespersonnelles@cetelem.fr": "Les informations demandées ont pour finalité la gestion de votre demande de location. Sauf mention contraire, elles sont obligatoires et à défaut de réponse complète, votre demande pourra être refusée. Si vous choisissez de ne pas nous les communiquer, nous ne pourrons pas traiter votre demande. Elles sont destinées au Loueur, Personal Finance Location, responsable de traitement. Par son intermédiaire, vous pourrez recevoir des propositions émanant de partenaires. Pour plus d’informations sur le traitement de vos données personnelles par Personal Finance Location et sur vos droits (droit d’accès, de rectification, à l’effacement, à la limitation du traitement, à la portabilité des données, droit d’opposition et droit d’organiser la gestion de vos données après votre décès) vous pouvez vous reporter à la notice d’information sur l’utilisation des données personnelles  ou nous contacter à l’adresse suivante à Personal Finance Location – Service Clients – 62061 Cedex 9 - consommateurs.donneespersonnelles@cetelem.fr",
  "The information requested is for the management and evaluation of your Service Contract. Unless otherwise stated, they are mandatory and in the absence of a complete response, your request may be refused. If you choose not to let us know, we will not be able to process your request. They are intended for EVOLLIS, responsible for the processing. Through it, you will be able to receive proposals from partners. For more information on the processing of your personal data by EVOLLIS and on your rights (right of access, rectification, erasure, limitation of processing, data portability, right of opposition and right to organize the management of your data after your death) you can refer here to the use of your data or contact us at the following address EVOLLIS - Customer Service - 22 quai de Bacalan - 33300 Bordeaux or at protectiondonnees@evollis.com": "Les informations demandées ont pour finalité la gestion et l’évaluation de votre Contrat de services. Sauf mention contraire, elles sont obligatoires et à défaut de réponse complète, votre demande pourra être refusée. Si vous choisissez de ne pas nous les communiquer, nous ne pourrons pas traiter votre demande. Elles sont destinées à EVOLLIS, responsable du traitement. Par son intermédiaire, vous pourrez recevoir des propositions émanant de partenaires. Pour plus d’informations sur le traitement de vos données personnelles par EVOLLIS et sur vos droits (droit d’accès, de rectification, à l’effacement, à la limitation du traitement, à la portabilité des données, droit d’opposition et droit d’organiser la gestion de vos données après votre décès) vous pouvez vous reporter ici sur l’utilisation de vos données ou nous contacter à l’adresse suivante EVOLLIS – Service Clients – 22 quai de Bacalan – 33300 Bordeaux ou à l’adresse protectiondonnees@evollis.com",
  "Service contract managed by EVOLLIS - SAS with capital of € 1,277,010 - Headquarters: 22 Quai de Bacalan 33300 Bordeaux - RCS Bordeaux N ° 530 612 571 - Intra-community VAT number: FR 195 306 125 71 - ORIAS number: 13003909.": "Contrat de services géré par EVOLLIS – SAS au capital de 1 277 010 €- Siège social : 22 Quai de Bacalan – 33300 Bordeaux – RCS Bordeaux N° 530 612 571 - N° TVA intracommunautaire : FR 195 306 125 71 – N° ORIAS : 13003909.",
  "You also have, in accordance with article L 223-2 of the Consumer Code, a right to register on the list of opposition to canvassing available on the website www.bloctel.gouv.fr. This registration prohibits a professional from canvassing by telephone, except in the case of pre-existing contractual relationships.": "Vous disposez également, conformément à l’article L 223-2 du code de la consommation, d’un droit à vous inscrire sur la liste d’opposition au démarchage téléphonique disponible sur le site internet www.bloctel.gouv.fr . Cette inscription interdit à un professionnel le démarchage téléphonique, sauf en cas de relations contractuelles préexistantes.",
  "Unless otherwise stated, the informations asked are mandatory. Collected data will be used by Personal Finance Location, data controller, in order to make your rent request succeed. They allow us to propose you adapted offers, and satisfy our legal obligations. If you need more information about data's treatment, and to exercise your rights : access right, rectification right, erasement right, treatment's limitation right, data portability, opposition right ( including the use of your personal data for prospecting purposes ) and the right to organize the management of your data after your death, please contact us at Personal Finance Location – Service Clients – 62061 ARRAS Cedex 9 - consommateurs.donneespersonnelles@cetelem.fr ." : "Sauf indication contraire, les informations demandées sont obligatoires. Les données collectées sont destinées à Personal Finance Location, responsable de traitement, et sont utilisées pour la gestion de votre demande de location, pour nous permettre de vous proposer des offres adaptées, ainsi que pour satisfaire à nos obligations légales et réglementaires. Pour plus d’informations sur le traitement de vos données personnelles et pour exercer vos droits : droit d’accès, de rectification, à l’effacement, à la limitation du traitement, à la portabilité des données, droit d’opposition (y compris à l’utilisation de vos données personnelles à des fins de prospection) et droit d’organiser la gestion de vos données après votre décès, vous pouvez nous contacter à l’adresse suivante :  Personal Finance Location – Service Clients – 62061 ARRAS Cedex 9 - consommateur.location.donneespersonnelles@cetelem.fr",
  "You can object to receiving commercial prospecting by telephone by registering for free on the list of opposition to telephone canvassing on the website www.bloctel.gouv.fr or by post to - Société Opposetel, Service Bloctel, 6 rue Nicolas Siret 10000 Troyes. This registration prohibits a professional from canvassing you by telephone, unless this solicitation occurs in the context of the execution of a current contract and it has a connection with the subject of this contract.": "Il vous est possible de vous opposer à recevoir de la prospection commerciale par voie téléphonique en vous inscrivant gratuitement sur la liste d’opposition au démarchage téléphonique sur le site internet http://www.bloctel.gouv.fr  . Cette inscription interdit à un professionnel de vous démarcher téléphoniquement, sauf si cette sollicitation intervient dans le cadre de l'exécution d'un contrat en cours et qu’elle a un rapport avec l'objet de ce contrat.",
  "The evaluation of your file is subject to automated processing, which may result in the refusal of your subscription request. You have the right to obtain human intervention, to express your point of view and to contest the decision by contacting us at the following address: Personal Finance Location - 62061 Arras - Cedex 9 or by email: serviceclientspflocation @ bnpparibas -pf.com":"L’évaluation de votre dossier fait l’objet d’un traitement automatisé susceptible d’entrainer un refus de votre de demande de souscription. Vous disposez d’un droit d’obtenir une intervention humaine, d’exprimer votre point de vue et de contester la décision en nous contactant à l’adresse suivante : Personal Finance Location – 62061 Arras - Cedex 9 ou par email : serviceclientspflocation@cetelem.fr",
  /* ========================================== */
  /* ERROR PAGES ============================== */
  /* ========================================== */
  "Internal error": "Erreur interne",
  "The server was unable to process your request.": "Le serveur n'a pas pu traiter votre requête.",
  "The server has encountered an error, and couldn't treat your request.": "Le serveur a rencontré une erreur et n’a pas été en mesure de traiter votre requête.",
  "Page not found": "Page introuvable",
  "The page you're asking for doesn't exist or had been deleted": "La page que vous recherchez n’existe pas ou a été supprimée.",
  "Service unavailable": "Service indisponible",
  "Service is unavailable, please come back later.": "Le service est temporairement indisponible. Merci de revenir plus tard.",
  "Pending request":"Demande en attente",
  "We will send you an email allowing you to finalize your file later.":"Nous allons vous envoyer un email vous permettant de finaliser votre dossier plus tard.",
  "Without your supporting documents, your file will be canceled within 3 days.":"Sans vos pièces justificatives, votre dossier sera annulé dans un délai de 3 jours.",
  /* ========================================== */
  /* VALIDATION =============================== */
  /* ========================================== */
  "Not a valid IBAN": "IBAN invalide",
  "Not a valid BIC": "BIC invalide",
  "Required": "Champ requis",
  "Date is not valid": "Date invalide",
  "maximum characters": "caractères maximum",
  "Unauthorized characters": "Caractères non-autorisés",
  "Phone number must start with 06 or 07 and contain 10 numbers": "Le numéro de téléphone doit commencer par 06 ou 07 et contenir 10 numéros",
  "Invalid postal code": "Code postal invalide",
  "Minimum age must be > 18 years old": "L'âge minimum doit être > 18 ans",
  "Maximum age must be < 110 years old": "L'âge maximum doit être < 110 ans",
  "Must be positive": "Le nombre doit être positif",
  "minimum characters": "caractères minimum",
  "number of children must be contains between 0 and 10": "Le nombre d'enfants doit être compris entre 0 et 10",
  "birthDate is not valid": "Date de naissance invalide",
  "employmentDate cannot be before birthDate": "La date d'embauche ne peut pas être avant la date de naissance",
  "Year seniority bank cannot be before birth date": "La date d'ancienneté bancaire ne peut pas être avant la date de naissance",
  "difference between employment date and birth date must be at least 16 years": "La différence entre la date d'embauche et de naissance doit être d'au moins 16 ans",
  "difference between bank seniority date and birth date must be at least 16 years": "La différence entre la date d'ancienneté bancaire et la date de naissance doit être d'au moins 16 ans",
  "employmentDate cannot be in future": "La date d'embauche ne peut pas être dans le futur",
  "Must be a whole number": "Doit être un nombre entier",
  "Must be a number": "Doit être un nombre",
  "Email invalid": "Adresse mail invalide",
  "Please assert you're the real card owner": "Cochez pour confirmer être le propriétaire de cette carte de paiement",
  "Not a valid MM value": "Mois au format MM requis",
  "Not a valid YYYY date": "Année au format AAAA requise",
  "Not a valid date": "Date invalide",
  "Can't be before current year": "Ne peut pas être avant l'année actuelle",
  "Must be composed of 3 digits": "Doit être composé de 3 chiffres",
  "Must be composed of 12-19 digits": "Doit être composé de 12-19 chiffres",
  "Not a valid YYYY-MM-DD date": "Entrez une date au format JJ/MM/AAAA",
  "Residence date cannot be before birth date": "La date de résidence ne peut pas être avant la date de naissance",
  "This date cannot be in future": "Cette date ne peut pas être dans le futur",
  "Employment date cannot be before birth date": "La date d'embauche ne peut pas être avant la date de naissance",
  "Employment date cannot be in future": "La date d'embauche ne peut pas être dans le futur",
  "Please pick a correct value": "Choisissez une valeur correcte",
  /* ========================================== */
  /* OK KO BACK =============================== */
  /* ========================================== */
  "Your request is already finalized": "Votre demande est déjà finalisée",
  "You will receive a final response within 24 hours.": "Votre recevrez une réponse définitive sous 24h.",
  "Thank you for your request.": "Nous vous remercions pour votre demande.",
  "Your request has already been validated": "Votre demande a déjà été validée",
  "The information you have entered can no longer be changed.": "Les informations que vous avez saisies ne peuvent plus être modifiées.",
  "Please click on the button below to finalize your order.": "Merci de cliquer sur le bouton ci dessous pour finaliser votre commande.",
  "Finalize your order": "Finalisez votre commande",
  "Operation failed" : "Echec de votre demande",
  "Your order is canceled. The amount of the upfront will not be deducted from you.": "Votre commande est annulée. Le montant de l'apport ne vous sera pas prélevé.",
  "Request denied": "Demande refusée",
  "We regret to inform you that we cannot give a favorable response to your request.": "Nous sommes au regret de vous informer que nous ne pouvons pas donner une suite favorable à votre demande.",
  "Maintenance": "Maintenance",
  "The app is temporarily unavailable.": "L'application est temporairement indisponible.",
  "Please come back later.": "Merci de revenir plus tard.",
};
