/* React Boostrap */
import React, { Component } from "react";
import { Button, Col, Container, Form, Alert, Row } from "react-bootstrap";
import * as FinalForm from "react-final-form";
import { withRouter } from "react-router-dom";

/* Components */
import Basket from "../../components/aside/Basket";
import Topbar from "../../components/main/Topbar";
import Header from "../../components/main/Header";
import Footer from "../../components/main/Footer";
import BreadcrumbMobile from "../../components/main/BreadcrumbMobile";

/* Utils */
import { loc } from "../../core/utils";
import { deepGet } from "../../core/utils.common";
import ApiService from "../../core/service/ApiService";
import _ from "lodash";
import {
  flagTunnelDataForPaylineNavigationCases,
  initPdiComponent, LOCAL_STORAGE_CLICK_AND_COLLECT_TEMPORARY_PERSON_KEY
} from "../../core/service/BusinessLogicService";

/* Forms controls & validation */
import validate from "./validate";
import TextField from "../../form/TextField";
import NumberField from "../../form/NumberField";
import CheckboxField from "../../form/CheckboxField";

/* Images */
import cb from "../../asset/img/cb.png";
import mastercard from "../../asset/img/mastercard.png";
import visa from "../../asset/img/visa.png";
import {mapValidateReturnObjectToFitFinalFormRequirements} from "../../form/makeValidate";
import "./style.css";

class CbPage extends Component {
  checkbox;
  componentDidMount(){
    initPdiComponent("CBPage");
    const { tunnelData } = this.props;
    const script = document.createElement('script');
    script.src = tunnelData.paylineWidgetScriptSrc;
    script.async = true;
    document.body.appendChild(script);
    this.checkbox = document.querySelector("[name='userIsRealCardOwner']");
  }

  state = {paymentButtonLocked : false, hasAlreadyFailedCb : false};

  async validate(values) {
    if (!values.userIsRealCardOwner) {
      values.userIsRealCardOwner = false;
    }
    const res = await validate(values);
    if (!_.isEmpty(res)) {
      return await mapValidateReturnObjectToFitFinalFormRequirements(res);
    }
  }

  render() {
    const { tunnelData } = this.props;
    let certifySignature;
    if (tunnelData?.clickAndCollectJourney) {
      let person = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_CLICK_AND_COLLECT_TEMPORARY_PERSON_KEY));
      certifySignature = person.firstName + " " + person.name;
    }
    else {
      certifySignature = tunnelData?.data?.order?.customer?.firstName + " " + tunnelData?.data?.order?.customer?.name;
    }

    return (
      <Container fluid className="white-background">
          <Row noGutters>
            {/* ——————————————————————————————————————————————— */}
            {/* ———————————————————— MAIN ————————————————————— */}
            {/* ——————————————————————————————————————————————— */}
            <Col className="main-block">
              <Row>
                <Topbar tunnelData={this.props?.tunnelData}/>
                <Header tunnelData={this.props?.tunnelData}/>

                {/* CONTENT */}
                    <Col sm={12}>
                      <Container>

                        <FinalForm.Form
                          onSubmit={this.onSubmit}
                          validate={async values => this.validate(values)}
                          render={({ handleSubmit, values, submitting, validating, valid }) => (
                            <Form onSubmit={handleSubmit}>

                              <div className="solid-brand-block">
                                <Row>
                                  <Col className="align-self-center">
                                    <h4>{loc`first installment of your rental contract`}</h4>
                                  </Col>
                                  <Col className="col-auto align-self-center">
                                    <p className="text-right">{tunnelData?.data?.order?.rent?.firstCustomerPayment} €</p>
                                  </Col>
                                </Row>
                              </div>
                              <Row className="margin-top-32">
                                  <Col sm={12}>
                                    <Alert variant="info">
                                      <p>
                                        <strong>{loc`This step only constitutes a request for authorization of payment.`}</strong>
                                        {loc` The 1st payment of `}
                                        {this.props?.tunnelData?.data?.order?.rent?.firstCustomerPayment || 0} €
                                        {loc`by credit card will only be effective after signing your rental contract and downloading your supporting documents.If your rental request is subsequently refused by the Lessor following its study, then this amount will be immediately refunded.`}
                                      </p>
                                    </Alert>
                                  </Col>
                                </Row>
                              <div className="hard-shadow-white-block has-wider-padding">

                                {(this.state?.hasAlreadyFailedCb || tunnelData.paylineError) && <Alert variant="danger"><p>{loc`Your card has been declined. Please check the accuracy of the information entered. You can also contact your bank to verify that it is not blocking payment.`}</p></Alert>}

                                <Row className="row-center">
                                <>
            <div id="PaylineWidget"
                data-token={tunnelData.paylineToken}
                data-template="column"
                data-auto-init="true"
                data-embeddedredirectionallowed="false">
            </div>
            <CheckboxField
                label={loc`I, undersigned ${certifySignature} certify that I am the holder of the bank card used for the request for payment of my 1st payment.`}
                name="userIsRealCardOwner"
            />
        </>

        </Row>
                              </div>

                              <Row className="margin-top-40">
                                <Col lg={{ span: 3, order: 1 }} md={{ span: 4, order: 1 }} xs={{ span: 12, order: 3 }} className="mr-auto">
                                  <Button href={this.props?.tunnelData?.shopUrl} variant="outline-primary" className="ico-back-small-before">
                                    {loc`Back to shop`}
                                  </Button>
                                </Col>
                                <Col lg={{ span: 4, order: 2 }} md={{ span: 2, order: 2 }} xs={{ span: 12, order: 1 }} className="align-self-center">
                                  <p className="secured-payment ico-secure-small-before">{loc`Secured payment`}</p>
                                </Col>
                                <Col lg={{ span: 4, order: 3 }} md={{ span: 6, order: 3 }} xs={{ span: 12, order: 2 }} className="align-item-end">
                                  <Button variant="success" type="submit" className="btn-solid btn" disabled={!this.checkbox?.checked} onClick={()=>this.onSubmit()}>
                                    {loc`validate the payment authorization`}
                                  </Button>
                                </Col>
                              </Row>

                            </Form>
                          )}
                        />

                     </Container>
                    </Col>
                {/* END CONTENT */}

                <Col sm={12}>
                  <Container>
                    <BreadcrumbMobile tunnelData={this.props?.tunnelData}/>
                    <Footer tunnelData={this.props?.tunnelData} infosAndClientPageDisplay={true}/>
                  </Container>
                </Col>
              </Row>
            </Col>

            {/* ————————————————————————————————————————————————— */}
            {/* ————————————————————— ASIDE ————————————————————— */}
            {/* ————————————————————————————————————————————————— */}

            <Col className="aside-block d-none d-lg-block d-xl-block" lg={4}>
              <Basket tunnelData={this.props?.tunnelData || null} />
            </Col>
          </Row>

          <div id="acs-evollis-widget" />
        </Container>
    );
  }

  lockPaymentButton = async () => {
    await this.setState({paymentButtonLocked : !this.state.paymentButtonLocked});
  };

  onSubmit = async values => {
    await this.lockPaymentButton();
    const btnValidPayline = document.getElementsByClassName("pl-pay-btn")[0];
    btnValidPayline.click();
  };
}

export default withRouter(CbPage);
