import React from 'react'
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import { loc } from "../core/utils";

const TooltipContent = ({ document }) =>
    <React.Fragment>{loc(document.description)}
        <OverlayTrigger
            placement={document.tooltip?.position}
            overlay={
                <Tooltip>{loc(document.tooltip?.message)}</Tooltip>
            }
        >
            {document.tooltip?.show ? <Button className="btn-tooltip">
                <i className="fas fa-info-circle"></i>
            </Button> : <div />}
        </OverlayTrigger>
    </React.Fragment>

export default function CustomizedTooltip({ document, isParagraphed }) {
    return <React.Fragment>
        <i className={document.className} />
        {isParagraphed ? 
            <p>
                <TooltipContent document={document} />
            </p> :
            <TooltipContent document={document} />}
    </React.Fragment>;
}