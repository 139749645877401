/* React Boostrap */
import React, {Component} from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  OverlayTrigger,
  Row,
  Tooltip
} from "react-bootstrap";
/* Components */
import Topbar from "../../components/main/Topbar";
import Header from "../../components/main/Header";
import Footer from "../../components/main/Footer";
import BreadcrumbMobile from "../../components/main/BreadcrumbMobile";
/* Forms controls & validation */
import * as FinalForm from "react-final-form";
import TextField from "../../form/TextField";
import NumberFieldPhone from "../../form/NumberFieldPhone";
import RadiosField from "../../form/RadiosField";
/* Utils */
import _ from "lodash";
import {loc} from "../../core/utils";
import {
  getCivilStatuses,
  initPdiComponent,
  LOCAL_STORAGE_CLICK_AND_COLLECT_TEMPORARY_PERSON_KEY,
  missEnumCode
} from "../../core/service/BusinessLogicService";
import {mapValidateReturnObjectToFitFinalFormRequirements} from "../../form/makeValidate";
import validate from "./validate";
import ApiService from "../../core/service/ApiService";
import { getCountryFromLanguage } from "../../core/service/BusinessLogicService";

class IdentificationPage extends Component {
  state = {
    submitButtonLocked: false,
  };

  async componentDidMount() {
    initPdiComponent("IdentificationPage");
    this.setState({
      "civilStatuses": await getCivilStatuses(this.props?.tunnelData, true)
    });
  }

  lockSubmitButton = async () => {
    await this.setState(
        { submitButtonLocked: this.state.submitButtonLocked !== true });
  };

  onSubmit = async values => {
    await this.lockSubmitButton();
    const headers = {
      Authorization: `Bearer ${this.props.tunnelData.access_token}`,
      Accept: "*/*",
      "Content-Type": "application/json"
    };
    await ApiService.postForEvollisApi(this.props.tunnelData.apiUrl + "api/v2/persons/" + this.props.tunnelData.opl + "/light-person", values, {headers});
    window.localStorage.setItem(LOCAL_STORAGE_CLICK_AND_COLLECT_TEMPORARY_PERSON_KEY, JSON.stringify(values.person));
    this.props.onSetStep(values);
  };

  render() {
    return (

        <Container fluid>

          <Row noGutters>
            <Col>
              <Row>
                <Topbar tunnelData={this.props?.tunnelData} displayToggleButton={false} />
                <Header tunnelData={this.props?.tunnelData} />

                <Col sm={12}>
                  {/* CONTENT */}
                  <Container>
                    <FinalForm.Form
                        initialValues={null}
                        validate={async values => {
                          // client side errors
                          const res = await validate(values);
                          let newValidationResult;
                          if (!_.isEmpty(res)) {
                            newValidationResult = await mapValidateReturnObjectToFitFinalFormRequirements(res);
                            return newValidationResult;
                          }
                        }}
                        onSubmit={this.onSubmit}
                        render={({
                          handleSubmit,
                          values,
                          submitting,
                          validating,
                          valid,
                        }) => {
                          let maidenNameNeedDisplay = (values && values.person
                              && values.person.title === missEnumCode);

                          return (
                              <Form onSubmit={handleSubmit}>

                                <Row className="justify-content-center">
                                  <Col lg={6}>
                                    <div className="hard-shadow-white-block">
                                      <RadiosField
                                          name="person.title"
                                          items={this.state.civilStatuses || []}
                                          label={loc`Title`}
                                          hasServerErrors={null}
                                          initialCustomValue={null}
                                      />
                                      <TextField
                                          name="person.name"
                                          label={loc`Last name`}
                                          hasServerErrors={null}
                                          initialValue={null}
                                          trimOnBlur={true}
                                      />
                                      {maidenNameNeedDisplay
                                      && <FormGroup>
                                        <Form.Label>{loc`Maiden name`}</Form.Label>
                                        <Row noGutters>
                                          <Col>
                                            <TextField
                                                noFormGroup
                                                name="person.maidenName"
                                                hasServerErrors={null}
                                                initialValue={null}
                                                trimOnBlur={true}
                                            />
                                          </Col>
                                          <Col className="col-auto">
                                            <OverlayTrigger
                                                key="left"
                                                placement="bottom"
                                                overlay={
                                                  <Tooltip>{loc`If you are not married, enter your name again.`}</Tooltip>
                                                }
                                            >
                                              <Button className="btn-tooltip">
                                                <i class="fas fa-info-circle"></i>
                                              </Button>
                                            </OverlayTrigger>
                                          </Col>
                                        </Row>
                                      </FormGroup>}
                                      <TextField
                                          name="person.firstName"
                                          label={loc`First name`}
                                          hasServerErrors={null}
                                          initialValue={null}
                                          trimOnBlur={true}
                                      />
                                      <TextField
                                          name="customer.address.email"
                                          label={loc`Email`}
                                          hasServerErrors={null}
                                          initialValue={null}
                                          trimOnBlur={true}
                                      />
                                     <NumberFieldPhone
                                          countryCode={getCountryFromLanguage(this.props.tunnelData.data.order.orderLanguageTag)}
                                           name= "customer.address.mobilePhone"
                                          label={loc`Mobile`}
                                      />
                                      <Button
                                          variant="success"
                                          type="submit"
                                          className="btn-solid btn margin-top-40"
                                          disabled={this.state?.submitButtonLocked
                                          === true}
                                      >
                                        {loc`Validate`}
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                          )
                        }}
                    />
                  </Container>
                  {/* END CONTENT */}
                </Col>
                <Col sm={12}>
                  <Container>
                    <BreadcrumbMobile tunnelData={this.props?.tunnelData} />
                    <Footer tunnelData={this.props?.tunnelData} infosAndClientPageDisplay={true}/>
                  </Container>
                </Col>
              </Row>
            </Col>
          </Row>

        </Container>
    );
  }
}

export default IdentificationPage;
