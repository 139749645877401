import React, { Component } from "react";
import { Field as FinalFormField } from "react-final-form";
import { Alert, Form, FormGroup, Col, Row } from "react-bootstrap";
import { AccessKeysContext } from "../context/AccessKeysContext";
import { REQUIRED_MESSAGE } from "./defaultMessages";
import { OnChange } from 'react-final-form-listeners';

export default class RadiosField extends Component {
  validate = (value, accessKeys) => {
    const { name } = this.props;
    const ak = accessKeys[name] || {};
    const { required, requiredMessage = REQUIRED_MESSAGE } = { ...this.props, ...ak };
    if (required && value === undefined) {
      return requiredMessage;
    }
  };

  state = {
    pickedOption : this.props.initialCustomValue
  };

  render() {
    return (
      <AccessKeysContext.Consumer>
        {(accessKeys = {}) => {
          const { name } = this.props;
          const ak = accessKeys[name] || {};
          const {
            items,
            hidden,
            placeholder,
            label,
            className,
            xs,
            lg,
            xl,
            md,
            sm,
            xsHidden,
            smHidden,
            mdHidden,
            lgHidden,
            xlHidden,
            disabled,
            hasServerErrors,
            noFormGroup,
            initialCustomValue
          } = { ...this.props, ...ak };

          if (hidden) {
            return false;
          }

          return (
            <FinalFormField
              name={name}
              component="input"
              type="text"
              placeholder={placeholder}
              className="form-control"
              validate={value => {
                return this.validate(value, accessKeys);
              }}
            >
              {({ input, meta }) => {
                const metaError = meta.error && (meta.touched || hasServerErrors);
                const hasError = metaError || meta.submitError;
                const cl = {
                  "d-none": xsHidden || smHidden || mdHidden || lgHidden || xlHidden,
                  "d-sm-block": !smHidden,
                  "d-sm-none": smHidden,
                  "d-md-block": !mdHidden,
                  "d-md-none": mdHidden,
                  "d-lg-block": !lgHidden,
                  "d-lg-none": lgHidden,
                  "d-xl-block": !xlHidden,
                  "d-xl-none": xlHidden,
                  "col-no-padding": true
                };
                if (className) {
                  cl[className] = true;
                }
                const hasColParam =
                  xs || lg || xl || md || sm || xsHidden || smHidden || mdHidden || lgHidden || xlHidden;
                const colParams = { className: cl, xs, lg, xl, md, sm };
                let body = [
                  <React.Fragment key="label">{label && <Form.Label>{label}</Form.Label>}</React.Fragment>,
                  <Row key="radios" className="radiofields">
                    {items.map(item => (
                      <Col key={item.name}>
                        <Form.Label className={hasError && "radiosfield-error"}>
                          <FinalFormField
                            disabled={disabled}
                            name={name}
                            component="input"
                            type="radio"
                            checked={this.state.pickedOption === item.name}
                            value={item.name}
                          />
                          {item.label}
                          {" "}
                        </Form.Label>
                      </Col>
                    ))}
                    <OnChange name={name}>
                      {(value, previous) => {
                        this.state.pickedOption = value;
                      }}
                    </OnChange>
                  </Row>,
                  <React.Fragment key="error">
                    {hasError && <p className="required-message">{meta.error || meta.submitError}</p>}
                  </React.Fragment>
                ];

                if (!noFormGroup) {
                  body = <FormGroup>{body}</FormGroup>;
                }

                if (hasColParam) {
                  body = <Col {...colParams}>{body}</Col>;
                }
                return body;
              }}
            </FinalFormField>
          );
        }}
      </AccessKeysContext.Consumer>
    );
  }
}
