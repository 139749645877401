import { set, get } from "lodash";

export function makeValidate(validator) {
  return async values => {
    try {
      await validator.validate(values, { abortEarly: false, values });
    } catch (err) {
      return err.inner.reduce((errors, { path, message, params }) => {
        if (errors.hasOwnProperty(path)) {
          set(errors, path, {errorPathAndMessage : get(errors, path) + " " + message, originalValueDetected : !!params.originalValue});
        } else {
          set(errors, path, {message : message, originalValueDetected : !!params.originalValue});
        }
        return errors;
      }, {});
    }
  };
}

export async function mapValidateReturnObjectToFitFinalFormRequirements(validationReturnedObject) {
  let reworkedObject = {};
  for (const [key, value] of Object.entries(validationReturnedObject)) {
    if (!value) {
      reworkedObject[key] = undefined
    }
    else if (typeof value === "object") {
      if (value.hasOwnProperty("message")) {
        reworkedObject[key] = value.message
      }
      else {
        reworkedObject[key] = await mapValidateReturnObjectToFitFinalFormRequirements(value);
      }
    }
  }
  return reworkedObject;
}
