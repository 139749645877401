/* React Boostrap */
import React, { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

/* Components */
import Topbar from "../components/main/Topbar";
import Footer from "../components/main/Footer";

/* Utils */
import { loc, userConfigurationService } from "../core/utils";
import {initPdiComponent} from "../core/service/BusinessLogicService";

class FinalPage extends Component {
  state = {
    backToShopUrl: ""
  };

  componentDidMount() {
    initPdiComponent("FinalPage");
    let shopLocale = "";
    let config = userConfigurationService.getUserConfiguration();
    if (config) {
      shopLocale = config.user.locale;
      if (shopLocale === "nl-BE") {
        shopLocale = "be";
      } else if (shopLocale === "fr-BE") {
        shopLocale = "be_fr";
      }
    }
    this.setState({backToShopUrl : this.props?.tunnelData?.shopUrl.replace("OPL", this.props?.tunnelData.opl).replace("BELGIUM_LOCALE", shopLocale)});
  }

  render() {
    let { tunnelData } = this.props;
    let backToShopUrl = this.state?.backToShopUrl;
    return (
      <Container fluid>

        <Row noGutters>
          {/* ——————————————————————————————————————————————— */}
          {/* ———————————————————— MAIN ————————————————————— */}
          {/* ——————————————————————————————————————————————— */}
          <Col className="misc-block">
            <Row>
              <Topbar tunnelData={this.props?.tunnelData} displayToggleButton={false}/>

              <Col sm={12}>
                {/* CONTENT */}
                <Container>
                  <Row className="justify-content-center">
                    <Col xs={11} sm={10} md={10} lg={6} className="hard-shadow-white-block text-center misc-mono-block">
                      <span className="ico-success-background">
                        <i class="far fa-check"></i>
                      </span>
                      <h1>{loc`Request sent`}</h1>
                      <p>{loc`Your file number is`} {tunnelData.opl}.</p>
                      <p>{loc`Now, we need to review it and determine if you are eligible for our offer. A response will be provided to you within 48 hours.`}</p>
                      <p>{loc`Thank you for your trust and see you soon !`}</p>
                      <Button href={backToShopUrl} variant="primary" className="ico-back-small-before">
                        {loc`Back to shop`}
                      </Button>
                    </Col>
                  </Row>
                </Container>
                {/* END CONTENT */}
              </Col>
              <Col sm={12}>
                <Container>
                  <Footer tunnelData={this.props?.tunnelData}/>
                </Container>
              </Col>
            </Row>
          </Col>
        </Row>

      </Container>
    );
  }
}

export default FinalPage;
