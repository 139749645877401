/* React Bootstrap */
import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";

/* Components */
import Basket from "../components/aside/Basket";
import Topbar from "../components/main/Topbar";
import Header from "../components/main/Header";
import Footer from "../components/main/Footer";
import BreadcrumbMobile from "../components/main/BreadcrumbMobile";

/* Utils */
import ApiService from "../core/service/ApiService";
import { loc } from "../core/utils";
import {initPdiComponent} from "../core/service/BusinessLogicService";

class NetheosPage extends Component {
  componentDidMount() {
    initPdiComponent("NetheosPage");
    const { tunnelData } = this.props;
    this.handleNetheosIFrameOpening(tunnelData);
  }

  render() {
    const { tunnelData } = this.props;
    console.log("TUNNEL DATA DANS LE RENDER DE NETHEOS", tunnelData);
    console.log("Néthéos render page : uuid =", tunnelData.netheosToken);
    return (
      <Container fluid>

        <Row noGutters>
          {/* ——————————————————————————————————————————————— */}
          {/* ———————————————————— MAIN ————————————————————— */}
          {/* ——————————————————————————————————————————————— */}
          <Col className="main-block">
            <Row>
              <Topbar tunnelData={this.props?.tunnelData} />
              <Header tunnelData={this.props?.tunnelData}/>


              <Col sm={12}>
              {/* CONTENT */}
                <Container>
                  <Row>
                    <Col>
                      <div id="iframe-wrapper"></div>
                    </Col>
                  </Row>
                </Container>
              {/* END CONTENT */}
              </Col>
              <Col sm={12}>
                <Container>
                  <BreadcrumbMobile tunnelData={this.props?.tunnelData}/>
                  <Footer tunnelData={this.props?.tunnelData}/>
                </Container>
              </Col>
            </Row>
          </Col>

          {/* ————————————————————————————————————————————————— */}
          {/* ————————————————————— ASIDE ————————————————————— */}
          {/* ————————————————————————————————————————————————— */}

          <Col className="aside-block d-none d-lg-block d-xl-block" lg={4}>
            <Basket tunnelData={this.props?.tunnelData || null} />
          </Col>
        </Row>
      </Container>
    );
  }

  handleNetheosIFrameOpening(tunnelData) {
  // should be returnUrl according to swagger documentation
    var tokenNetheos = tunnelData.netheosToken;
    var iframeSrc = tunnelData.netheosIFrameUrl + tokenNetheos + '&lang='
        + tunnelData.data.order.orderLanguageTag.split("-")[0];
    var textToRetrieve =
        '<iframe id="contractSignatureFrame" title="Documents Signature Frame" width="100%" height="2100" src='
        + iframeSrc
        + "></iframe>";
    document.getElementById("iframe-wrapper").innerHTML = textToRetrieve;
    this.initNetheosHandlers(tunnelData);
  }

  initNetheosHandlers(tunnelData) {
    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
    var signatureApiBaseUrl = tunnelData.apiUrl + "middle-business-service/api/v2/order/" + tunnelData.opl + "/signature/" + tunnelData.netheosToken ;
    const { history } = this.props
    const token = tunnelData.access_token;
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "*/*",
      "Content-Type": "application/json"
    };

    var MESSAGE_TITLES = {
      EXIT: "A bientôt !",
      SUSPENDED: "Pièces justificatives en cours de contrôle",
      FINALIZED: "Dossier complet",
      WAITING: "Dossier en cours de validation",
      ACCEPTED: "Félicitations !",
      REJECTED: "Désolé"
    };

    var MESSAGE_TEXTS = {
      EXIT: "Vous pouvez revenir quand vous le souhaitez compléter votre dossier.",
      SUSPENDED:
          "Une ou plusieurs des pièces que vous avez soumises sont en cours d'examen par nos opérateurs. Merci de revenir plus tard.",
      FINALIZED: "Votre dossier est désormais complet, nous vous recontacterons quand nous l'aurons examiné.",
      WAITING: "Votre dossier est en cours d'examen.",
      ACCEPTED: "Votre dossier a été accepté.",
      REJECTED: "Votre dossier a été refusé.",
      SIGNATURE_REJECTED: "Votre dossier est annulé, la signature du contrat a été refusée.",
      TOO_MANY_OTP_TRIES: "Votre dossier est annulé, trop de code OTP erronés envoyés lors de la signature."
    };


    eventer(
        messageEvent,
        function(event) {
          var jsEvent = JSON.parse(event.data);
          if (jsEvent.type !== "displayEvent") {
            console.log("event received");
            console.log("Event / JS event : ", event);
            console.log("Event / JS event : ", jsEvent);
          }

          if (jsEvent.type === "endEvent") {
            console.warning("endEvent Detected ! ", jsEvent);
            console.log("Exiting the page");
          } else if (jsEvent.type === "userEvent") {
            // All user actions => used by web analytics libraries like Google Analytics
            console.log("user event : " + jsEvent.action);
            if (jsEvent.action === "CHECK_CLAUSE") {
              if (jsEvent.context.checked === true) {
                console.log("user event : check clause OK");
                ApiService.postForEvollisApi(
                    signatureApiBaseUrl + "/log-user-event/CHECK_CLAUSE_OK",
                    null,
                    { headers }
                );
              } else {
                console.log("user event : check clause KO");
                ApiService.postForEvollisApi(
                    signatureApiBaseUrl + "/log-user-event/CHECK_CLAUSE_KO",
                    null,
                    { headers }
                );
              }
            } else {
              console.log("user event other : ", jsEvent);
              ApiService.postForEvollisApi(
                  signatureApiBaseUrl + "/log-user-event/" + jsEvent.action,
                  null,
                  { headers }
              );
            }
          } else if (jsEvent.type === "clientFileEvent") {
            // Event coming from eKeynox SaaS with data concerning the state of the client file
            console.log("client file state : " + jsEvent.state);

            if ((jsEvent.changedState
                && jsEvent.changedState.from === "PENDING"
                && (jsEvent.changedState.to === "WAITING" || jsEvent.changedState.to === "ACCEPTED"))
                || jsEvent.participantState === "WAITING") {
              console.log(MESSAGE_TITLES["FINALIZED"]);
              ApiService.postForEvollisApi(
                  signatureApiBaseUrl + "/contract/events/end",
                  null,
                  { headers }
              );
              console.log("final request has been sent to Evollis' API");
              history.push("/digital-journey/final");
            } else if (jsEvent.state === "PENDING" && jsEvent.context.type === "signatureRefusal") {
              console.log(MESSAGE_TEXTS["SIGNATURE_REJECTED"]);
              ApiService.postForEvollisApi(
                  signatureApiBaseUrl + "/contract/events/signatureRefusal",
                  null,
                  { headers }
              );
              history.push("/digital-journey/score-ko");
            } else if (jsEvent.state === "REJECTED" && jsEvent.cause === "TOO_MANY_OTP_TRIES") {
              console.log(MESSAGE_TEXTS["TOO_MANY_OTP_TRIES"]);
              ApiService.postForEvollisApi(
                  signatureApiBaseUrl + "/contract/events/TOO_MANY_OTP_TRIES",
                  null,
                  { headers }
              );
              history.push("/digital-journey/score-ko");
            } else {
              if (jsEvent.state !== "PENDING") {
                console.log(MESSAGE_TEXTS[jsEvent.state]);
              }
            }
          } else if (jsEvent.type === "exitEvent") {
            console.log(MESSAGE_TEXTS["EXIT"]);
            ApiService.postForEvollisApi(
                signatureApiBaseUrl + "/contract/events/exit",
                null,
                { headers }
            );
            history.push("/digital-journey/signature-paused");
          } else if (jsEvent.type === "errorEvent") {
            if (jsEvent.cause !== "INVALID_STATE") {
              ApiService.postForEvollisApi(
                  signatureApiBaseUrl + "/contract/events/INVALID_STATE",
                  null,
                  { headers }
              );
            } else {
              ApiService.postForEvollisApi(
                  signatureApiBaseUrl + "/contract/events/errors",
                  null,
                  { headers }
              );
            }
          }
        },
        false
    );
  }
}

export default NetheosPage;
