/* React Boostrap */
import React, { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

/* Components */
import Topbar from "../components/main/Topbar";
import Footer from "../components/main/Footer";

/* Utils */
import { loc } from "../core/utils";
import {initPdiComponent} from "../core/service/BusinessLogicService";

class ComeBackLaterPage extends Component {
  state: {};

  componentDidMount() {
    initPdiComponent("ComeBacklaterPage");
  }

  render() {
    return (
      <Container fluid>

        <Row noGutters>
          <Col className="misc-block">
            <Row>
              <Topbar tunnelData={this.props?.tunnelData} displayToggleButton={false}/>

              <Col sm={12}>
                {/* CONTENT */}
                <Container>
                  <Row className="justify-content-center">
                    <Col xs={11} sm={10} md={10} lg={5} className="hard-shadow-white-block text-center misc-mono-block">
                      <span className="ico-warning-background">
                        <i class="far fa-clock"></i>
                      </span>
                      <h1>{loc`Pending request`}</h1>
                      <p>{loc`We will send you an email allowing you to finalize your file later.`}</p>
                      <p>{loc`Without your supporting documents, your file will be canceled within 3 days.`}</p>
                      <Button href={this.props?.tunnelData?.shopUrl} variant="primary" className="ico-back-small-before">
                        {loc`Back to shop`}
                      </Button>
                    </Col>
                  </Row>
                </Container>
                {/* END CONTENT */}
              </Col>
              <Col sm={12}>
                <Container>
                  <Footer tunnelData={this.props?.tunnelData}/>
                </Container>
              </Col>
            </Row>
          </Col>
        </Row>

      </Container>
    );
  }
}

export default ComeBackLaterPage;
