import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { loc } from "../../core/utils";

class Breadcrumb extends Component {
  render() {
    const { tunnelData } = this.props;
    return (
      <Col sm={12} className="d-none d-sm-block">
        <ul className="list-unstyled inline-steps">
          {tunnelData.breadcrumbs.map((step, index) => {
            if (step.title) {
              return <li key={index} className={step.id === tunnelData.step ? "is-active" : ""}><span className={step.id === tunnelData.step ? "is-page-active" : ""}>{loc(step.title)}</span></li>
            }
            return null;
          })}
        </ul>
      </Col>
    )
  }
}

export default Breadcrumb;