import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { loc } from "../../core/utils";

class BreadcrumbMobile extends Component {
  render() {
    let tunnelData = this.props?.tunnelData;
    return (
      <Row>
        <Col className="d-md-none">
          <ul className="list-steps list-unstyled">
            {tunnelData.breadcrumbs.map((step, index) => {
              if (step.title) {
                return <li key={index} className={step.id === tunnelData.step ? "is-active" : ""}><span className={step.id === tunnelData.step ? "is-page-active" : ""}>{loc(step.title)}</span></li>
              }
              return null;
            })}
          </ul>
        </Col>
      </Row>
    )
  }
}

export default BreadcrumbMobile;